import React, { useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import { soundVolume } from "../AppAtoms"
import { getStr } from "../utils/simpleLocalize"
import { IconDragger, IconSoundOff, IconSoundOn } from "./icons"

export const VolumeControl = ({ value, onChange, className }) => {

  const [showBar, setShowBar] = useState(false)
  const [vol, setVol] = useRecoilState(soundVolume)
  const IDD = useRef()
  const block = useRef()

  const setVolume = (v, save=false) => {
    var volume = Math.round((1-v) * 100)
    setVol( (v)=>{ return {...v,...{vol:volume}}} )
    if (save === true){
      localStorage.setItem('saved_volume',v)
      clearTimeout( IDD.current )
    }
  }

  const handleAutoHide = () => {
    clearTimeout(IDD.current)
    IDD.current = setTimeout(()=>{
      setShowBar(false)
    },3000)


    var b = block.current.getBoundingClientRect()
    const trackMouse = (e) =>{
      if (e.screenX < b.left || e.screenX > b.right){
        block.current.removeEventListener('mousemove',trackMouse)
        clearTimeout(IDD.current)
        setShowBar(false)
      }
    }

    window.addEventListener('mousemove',trackMouse)
  }

  const toggleVolume = () => {

    if (vol.vol !== 0){
      console.log('mute')
      setVolume(1)
    }

    if (vol.vol === 0){
      var storedVolume = Number(localStorage.getItem('saved_volume')) || 1
      if (storedVolume === 0) storedVolume = 1
      console.log('stored::',storedVolume)
      setVolume(1-storedVolume)
    }


    if (!showBar){
      handleAutoHide()
    }
    setShowBar( (e)=>!e )
  }

  return <div className={`relative ${className}`}>
    <button ref={block} aria-label={getStr('ui.volume')} onMouseEnter={ ()=>{setShowBar(true); handleAutoHide()} }  onClick={ ()=>toggleVolume()  }>{vol.vol > 0 ? <IconSoundOn /> : <IconSoundOff />}{}</button>
    {showBar && <div className="absolute top-full">
      <div className="relative drag-contain flex flex-col bg-uigrey-black" style={{ height: 300, width:64 }}>
        <div className="absolute gradient-vert-white" style={{top:30,left:30,right:30,bottom:30}}></div>
        <Dragger value={100-vol.vol} onEnd={ ()=>handleAutoHide() }  onChange={v=>setVolume(v,true)}><div className="pointer-events-none"><IconDragger /></div></Dragger>        
      </div>
    </div>}
  </div>
}


const Dragger = ({value, onStart, onEnd, onChange, children }) => {
  const block = useRef()
  const [ofs, setOfs] = useState(0)

  useEffect(() => {
    if (!block.current) return

    var parRef = block.current.parentNode
    var yShift = 0

    const getY = (e) => {
      return e.touches ? e.touches[0].clientY : e.clientY
    }

    const onMove = (e) => {
      var y = getY(e) - yShift
      var mxh = parRef.offsetHeight - block.current.offsetHeight
      if (y < 0) y = 0
      if (y > mxh) y = parRef.offsetHeight - block.current.offsetHeight
      setOfs(y)
      if (onChange) onChange(y/mxh)
    }

    const onDown = (e) => {
      yShift = getY(e) - block.current.offsetTop
      parRef.addEventListener('mousemove', onMove)
      parRef.addEventListener('touchmove', onMove)
      window.addEventListener('mouseup', onDone)
      window.addEventListener('touchend', onDone)
      if (onStart) onStart()
    }

    const onDone = () => {
      parRef.removeEventListener('mousemove', onMove)
      window.removeEventListener('mouseup', onDone)
      if (onEnd) onEnd()
    }

    block.current.addEventListener('mousedown', onDown)
    block.current.addEventListener('touchstart', onDown)

    if (value) {
      setOfs( (parRef.offsetHeight - block.current.offsetHeight) / 100 * value)
    }else{
      setOfs(block.current.offsetTop)
    }

    return () => {
      if (block.current) block.current.removeEventListener('mousedown', onDown)
    }
  }, [block.current])

  return <div className="absolute" ref={block} style={{ top: ofs }}>{children}</div>
}