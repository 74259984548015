import React from 'react'
import { getStr, L } from '../../utils/simpleLocalize'
import { IconClose } from '../icons'

import icon_fb from './icons/facebook.svg'
import icon_twitter from './icons/twitter.svg'
import icon_whatsapp from './icons/whatsapp.svg'
import icon_signal from './icons/signal.svg'
import icon_mail from './icons/mail.svg'
import icon_link from './icons/link.svg'



export const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea")
  textArea.value = text
  
  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.warn('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.warn('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}


export const ShareStory = ({color,title,text,onClose}) =>{

  var _url = encodeURIComponent(window.location.href)
  var _title = encodeURIComponent(title)
  var _text = encodeURIComponent(text+'\n'+window.location.href)

  const clipboard = (e)=> {
    e.preventDefault()
    var text = window.location.href

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(function() {
      
    }, function(err) {
      
    })
  }


  return (
    <div className='share-overlay'>
      <div className="cover anim-fadein"></div>
      <div className={`win anim-slide-top absolute left-0 w-full h-full flex flex-col z-40 top-0 md:top-auto md:h-auto md:bottom-0 bg-${color}-50`}>
        
        <header className='flex items-center pl-4'>
          <div className='flex-1 text-h3'>
            <L id="share.title"></L>
          </div>
          <div>
            <button aria-label={getStr('ui.close')} className="" onClick={()=>onClose()}><IconClose/></button>
          </div>
        </header>

        {/* <div className='py-4 px-4'>
          <L id="share.info" />
        </div> */}

        <div className="flex-1 px-4 flex flex-col justify-center">
          <div className='text-h2'>{title}</div>
        </div>

        <div className='flex flex-wrap pb-4 px-4'>
          {/* <a rel="noreferrer" target="_blank" className="share-icon w-1/3 md:w-2/12 py-2" href={`https://www.facebook.com/sharer/sharer.php?u=${_url}&t=${_title}"`}><img src={icon_fb} /><div><L id="share.facebook"/></div></a>
          <a rel="noreferrer" target="_blank" className="share-icon w-1/3 md:w-2/12 py-2" href={`https://twitter.com/share?url=${_url}&text=${_text}`} ><img src={icon_twitter} /><div><L id="share.twitter"/></div></a> */}
          <a rel="noreferrer" target="_blank" className="share-icon w-1/3 md:w-auto py-2 md:pr-4" href={`whatsapp://send?text=${_text} `} data-action="share/whatsapp/share"><img src={icon_whatsapp} /><div><L id="share.whatsapp"/></div></a>
          <a rel="noreferrer" target="_blank" className="share-icon w-1/3 md:w-auto py-2 md:pr-4" href={`signal://send?text=${_text} `} data-action="share/signal/share"><img src={icon_signal} /><div><L id="share.signal"/></div></a>
          <a rel="noreferrer" target="_blank" className="share-icon w-1/3 md:w-auto py-2 md:pr-4" href={`mailto:?subject=${_title}&body=${_text}`}><img src={icon_mail} /><div><L id="share.mail"/></div></a>
          <a rel="noreferrer" target="_blank" className="share-icon w-1/3 md:w-auto py-2 md:pr-4" href="#" onClick={e=>clipboard(e)}><img src={icon_link} /><div><L id="share.link"/></div></a>
        </div>

      </div>
    </div>
  )

}
