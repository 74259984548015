import { PlaneGeometry, MeshBasicMaterial, TextureLoader, DoubleSide, Mesh, MathUtils } from "three"
import { isDebug } from "../../utils/tool"
import { wrapper,scene, camera, controls,setThreeSixtyCallback,renderer, slideToByString } from './Map_threejs'
import anime from 'animejs/lib/anime.es'


export const Heatmap = {

  plane:null,
  material:null,


  close: ()=>{
    anime({
      targets:[Heatmap.material],
      opacity: 0,
      easing:'linear',
      duration:400,
      complete:()=>{
        scene.remove( Heatmap.plane )
      }
    })  

    
  },

  init:(mapUrl)=>{

    const pGeo = new PlaneGeometry(2000, 2000 )  
    
    Heatmap.material = new MeshBasicMaterial({    
      map:new TextureLoader().load(mapUrl, (img)=>{
      })
    })
    Heatmap.material.side = DoubleSide
    Heatmap.material.transparent = true
    Heatmap.material.opacity = 0

    var [xp,zp,rz,sx,sz] = String('-514_-746_1.6100662349647712_4.913826354140587_4.675337170822537').split('_')

    
    Heatmap.plane = new Mesh( pGeo, Heatmap.material )
    Heatmap.plane.basescale = 1
    Heatmap.plane.rotation.set( MathUtils.degToRad(-90),0,rz)
    Heatmap.plane.position.set(Number(0),Number(14),Number(0))    
    scene.add(Heatmap.plane)

    Heatmap.plane.position.setX(Number(xp))
    Heatmap.plane.position.setZ(Number(zp))
    Heatmap.plane.scale.setX(sx)
    Heatmap.plane.scale.setY(sz || sx)
    
    setTimeout(()=>{
      slideToByString('-590,56,693$-2386,2804,-119','city',()=>{
      })
    },1000)

    

    anime({
      targets:[Heatmap.material],
      opacity: 1,
      easing:'linear',
      duration:2000,
      complete:()=>{
      }
    })  
  


    // -185,-655,-144$-1725,2194,831

    if (!isDebug()) return
    
    document.addEventListener('keydown',(e)=>{
      var speed = e.shiftKey ? 10 : 1
      switch( e.key.toLowerCase() ){
        case 'q': Heatmap.plane.position.x += speed; break
        case 'w': Heatmap.plane.position.x -= speed; break

        case 'a': Heatmap.plane.position.z += speed; break
        case 'y': Heatmap.plane.position.z -= speed; break
  
        case 's': Heatmap.plane.rotation.z -= Math.PI / 720; break
        case 'x': Heatmap.plane.rotation.z += Math.PI / 720; break
  
        case ',': 
          Heatmap.plane.scale.x *= 1.01
          break
        case '.': 
          Heatmap.plane.scale.x /= 1.01
          break

        case 'k': 
          Heatmap.plane.scale.y *= 1.01
          break
        case 'l': 
          Heatmap.plane.scale.y /= 1.01
          break
      }
    })

  }

}