import React from "react"

export const ParallaxDivider1 = () => {
  return (
    <div className="w-full h-full overflow-hidden">
      <svg width="403" height="1080" viewBox="0 0 403 1080" fill="none" xmlns="http://www.w3.org/2000/svg" data-swiper-parallax={'30%'}>
        <path d="M0 1080V0H329.692L169.288 1080H0Z" fill="currentColor" />
      </svg>
    </div>
  )
}


export const ParallaxDivider2 = () => {
  return (
    <svg width="326" height="1080" viewBox="0 0 326 1080" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform:'translateX(-50%)'}}>
      <path d="M0.442017 1080H165.288L325.692 0H160.846L0.442017 1080Z" fill="currentcolor" data-swiper-parallax={'-10'}/>
    </svg>
  )
}

export const ParallaxDividerRight1 = () => {
  return (
    <svg width="403" height="1080" viewBox="0 0 403 1080" fill="none" xmlns="http://www.w3.org/2000/svg" data-swiper-parallax={'30%'}>
      <path d="M0 1080V0H329.692L169.288 1080H0Z" fill="currentColor" />
    </svg>
  )
}

export const ParallaxDividerRight2 = () => {
  return (
    <svg width="403" height="1080" viewBox="0 0 403 1080" fill="none" xmlns="http://www.w3.org/2000/svg" data-swiper-parallax={'30%'}>
      <path d="M0 1080V0H329.692L169.288 1080H0Z" fill="currentColor" />
    </svg>
  )
}

export const DividerRight = () => {
  return (
    <svg width="400" height="1080" viewBox="0 0 400 1080" fill="none" xmlns="http://www.w3.org/2000/svg" className="right-0 absolute">
      <path d="M289.344 1080L389.914 0H400V1080H289.344Z" fill="currentcolor" />
    </svg>

  )
}

export const DividerLeft = () => {
  return (
    <svg width="140" height="1080" viewBox="0 0 140 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M110.656 9.67385e-06L10.086 1080H0L9.44166e-05 0L110.656 9.67385e-06Z" fill="currentcolor"/>
    </svg>
  )
}