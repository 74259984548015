import React, { useEffect, useState } from "react"
import { serverUrl } from "../../utils/net"
import { Heatmap } from "../map/Map_Heatmap"

export const StorySpecial = ({data,onClose}) => {

  const [url,setUrl] = useState('')


  useEffect(()=>{

    if (data && data.options.type == 'iframe' && data.options.url){
      setUrl(data.options.url)
    }
  },[data])

  return (
    <div className="story-special relative w-full h-full pointerevent-none">
      {data.options.type == 'iframe' && url && <iframe src={url} className="absolute w-full h-full"></iframe>}
    </div>
  )

}