import React, { Fragment, useState, useEffect, useRef } from "react"
import { postData } from "../../utils/net"
import { IconArrowRight } from "../icons"


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const SurveyYesNo = ({ data, surveyId, onNext }) => {
  const submitAnswer = (opt) => {
    postData(`/api/surveys/store/${surveyId}/${data.slug_slug}`, { type: 'yesno', data: opt }, (resdata) => {
      if (resdata && resdata.status === 'ok') {
        let slugField = ['no_slug_slug','yes_slug_slug'][opt]
        onNext(data[slugField])
      }
    })
  }

  return <div className="h-full flex flex-col">
    <div className="flex-1"></div>
    <div className="p-4 sm:text-h2 text-bg-backdrop text-h4 font-bold anim-slide-top anim-delay-200 opacity-0 text-shadow-1" dangerouslySetInnerHTML={{ __html: data.question }}></div>
    <div className="bg-uigrey-light flex flex-row p-4 anim-slide-top">
      <button className="yesno bg-uigrey-default text-h4 sm:text-h3 mr-1" onClick={e => submitAnswer(1)}>{data.yes_title}</button>
      <button className="yesno bg-uigrey-default text-h4 sm:text-h3 ml-1" onClick={e => submitAnswer(0)}>{data.no_title}</button>
    </div>
    <div className="p-2 bg-uigrey-light"></div>
  </div>
}
