import { getStr } from "../../utils/simpleLocalize"

export const ParseSummaryData = ( raw_data )=>{
  raw_data.data_json = JSON.parse(raw_data.data)
  return raw_data
}


export const BuildSummaryDiagramData = ( dataset ) => {
  var result = {}  
  result.icon = null
  result.data = ''
  result.total = 0
  result.maxValue = 0
  result.year_min = Number(dataset.year_min)
  result.year_max = Number(dataset.year_max)
  var curDataset = dataset.data_json.inprison
  Object.keys(curDataset).forEach( (k)=>{
    result.data += `${k}:${curDataset[k].total_m},${curDataset[k].total_w},0\n`
    result.total += (curDataset[k].total_w + curDataset[k].total_m + curDataset[k].total_u)
    var m = Object.keys(curDataset[k].rec).reduce( (v,r)=>{ return Math.max(v,curDataset[k].rec[r] ) },0 )
    result.maxValue = Math.max(result.maxValue,m)
  })

  if (dataset.paragraph){
    result.data = '*total:'+Number(dataset.paragraph)+'\n' + result.data
  }else{
    result.data = '*total:'+dataset.data_json.total+'\n' + result.data
  }

  return result
}

export const buildSummaryItems = ( dataPreset, titles, data ) => {
  var result = {}
  var remainingvalue = 0
  var maxValue = 0

  Object.keys(dataPreset).map(k=>{
    result[ dataPreset[k] ] = {id:k,title:titles[k],value:0}
  })
  Object.keys(data.rec).map(k=>{
    k=='other' ? remainingvalue = data.rec[k] : result[k].value = data.rec[k]
    maxValue = Math.max(maxValue,data.rec[k])
  })

  return {
    listed: Object.keys(result).map(k=>result[k]), 
    total: maxValue,
    remaining:{
      title:getStr('prisonstats.summary.remaininginfo'),
      id:'-',
      value:remainingvalue
    }    
  }
}