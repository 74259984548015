import React, { useEffect, useState } from 'react'


import { getStr, L, userLang } from '../../utils/simpleLocalize'
import { IconArrowLeft, IconArrowRight, IconArrowUp, IconClose } from '../icons'
import { Image } from '../Image'
import { getTopicBySlug } from './datafilter'

export const LocationPanel = ({data,onClose,color,onOpenContent,onNextLocation,onPrevLocation}) =>{  
  
  const [closeClass, setCloseClass] = useState('')
  const [imgSource, setImgSource] = useState('')

  const doClose = () => {
    setCloseClass('close')
    setTimeout(()=>{onClose()},400)
  }

  const handleStartClick = () =>{
    onOpenContent(data)
  }

  const getButtonTitle = () => {
    if (data.options && data.options['locationpanel_button_'+userLang]){
      return data.options['locationpanel_button_'+userLang]
    }else{
      return <L id="topicpanel.viewlocations"/>
    }
  }

  useEffect(()=>{
    
  },[data])

  let startButton
  if (data.options && data.options.url){
    startButton = <a href={data.options.url} aria-label={getStr('ui.openStory')} target="_black" className={`btn btn-icon pl-4 bg-${ color }-10`}>{getButtonTitle()}</a>
  }else{
    startButton = <button aria-label={getStr('ui.openStory')} onClick={e=>handleStartClick()} className={`btn btn-icon pl-4 bg-${ color }-10`}>{getButtonTitle()}</button>
  }


  return (
    <div className={`location-panel hide-on-story absolute inset-0 ${closeClass}`}>
      <div className="cover w-full h-full" onClick={ e=>doClose() }/>

      <div className={`panel bg-uigrey-dark z-10 sm:overflow-hidden`}>

        <button className={`close-btn absolute top-0 right-0 bg-${ color }-10 z-10`} aria-label={ getStr('ui.close')} onClick={ e=>doClose() }><IconClose /></button>

        <div className="flex-1 image-wrap hidden sm:block bg-uigrey-black relative">
          <Image className="w-full h-full object-cover absolute" srcdata={data.thumbnail} type="small" />
        </div>

        <div className="w-full relative">
          <div className={`absolute skew-cover bg-${color}-25`}></div>
          <div className="relative p-3 sm:p-4 pt-2 w-full flex">
            <div className="flex-1 flex flex-col h-full">
              <p className="pb-1">{data.pretitle}</p>  
              <h4 className="text-h3 md:text-h3 pb-2" dangerouslySetInnerHTML={{__html:data.title}} ></h4>
              <p className="pb-4 flex-1">{data.short_description}</p>
              <div className="flex">
                {startButton}
                <div className="pl-1 flex-1"></div>
                <button aria-label={getStr('ui.nextStory')} onClick={e=>onPrevLocation(data)} className={`btn btn-icon-only btn-hover-scale bg-${ color }-10 mr-1px`}><IconArrowLeft /></button>
                <button aria-label={getStr('ui.nextStory')} onClick={e=>onNextLocation(data)} className={`btn btn-icon-only btn-hover-scale bg-${ color }-10`}><IconArrowRight /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}