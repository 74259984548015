import React, { useEffect, useState } from "react"
import { L } from "../../utils/simpleLocalize"
import { IconArrowDown, IconArrowUp } from "../icons"

export const Dropdown = ({ data, id, onSelect }) => {
  const [dataid, setDataId] = useState()
  const [drop, setDrop] = useState(false)

  const selectItem = (idval) => {
    setDataId(idval)
    setDrop(false)
    onSelect(idval)
  }

  useEffect(()=>{
    if (dataid != id) setDataId(id)
  },[id] )

  return (<div className="dropdown relative z-10">
    <div className="field flex items-center bg-green-50 cursor-pointer" onClick={e => setDrop(!drop)}>
      <div className="flex-1 text-h4 pl-2 text-uigrey-black">
        <div className="text-h6 uppercase"><L id="prisonstats.dropdown.reason" /></div>
        {dataid ? data[dataid] : <L id="prisonstats.dropdown.overview" />}
      </div>
      <div className="text-uigrey-black">
        {!drop && <IconArrowDown />}
        {drop && <IconArrowUp />}
      </div>
    </div>
    {drop && <div className="absolute list w-full z-5 shadow-md">
      <button className="block bg-green-40 w-full text-left text-h4 pl-2 text-uigrey-black" onClick={e => selectItem('')}><L id="prisonstats.overview" /></button>
      {Object.keys(data).map((key, i) => {
        return <button className="block text-uigrey-black bg-green-40 w-full text-left text-h4 pl-2" key={key} onClick={e => selectItem(key)}>{data[key]}</button>
      })}
    </div>}
  </div>)
}

