import React from "react"


export const ParallaxShape1 = () => {
  return (
    <svg width="135" height="1080" viewBox="0 0 135 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M134.924 0H0V347.338L134.924 293.736V0Z" fill="currentcolor" />
    </svg>
  )
}

export const ParallaxShape2 = () => {
  return (
    <svg className="absolute right-0" width="136" height="1080" viewBox="0 0 136 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g data-swiper-parallax={'80%'}>
        <path d="M0.156723 0H135.081V257.338L0.156723 203.736V0Z" fill="currentcolor" />
      </g>
    </svg>
  )
}


export const ParallaxShape3 = () => {
  return (

    <svg style={{left:'-15vw'}} className="absolute" width="471" height="1080" viewBox="0 0 471 1080" fill="none" xmlns="http://www.w3.org/2000/svg" data-swiper-parallax={'50%'}>
      <path d="M0.542941 1080L471.705 1080L471.705 863.035L0.542943 1050.21L0.542941 1080Z" fill="currentcolor"/>
    </svg>


  )
}
export const ParallaxShape4 = () => {
  return (
    <svg width="211" height="1080" viewBox="0 0 211 1080" fill="none" xmlns="http://www.w3.org/2000/svg"  data-swiper-parallax={'64'}>
      <path d="M-2.31076e-05 1080L192 1080L192 723.794L-2.50059e-07 804.241L-2.31076e-05 1080Z" fill="currentcolor"/>
    </svg>

  )
}
