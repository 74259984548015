import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { fetchData } from '../../utils/net'
import { userLang } from '../../utils/simpleLocalize'
import { PageButton } from './PageButton'
import { PageHeadline } from './PageHeadline'
import { PageText } from './PageText'

import './Page.sass'
import { PageImage } from "./PageImage"
import { PageSpacer } from "./PageSpacer"
import { PageSection } from "./PageSection"

import { headerState,soundOptions } from '../../AppAtoms'
import { useRecoilState } from "recoil"



export const Page = ({onAction}) => {

  const [pageData, setPageDate] = useState(null)
  const [_headerState,_setHeaderState] = useRecoilState(headerState)
  const [sound,setSound] = useRecoilState(soundOptions)
  let { page } = useParams()
  const history = useHistory()

  var pageColor
  if (pageData && pageData.color){
    pageColor = pageData.color.content || 'uigrey-dark'
  }else{
    pageColor = 'uigrey-dark'
  }

  useEffect( ()=>{
    return ()=>{
      _setHeaderState('')
    }
  },[])


  useEffect(() => {
    if (!page) {
      return
    }
    fetchData('/api/page/' + userLang + '/' + page, (data) => {
      console.log(data)
      if (data && !data.error) {
        if (data.music){
          setSound(data.music.content)
        }
        setPageDate(data)
        _setHeaderState('sticky')
      }else{
        history.push('/')
        console.error('Could not be found')
      }
    })
  }, [page])

  const handleAction = (e)=>{
    var [action,options] = e.split(':')    
    switch (action){
      case '#tour':
        onAction({ action:'start_tour', options: options})
        break
      case '#novel':
        onAction({ action:'open_novel', options: options})
        break
      default:
        history.push(action)

    }
  }



  const parseComponents = (comps) => {
    return comps.map((el,i)=>{

      switch (el.component) {
        case 'section': return <PageSection key={i} data={el.settings} items={el.children}>{ parseComponents(el.children) }</PageSection>
        case 'heading': return <PageHeadline key={i} data={el.settings} />
        case 'text': return <PageText key={i} data={el.settings} />
        case 'button': return <PageButton key={i} data={el.settings} onClick={ e=>handleAction(e) }/>
        case 'divider': return <hr key={i} className="pb-4"/>
        case 'image': return <PageImage key={i} data={ el.settings } color={ pageColor }/>
        case 'spacer': return <PageSpacer key={i} data={ el.settings } />
        default:
          console.warn('PAGE COMPONENT ['+el.component+'] NOT FOUND', el)
          return <Fragment />
      }


    })
  }

  console.log(pageData)

  // 
  if (!page || !pageData) return <Fragment></Fragment>

  return (
    <div className={`page bg-${  pageColor  }`}>
      <div className={`header-cover fixed top-0 w-full bg-${pageColor}`} />
      <div className="page-wrapper pointer-events-all">
        {pageData && parseComponents(pageData.components) }
      </div>
    </div>
  )


}

