import React, { useEffect, useRef, useState } from 'react'
import { getStr } from '../../../utils/simpleLocalize'
import { ContentHeader } from "../../ContentHeader"
import { Copyright } from "../../Copyright"
import { glossaryParse, useGlossary } from "../../Glossary"
import { IconClose } from '../../icons'
import { Image } from '../../Image'
import { Ratio } from "../../Ratio"



export const OverlayText = ({ data, color, onClose }) => {

  const imageUrl = (data.image && !Array.isArray(data.image)) ? data.image.path : null
  const imageCopy = (data.image && !Array.isArray(data.image) && data.image.meta) ? data.image.meta.title : null

  const txtRef = useRef()
  const g = useGlossary(txtRef)

  return (
    <div className={`overlay-screen overlay-text bg-uigrey-black flex flex-col sm:flex-row bg-${color}-25`}>
      <ContentHeader title={ data.title } color={color} onClick={e => onClose()} />

      {imageUrl && <div className="sm:w-1/2 relative">
        <Copyright data={ imageCopy } type="text" className="z-2"/>
        <Ratio ratio="4x3" className="w-full"/>
        <Image className="top-0 absolute w-full h-full object-cover" src={imageUrl} preview={true}/>
      </div>}

      <div className="sm:w-1/2 flex flex-col sm:pl-8 sm:pt-12 pl-2 pt-2 flex-1 relative">
        <div className="text-body-wrapper sm:pr-4 h-full absolute sm:relative">
          <div className="text-title sm:text-xl text-h3 pb-4" dangerouslySetInnerHTML={{ __html: data.title }}></div>
          <div ref={txtRef} className="text-body text-lg pb-4" dangerouslySetInnerHTML={{ __html: glossaryParse(data.text) }}></div>
          {/* <div>{JSON.stringify(data)}</div> */}          
        </div>
      </div>


    </div>
  )

}
