/* eslint-disable react/no-children-prop */
import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom"

import { Header } from './components/header/Header'
import { Loading } from './components/Loading'
import { MapHandler } from './components/MapHandler.jsx'
import { Global, global } from './global'
import './styles/index.scss'
import { DebugBar } from './utils/DebugBar'
import { Metadata } from './components/Metadata'
import { cache, fetchData, getParam } from './utils/net'
import { createChain } from './utils/simpleChain'
import { loadLanguage, userLang } from './utils/simpleLocalize'
import { StoryHandler } from './components/StoryHandler'
import { Startmenu } from './components/Startmenu'

import { Page } from './components/page/Page'
import { GuidedTour } from './components/GuidedTour'
import { Novel } from './components/novel/Novel'
import { Error } from './components/Error'

import { RecoilRoot } from 'recoil'
import { Survey } from './components/survey/Survey'
import { OverlayTools } from './components/story/overlays/OverlayTools'
import { PrisonStats } from './components/prisonStatistics/PrisonStats'
import { Glossary } from "./components/Glossary"
import { InlineHelp } from "./components/InlineHelp"
import { useTracking } from "./components/tracking"
import { getScrollbarWidth, isDebug } from "./utils/tool"


export const routeRoot_city = '/erfurt'
export const routeRoot_area = '/andreasstrasse'
export const routeRoot_start = '/start'
export const routeRoot_360 = '/360'

export const isMobile = () => {
  // var style = getComputedStyle(document.body)
  // console.log('debuvce', style.getPropertyValue('--device-id') )

  // var isMobile = false //initiate as false
  // // device detection
  // if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
  //   || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
  //   isMobile = true
  // }


  let isMobile = window.matchMedia("only screen and (max-width: 480px)").matches
  return isMobile
}

export const isMobileLayout = () => {
  let isMobile = window.matchMedia("only screen and (max-width: 480px)").matches
  return getComputedStyle(document.querySelector('body')).getPropertyValue('--device-id') == 2
}


export const OnResize = () => {
  const [size, setSize] = useState({ wi: window.innerWidth, hi: window.innerHeight })
  useEffect(() => {
    const listener = () => {
      setSize({ wi: window.innerWidth, hi: window.innerHeight })
    }
    window.addEventListener('resize', listener)
    listener()
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])
  return size
}



function App() {

  const [initStep, setInitStep] = useState(0)
  const [tourname, setTourName] = useState(false)
  const [storyData, setStoryData] = useState(null)

  const handleAction = (action, opt) => {
    switch (action) {
      case 'start_tour':
        setTourName(opt)
        break
    }
  }

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--vh', (window.innerHeight * .01) + 'px')
    doc.style.setProperty('--scrollbar', getScrollbarWidth() + 'px')
  }

  // this is essential for the app
  useEffect(() => {
    isMobile()
    window.addEventListener('resize', () => {
      appHeight()
    })

    appHeight()

    if (getParam('', 'debug')) {
      console.warn('ENTER DEBUG MODE')
      localStorage.setItem('debugmode', getParam('', 'debug'))
    }

    //_paq.push(['setDocumentTitle', document.title]);
    //_paq.push(['trackPageView']);

  }, [])


  useEffect(() => {

    if (initStep === 0) {
      loadLanguage(() => {
        setInitStep(1)
      })
    }

    if (initStep === 1) {
      fetchData('/api/singleton/' + userLang, (data) => {
        cache.parse(data)
        setInitStep(2)
      })
    }

    if (initStep === 2) {
      fetchData('/api/mapdata/' + userLang, (data) => {
        var t = {}
        data.topics.map((e) => { t[e._id] = e })
        cache.set('topicData', data)
        cache.set('topicRef', t)
        cache.set('city_topics', data.topics.filter((el) => el.geo_category == 'city'))
        cache.set('area_topics', data.topics.filter((el) => el.geo_category == 'area'))
        cache.set('default_locations', data.locations.filter((el) => el.topic_link == ''))
        setInitStep(3)
      })
    }

  }, [initStep])

  if (initStep < 3) {
    return <Loading />
  }

  return (
    <RecoilRoot>
      <div className={`app ${tourname && 'tour'} ${isDebug() ? 'debug':''}`}>
        <Metadata slug="root"></Metadata>
        <PurgeCss />
        <Router>
          <MapHandler isMobile={isMobile()} onTour={tourname ? true : false} storyData={storyData} />
          <Header />
          <GuidedTour tourname={tourname} onClose={e => setTourName(null)} />
          <Switch>
            <Route path="/" exact render={props => (<Redirect to={routeRoot_start} />)} />

            {/* <Route path="/:mode/:location/:story" component={ StoryHandler } /> */}
            <Route path="/:mode/:topic/:location/:story" render={(e) => <StoryHandler {...e} onStoryData={(data) => {
              setStoryData(data)
            }} />
            } />

            <Route path="/debug/:story" render={(e) => <StoryHandler {...e} onStoryData={(data) => { setStoryData(data) }} />} />
            <Route path="/story/:story" render={(e) => <StoryHandler {...e} onStoryData={(data) => { setStoryData(data) }} />} />
            <Route path="/geschichte/:story" render={(e) => <StoryHandler {...e} onStoryData={(data) => { setStoryData(data) }} />} />

            <Route path="/start" component={Startmenu} />
            <Route path="/page/:page" render={() => <Page onAction={e => handleAction(e.action, e.options)} />} />
            <Route path="/seite/:page" render={() => <Page onAction={e => handleAction(e.action, e.options)} />} />
            <Route path="/statistik" render={() => <PrisonStats visible={true} />} />
            <Route path="/glossar" render={() => <Glossary visible={true} />} />
            <Route path="/novel/:start" component={Novel} />
            <Route path="/comic/:start" component={Novel} />
            <Route path="/survey/:id" component={Survey} />
            <Route path="/umfrage/:id" component={Survey} />
            <Route path="*" component={ Error } />
          </Switch>

          <OverlayTools />
          <PrisonStats />
          <Glossary />
          
          <Tracking/>

        </Router>
        {isDebug() ? <a href="/?debug=0" className="fixed z-40 pointer-events-all top-0 left-0 text-h6 bg-red-100 p-1">Stop DEBUG</a> : ''}

      </div>
    </RecoilRoot>
  )
}

const Tracking = () => {
  const location = useLocation()
  if (isDebug()) return <Fragment/>
  useEffect( ()=>{
    setTimeout( ()=>{
      if (!_paq) return
      //console.info('LOCATION CHANGD',location)
      _paq.push(['setDocumentTitle', document.domain + "/" + document.title])
      _paq.push(['trackPageView'])
    },200)
  },[location])
  return <Fragment/>
}


const PurgeCss = () => {
  let c = [
    'hidden',
    'bg-blue-10',
    'bg-blue-25',
    'bg-blue-50',
    'bg-blue-100',
    'bg-red-10',
    'bg-red-25',
    'bg-red-50',
    'bg-red-100',
    'bg-petrol-10',
    'bg-petrol-25',
    'bg-petrol-50',
    'bg-petrol-100',
    'bg-pink-10',
    'bg-pink-25',
    'bg-pink-50',
    'bg-pink-100',
    'bg-kubus-10',
    'bg-kubus-25',
    'bg-kubus-50',
    'bg-kubus-100',

    'text-blue-10',
    'text-blue-25',
    'text-blue-50',
    'text-blue-100',
    'text-red-10',
    'text-red-25',
    'text-red-50',
    'text-red-100',
    'text-petrol-10',
    'text-petrol-25',
    'text-petrol-50',
    'text-petrol-100',
    'text-pink-10',
    'text-pink-25',
    'text-pink-50',
    'text-pink-100',
    'text-kubus-10',
    'text-kubus-25',
    'text-kubus-50',
    'text-kubus-100',
    'text-white-default',

    "object-bottom",
    "object-center",
    "object-left",
    "object-left-bottom",
    "object-left-top",
    "object-right",
    "object-right-bottom",
    "object-right-top",
    "object-top"

  ]
  return <div className={c.join(' ')}></div>
}


export default App
