import React from 'react'
import { Helmet } from 'react-helmet'
import { cache } from '../utils/net'


export const Metadata = ({slug,dataObject}) =>{
  
  const data =  dataObject || cache.get('metadata',slug,{})
  
  return (
    <Helmet>
      { 
        Object.keys(data).map( (type,i)=>{
          let val = data[type]
          switch ( type ){
            case 'title':       return <title key={i}>{val}</title>
            case 'description': return <meta key={i} name="description" content={val} />
          }
        })
      }      
    </Helmet>
  )
}