import React, { Fragment, useEffect, useRef, useState } from 'react'
import { getParam, serverUrl } from '../utils/net'
import anime from 'animejs/lib/anime.es'
import { isDebug } from "../utils/tool"

export const parseImageUrl = (src) =>{
  let image_url = String(src)
  image_url = image_url.replace('storage/uploads/', '')
  image_url = image_url.replace('uploads/', '')
  image_url = image_url.replace('/', '@')
  return image_url
}

//const forceRefresh = isDebug() || Number(getParam(null,'refresh')) === 1
const forceRefresh = Number(getParam(null,'refresh')) === 1

export const Image = ( { src, srcdata, className, alt, type = 'default', style, mobilecrop,ref, preview }) => {

  const [srcset, setSrcset] = useState(null)
  const previewref = useRef()

  useEffect(() => {

    if (!src && srcdata) {
      src = srcdata.path
      alt = (srcdata.meta && srcdata.meta.title) ? srcdata.meta.title : alt
    }

    let image_url = parseImageUrl(src)
    let sign = forceRefresh ? '!' : '_'


    let crop = '0'
    if (mobilecrop){
      var _cropArr = Object.keys(mobilecrop).map((k)=>mobilecrop[k])
      crop = _cropArr.join('x')
    }

    const imageSizes = {
      'raw': [
        `${serverUrl}/assets/${sign}raw_0_def_${image_url}`, 
      ],
      'novel': [
        `${serverUrl}/assets/${sign}nvsm_${crop}_def_${image_url} 480w`, // 120px
        `${serverUrl}/assets/${sign}nvhd_0_def_${image_url} 768w`, // 256px
      ],

      'thumb': [
        `${serverUrl}/assets/${sign}thumb_${crop}_def_${image_url} 480w`, // 120px
        `${serverUrl}/assets/${sign}thumb_0_def_${image_url} 768w`, // 256px
      ],

      'mini': [
        `${serverUrl}/assets/${sign}xs_${crop}_def_${image_url} 480w`, // 120px
        `${serverUrl}/assets/${sign}sm_0_def_${image_url} 768w`, // 256px
      ],

      'small': [
        `${serverUrl}/assets/${sign}xs_${crop}_def_${image_url} 480w`, // 120px
        `${serverUrl}/assets/${sign}sm_0_def_${image_url} 768w`, // 256px
      ],

      'extra-wide': [
        `${serverUrl}/assets/${sign}xsw_${crop}_def_${image_url} 480w`, // 120px
        `${serverUrl}/assets/${sign}xw_0_def_${image_url} 768w`, // 256px
      ],

      'default': [
        `${serverUrl}/assets/${sign}xs_${crop}_def_${image_url} 480w`,
        `${serverUrl}/assets/${sign}sm_${crop}_def_${image_url} 768w`,
        `${serverUrl}/assets/${sign}hd_0_def_${image_url} 1440w`
      ],

      'pink': [`${serverUrl}/assets/${sign}sm_${crop}_pink_${image_url} 480w`,],
      'red': [`${serverUrl}/assets/${sign}xs_${crop}_red__${image_url} 480w`,],
      'petrol': [`${serverUrl}/assets/${sign}xs_${crop}_petrol_${image_url} 480w`,],

      'video-red': [
        `${serverUrl}/assets/${sign}xs_${crop}_red_${image_url} 480w`,
        `${serverUrl}/assets/${sign}sm_${crop}_red_${image_url} 768w`,
      ]
    }

    if (!src) {
      setSrcset({
        src: `${serverUrl}/images/missing.svg`,
        preview: ``,
        set:[`${serverUrl}/images/missing.svg`]
      })

    } else {

      let srcstr = 'hd'
      switch (type){
        case 'mini':
          srcstr = 'xs'
          break
        case 'thumb':
          srcstr = 'thumb'
          break
      }
      
      setSrcset({
        //src: `${serverUrl}/assets/_hd_${crop}_def_${image_url}`,
        src: `${serverUrl}/assets/${sign}${srcstr}_${crop}_def_${image_url}`,
        preview: `${serverUrl}/assets/_preview_${crop}_def_${image_url}`,
        set: imageSizes[type]
      })
    }
    if (!alt) alt = 'placeholder image'
  }, [src, srcdata])


  if (!srcset) return <Fragment>{type}{JSON.stringify(srcset)}</Fragment>

  const fadein = () => {
    if (previewref && previewref.current){
      anime({
        targets:[previewref.current],
        opacity:0,
        duration: 1000
      })
    }
  }

  return (
    <Fragment>
      <img onLoad={ ()=>fadein() } ref={ref} onError={e => { e.target.style.display = 'none' }} src={srcset.src} sizes="75vw" srcSet={srcset.set.join(', ')} alt={alt} className={className} loading="lazy" style={style} />
      {preview && <img ref={previewref} src={ srcset.preview } alt={alt} className={ `absolute top-0 ${className ? className.replace('relative',''):''}`} style={style} /> }
    </Fragment>
  )
}