import * as vttToJson from 'vtt-to-json'


export function vttHandler(url) {

  this.isReady = false
  this.data = null
  this.IDD = null
  this.dataIdx = 0

  this.loadVTT = (fetchcb) => {
    fetch(url).then((d) => {
      d.text().then((txt) => {
        fetchcb(txt)
      })
    })
  }


  this.getCue = (ofs) =>{

  }


  this.startTracking = (hwl,cb) => {
    this.destroy()
    this.IDD = setInterval(()=>{
      var seek = Math.round(hwl.seek() * 1000)
      if (!this.isReady || seek==0 || this.dataIdx >= this.data.length) return      

      if (this.data[this.dataIdx].end < seek){
        this.dataIdx++
        cb('')
      }else if (this.data[this.dataIdx].start < seek){
        cb(this.data[this.dataIdx].part)
      }else{
        cb('')
      }
      console.log(seek,this.dataIdx,this.data[this.dataIdx])
    },300)
  }

  this.destroy = () => {
    clearInterval(this.IDD)
  }

  this.reset = () => {
    this.dataIdx = 0
  }

  this.init = () =>{
    this.loadVTT((txt)=>{
      txt += '\n\r'
      vttToJson(txt).then((js) => {
        this.data = js
        this.isReady = true
        console.log(js)
      })
    })
  }


  this.init()

}




