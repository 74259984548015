import React, { Fragment } from "react"
import { useRecoilState } from 'recoil'
import { overlayTool } from '../../../AppAtoms'
import { OverlayCompareSwiper } from "./OverlayCompareSwiper"
import { OverlayDocZoomer } from "./OverlayDocZoomer"

export const OverlayTools = () => {

  const [_OverlayTool,_setOverlayTool] = useRecoilState(overlayTool) 

  if (!_OverlayTool) return <Fragment />

  return (
    <div className="overlay-tools absolute w-full h-full">
      {_OverlayTool.type == 'doczoomer' && <OverlayDocZoomer data={_OverlayTool.data} color={_OverlayTool.color} />}
      {_OverlayTool.type == 'compareswipe' && <OverlayCompareSwiper data={_OverlayTool.data} color={_OverlayTool.color} />}
    </div>)
}