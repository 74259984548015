import React, { useState, Fragment, useEffect } from "react"
import './HowTo.sass'
import { IconClose, IconDirections } from "../icons"
import { cache, fetchData } from "../../utils/net"
import { userLang } from "../../utils/simpleLocalize"

export const HowTo = ({ onClose, slug = 'global.howto' }) => {

  const [helpData, setHelpData] = useState('')

  useEffect(() => {
    const id = 'global.howto'
    var data = cache.get('inlinehelp', id, null)
    if (data === null) {
      fetchData('/api/inlinehelp/' + userLang + '/' + id, (data) => {
        if (!data.error) {
          data = data.pop()
          console.log(data)
          cache.setKey('inlinehelp', id, data)
          setHelpData(data)
        }
      })
    } else {
      setHelpData(data)
    }
  }, [])

  return (
    <div className="howto-help">
      <div className="wrap">
        <div className="popup flex flex-col p-1">
          <div className="header flex items-center w-full">
            <IconDirections />
            <div className="text-h4 pl-2 text-petrol-50 font-bold">{helpData && helpData.title}</div>
            <div className="flex-1"></div>
            <button onClick={() => onClose()}><IconClose /></button>
          </div>
          <div className="p-2 inner" dangerouslySetInnerHTML={{ __html: helpData.content }}></div>
        </div>
      </div>
    </div>
  )
}