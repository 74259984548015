import React from "react"
import { Image } from "../Image"

export const PageImage = ({data,color}) => {
  let src = ''
  let sx = {}
  if (data.height) sx.height = data.height
  return (
    <div className={`page-image relative ${data.class} text-${color}`} style={sx}>
      <Image src={ data.image.path } preview={true}/>
      <svg className="absolute bottom-0" width="1921" height="150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 150L1920 0L1920 150H0L0 150Z" fill="currentcolor"/>
      </svg>
    </div>
  )
}