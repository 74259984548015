import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { Swiper, Navigation, Parallax, Keyboard, Pagination } from 'swiper'
import { useSwiping } from "./hooks"
import { IconArrowLeft, IconArrowRight, IconClose, IconShare } from "../icons"
import { StorySlide } from "./StorySlide"
import { getStr, L } from "../../utils/simpleLocalize"
import { parseColor } from "../../utils/colors"
import { FixedBackground } from "./backgrounds"
import { ShareStory } from "./ShareStory"
import { useRecoilState, useRecoilValue } from 'recoil'
import { guidedTourOptions } from "../../AppAtoms"
import { ContentHeader } from "../ContentHeader"


Swiper.use([Parallax,Navigation, Keyboard,Pagination])



export const Scrollstory = ({ data, onClose, options, onShare }) => {

  const swref = useRef()
  const [slideIndex,setSlideIndex] = useState(-1)
  const [isLast,setIsLast] = useState(false)
  const [curScreens,setCurrentScreens] = useState([])
  const [fixedBackgrounds, setFixedBackgrounds] = useState([])

  
  const tourOptions = useRecoilValue( guidedTourOptions )

  useEffect(()=>{
  
    const sw = new Swiper(swref.current,{
      //slidesPerView: 1,
      slidesPerView: 'auto',
      centeredSlides:true,
      speed: 1200,
      spaceBetween: 0,
      parallax:true,
      watchSlidesProgress: true,
      modules:[Parallax, Navigation, Keyboard,Pagination],
      // pagination: {
      //   el: '.dot-holder',
      //   type: 'bullets',
      // },      
      navigation:{
        nextEl:'.story-nav-next',
        prevEl:'.story-nav-prev'
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },      
    })

    sw.on('slideChange',()=>{
      //setSlideIndex(sw.activeIndex)
      setIsLast( sw.slides.length-1 == sw.activeIndex )
    })

    return ( (r)=>{
    })
  },[])


  
  useEffect( ()=>{

    if (!data.screens) return

    var fixedBackgrounds = []
    var r = data.screens.map( (sc,i)=>{
      if (sc.fixed_background_count && sc.fixed_background_count>0 && sc.background){
        var meta = []
        fixedBackgrounds.push({
          index: i,
          count: Number(sc.fixed_background_count),
          title: sc.title,
          bg: sc.background[0],
          meta: sc.meta ? {...sc.meta} : null
        })
        sc.bgCopyright = ''
        if (sc.background[0]){
          if (sc.background[0].settings.copyright) sc.bgCopyright += sc.background[0].settings.copyright
          if (sc.background[0].settings.image && sc.background[0].settings.image.meta) sc.bgCopyright += sc.background[0].settings.image.meta.rights || ''  
        }
        sc.background = []        
      }
      return sc
    })

    //var r = data.screens.map( (el)=>{ return el })
    data.FixedBackground = fixedBackgrounds
    setCurrentScreens(r)
    setFixedBackgrounds(fixedBackgrounds)

    var idd = setInterval(()=>{
      if (swref.current && swref.current.swiper){
        swref.current.swiper.update()
        clearInterval(idd)
      }
    },10)
  },data.screens)

  

  return (
    <div className={`scrollstory w-full h-full anim-slide-left ${ options && options.skewed ? 'skew':''}`} data-active={slideIndex}>

      <ProgressBar swref={swref.current} color={ parseColor(data.color) }/>

      <ContentHeader title={data.title} onClick={ e=>onClose() } color={parseColor(data.color) } className="pt-1"/>

      {/* <header className="z-30 absolute pt-2 flex items-center hide-on-tour">
        <button aria-label={getStr('ui.closeStory')} className={`block`} onClick={ e=>onClose() }><IconClose/></button>
        <div className="uppercase text-h5">{data.title}</div>
      </header> */}

      <div className="swiper-container h-full" ref={swref}>
        {fixedBackgrounds.length > 0 && curScreens && <div className="fixed-bgs absolute top-0 w-full h-full overflow-hidden">
          {fixedBackgrounds.map( (bg,i)=>{
            return <Fragment key={i}><FixedBackground swref={swref} bg={bg} /></Fragment>
          })}          
        </div>}
        <div className="swiper-wrapper">
          {curScreens.map((e, i) => {
            let style = {}
            if (e.options && e.options.width){
              style.width = e.options.width
            }            
            return <div className="swiper-slide" key={i} style={style} data-id={e.title}>
              <StorySlide sw={swref.current.swiper} index={i} data={e} color={ parseColor(data.color) }/>
            </div>
          })}
        </div>
      </div>

      <footer className="overlay-footer absolute bottom-0 w-full z-10 pointer-events-none">        
        <div className={ `absolute bottom-0 right-0 flex w-full ${isLast ? 'hide-block' : 'show-block'}`}>
          <div className="dot-holder flex-1 flex items-center pl-2 md:pl-6 pr-2 flex-wrap">
          </div>
          <button aria-label={getStr('ui.prevSlide')} className="x1 block story-nav-prev pointer-events-auto hide-on-tour" ><IconArrowLeft/></button>
          <button aria-label={getStr('ui.nextSlide')} className={`block story-bg story-nav-next pointer-events-auto hide-on-tour`} ><IconArrowRight/></button>
        </div>
        <div className={ `hide-on-tour pointer-events-none absolute bottom-0 right-0 items-end flex ${!isLast ? 'hide-block' : 'show-block'}`}>
          <button aria-label={getStr('ui.prevSlide')} className="block story-nav-prev" ><IconArrowLeft/></button>
          <button aria-label={getStr('ui.shareStory')} className={`block story-bg flex items-center justify-center mr-1px ml-1px pr-4`} onClick={ e=>onShare(data.title) }><IconShare/><span><L id="ui.share_story"/></span></button>
          {/* <button aria-label={getStr('ui.closeStory')} className={`block story-bg flex items-center justify-center`} onClick={ e=>onClose() }><IconClose/></button> */}
        </div>
      </footer>     
      <TourRangeHandler swref={swref.current} />
    </div>
  )
}

const TourRangeHandler = ({swref}) => {
  const tourOptions = useRecoilValue( guidedTourOptions )
  useEffect(()=>{
    if (swref && swref.swiper && tourOptions){
      swref.swiper.slideTo( tourOptions.idx,2000 )
    }
  },[tourOptions])
  return <Fragment />
}


const ProgressBar = ({swref,color}) => {
  const [wi,setWi] = useState(0)
  useEffect(()=>{
    if (swref && swref.swiper){
      swref.swiper.on('progress',(e,pct)=>{
        setWi( pct*100 )
      })
    }
  },[swref])
  return <div className={`scroll-progress-bar ${color}`}><div className="block" style={{width:wi+'%'}}></div></div>
}