import { GridHelper, Color, PerspectiveCamera, Scene, BoxGeometry, WebGLRenderer, MeshBasicMaterial, MeshPhongMaterial, Mesh, HemisphereLight, Vector3, Raycaster, PointLight, DoubleSide, SphereGeometry, ImageUtils, TextureLoader, Group, PlaneGeometry, Vector2 } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import anime from 'animejs/lib/anime.es'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { serverUrl } from '../../utils/net'
import { isMobile } from '../../App'

// draco
const draco = new DRACOLoader()
draco.setDecoderPath('/gltf/')
draco.setDecoderConfig({ type: 'js' })

export const scene = new Scene()
export const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 10000)
export const renderer = new WebGLRenderer()
const state = { current: false }

let gridHelper
let lookAtPosition = new Vector3(0, 0, 0)
let taregtLookAtPosition = null
let debugTag = null
export var controls
let ani = null
let markerClickCallback = null

export const wrapper = new Group()
scene.add(wrapper)

let debugMouse = null
let debugBox = null
let debugLight = null
let raycaster
let isShiftDown = false
let labelTagRef = null
let curCanvasRef = null

let objectRef = []

let currentLocationData = []
let controlsEnabled = true

var onThreeSixtyCallback = null
export const setThreeSixtyCallback = (cb) => {
  onThreeSixtyCallback = cb
}



//set render size
const setRenderSize = () => {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()
  renderer.setSize(window.innerWidth, window.innerHeight)
}


//render 3d
const render = () => {

  renderer.render(scene, camera)

  if (debugMouse && raycaster && isShiftDown) {
    camera.updateMatrixWorld()
    raycaster.setFromCamera(debugMouse, camera)
    const intersects = raycaster.intersectObjects(objectRef, true)
    for (let i = 0; i < intersects.length; i++) {
      debugBox.position.copy(intersects[i].point)
    }
  }

  currentLocationData.forEach((obj) => {
    let q = toScreenPosition(obj, camera)
    obj.tag.style.visibility = q.z > 1 ? 'hidden' : 'visible'
    obj.tag.style.left = q.x + 'px'
    obj.tag.style.top = q.y + 'px'
  })

  if (state.current === true) {
    requestAnimationFrame(render)
  }

  if (onThreeSixtyCallback){
    onThreeSixtyCallback()
  }

}


const toScreenPosition = (obj, camera) => {
  var vector = new Vector3()

  var widthHalf = 0.5 * renderer.getContext().canvas.width
  var heightHalf = 0.5 * renderer.getContext().canvas.height

  obj.updateMatrixWorld()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = (vector.x * widthHalf) + widthHalf
  vector.y = - (vector.y * heightHalf) + heightHalf

  return {
    x: vector.x,
    y: vector.y,
    z: vector.z,
  }
}


const strVector = (vector) => {
  var st = Math.round(vector.x) + ',' + Math.round(vector.y) + ',' + Math.round(vector.z)
  return st
}


//setup orbital controls
const setupOrbitalControls = () => {
  controls = new OrbitControls(camera, renderer.domElement)
  controls.target.set(0, 5, 0)
  controls.maxPolarAngle = 1.45
  controls.addEventListener('change', () => {
    if (taregtLookAtPosition) {
      controls.target.copy(taregtLookAtPosition)
    }
  })
  controls.addEventListener('end', () => {
    lookAtPosition = controls.target.clone()
    if (debugTag && debugTag.current) {
      debugTag.current.innerHTML = strVector(controls.target) + '$' + strVector(camera.position)
    } else {
    }
  })
  controls.update()
}


// setup the map and debug 
export const setupMap = (canvasRef, labelRef, debugRef, clickCallback) => {

  camera.position.z = 100
  setRenderSize()
  window.onresize = () => {
    setRenderSize()
  }
  setupOrbitalControls()

  labelTagRef = labelRef
  markerClickCallback = clickCallback
  curCanvasRef = canvasRef

  let light = new HemisphereLight(0xffffff, 0x080808, 1) // soft white light
  scene.add(light)

  canvasRef.appendChild(renderer.domElement)
  debugTag = debugRef

  if (debugTag && debugTag.current) {
    appendDebug()
  }

}


//parse loaded model
const parseModels = (i) => {
  objectRef.push(i)
  i.material = new MeshPhongMaterial({ shininess: 0 })
  i.material.side = DoubleSide
  switch (i.name) {
    case "obj_bridge":
    case "obj_stadium":
    case "obj_centralstation":
    case "obj_post":
    case "obj_dom":
      i.material.color.setHex(0x227CAD)
      break
    case 'city_inner':
    case 'city_outer':
      i.material.color.setHex(0x586068)
      i.material.opacity = 0.6
      i.material.transparent = true
      break
    case 'andreasstrasse':
      i.material.color.setHex(0x63B9AD)
      break
    case 'roads':
      i.material.opacity = 1
      i.material.color.setHex(0x232B33)
      break
    default:
      if (String(i.name).substr(0, 2) === 'h_') {
        i.material.color.setHex(0x63B9AD)
      }
  }
  return i
}


const appendDebug = () => {
  // let g =new GridHelper(2000,10)
  // scene.add( g)
  // objectRef.push(g)

  const geometry = new BoxGeometry(5, 5, 5)
  debugBox = new Mesh(geometry, new MeshBasicMaterial({ color: 0xff0000 }))
  scene.add(debugBox)

  debugLight = new PointLight(0xff0000, 2, 50)

  debugLight.castShadow = true
  debugLight.shadow.mapSize.width = 512
  debugLight.shadow.mapSize.height = 512
  debugLight.shadow.camera.near = 10
  debugLight.shadow.camera.far = 200
  debugLight.shadow.focus = 1
  debugLight.add(new Mesh(geometry, new MeshBasicMaterial({ color: 0xff00ff })))
  scene.add(debugLight)

  raycaster = new Raycaster()

  document.addEventListener('keydown', (e) => { if (e.keyCode === 18) { isShiftDown = true } })
  document.addEventListener('keyup', (e) => {
    if (e.keyCode === 18) {
      isShiftDown = false
      debugBox.position.y = 0
      if (debugTag.current){
        debugTag.current.innerHTML = 'OBJ: ' + strVector(debugBox.position)
      }else{
        
      }
      taregtLookAtPosition = new Vector3()
      taregtLookAtPosition.copy(debugBox.position)

      debugLight.position.copy(debugBox.position)
      debugLight.position.y = 10
    }
  })

  window.addEventListener('mousemove', () => {
    debugMouse = {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: - (event.clientY / window.innerHeight) * 2 + 1
    }
  }, false)

}


// start loading the models
export const loadModels = (urls) => {
  let urls_to_load = [...urls]

  const loadData = (pathInfo) => {
    let loader = new GLTFLoader()
    loader.setDRACOLoader(draco)
    loader.load(pathInfo.url, (gltf) => {
      const root = gltf.scene
      root.children.forEach((el) => {
        return el = parseModels(el)
      })
            
      //scene.add(root)
      wrapper.add(root)

      if (urls_to_load.length > 0) {
        loadData(urls_to_load.shift())
      }
    }, (xhr) => {
      
    }, (error) => {
      console.warn(error)
    })

  }

  loadData(urls_to_load.shift())
}


// convert string to cam + lookat positin
const stringToCoords = (st) => {
  const [lookAt, cam] = st.split('$')
  const [lookX, lookY, lookZ] = lookAt.split(',')
  const [camX, camY, camZ] = cam.split(',')

  return {
    lx: Number(lookX),
    ly: Number(lookY),
    lz: Number(lookZ),
    cx: Number(camX),
    cy: Number(camY),
    cz: Number(camZ),
  }
}


var CamShift = {value:0}
var camShiftAnim
export const setCameraShift = (isOn) => {
  const wi = window.innerWidth
  const hi = window.innerHeight

  if (camShiftAnim) camShiftAnim.pause() 
  
  var targetValue = isMobile() ? hi/6 : wi/4
  camShiftAnim = anime({
    targets:[CamShift],
    value: isOn ? targetValue : 0,
    duration: 1000,
    easing: 'easeInOutSine',
    update:()=>{
      if (isMobile()){
        camera.setViewOffset(wi,hi, 0, CamShift.value , wi, hi)
      }else{
        camera.setViewOffset(wi,hi, CamShift.value, 0 , wi, hi)
      }      
    },
    complete:()=>{
      camShiftAnim = null
    }
  })



  if (isMobile){

  }else{
  

  }

}


//-254,60,-132$-223,79,-127
export const slideToByString = (cords, mode, cb) => {
  if (!controlsEnabled) return
  let cityInner = scene.getObjectByName('city_inner')

  let animProps = {
    lx: lookAtPosition.x,
    ly: lookAtPosition.y,
    lz: lookAtPosition.z,
    cx: camera.position.x,
    cy: camera.position.y,
    cz: camera.position.z,
    cityAlpha: cityInner ? cityInner.material.opacity : 0.6
  }

  if (cords === null) {
    cords = '-113,0,-605$-191,83,-661'
    console.warn('NO Camera cords - use default', cords)
  }

  const [lookAt, cam] = cords.split('$')
  var [lookX, lookY, lookZ] = lookAt.split(',')
  var [camX, camY, camZ] = cam.split(',')

  controls.enabled = false
  
  taregtLookAtPosition = mode.substr(0, 4) === 'area' ? new Vector3(Number(lookX), Number(lookY), Number(lookZ)) : null

  if (ani) {
    ani.pause()
    anime.remove(ani)
  }

  //animObj = stringToCoords(cords)
  
  ani = anime({
    targets: animProps,
    lx: Number(lookX),
    ly: Number(lookY),
    lz: Number(lookZ),
    cx: Number(camX),
    cy: Number(camY),
    cz: Number(camZ),
    cityAlpha: mode === 'area' ? 0 : 0.6,
    duration: 2000,
    easing: 'easeInOutSine',
    update: () => {
      if (!controlsEnabled) return
      lookAtPosition.x = animProps.lx
      lookAtPosition.y = animProps.ly
      lookAtPosition.z = animProps.lz
      camera.position.x = animProps.cx
      camera.position.y = animProps.cy
      camera.position.z = animProps.cz
      camera.lookAt(lookAtPosition)
      if (cityInner) {
        cityInner.material.opacity = animProps.cityAlpha
      }
    },
    complete: () => {
      if (!controlsEnabled) return
      camera.lookAt(lookAtPosition)
      controls.target.copy(lookAtPosition)
      controls.update()
      controls.enabled = controlsEnabled
      ani = null
      if (cb) {
        cb()
      }      
    }
  })
}

let animIdx = -1
let curAnim = null

const initAnim = (f, t) => {

  if (curAnim !== null) curAnim.pause()

  var animProps = stringToCoords(f)
  var props = stringToCoords(t)
  props.duration = 30000
  props.easing = 'linear'
  props.targets = [animProps]
  props.update = () => {
    lookAtPosition.x = animProps.lx
    lookAtPosition.y = animProps.ly
    lookAtPosition.z = animProps.lz
    camera.position.x = animProps.cx
    camera.position.y = animProps.cy
    camera.position.z = animProps.cz
    camera.lookAt(lookAtPosition)
  }
  props.complete = () => {
    if (!controlsEnabled) return
    if (animIdx != -1) startAnim()
  }
  curAnim = anime(props)
}


export const startAnim = () => {
  if (!controlsEnabled) return
  var animlist = [
    { from: '-165,82,-12$-165,258,-12', to: '-85,82,-614$-85,258,-614' },
    { from: '5,-20,1$502,45,-701', to: '-231,-116,-203$-30,42,-749' },
    { from: '635,-116,-565$635,264,-565', to: '-119,-116,-579$-115,123,-573' },
  ]
  animIdx = (animIdx + 1) % animlist.length
  initAnim(animlist[animIdx].from, animlist[animIdx].to)
}


export const stopAnim = () => {  
  animIdx = -1
  if (curAnim) {
    curAnim.pause()
    curAnim.reset()
  }
}


export const setMapMarkers = (data) => {
  clearAllMarkers()
  data.forEach((locObj) => {  
    if (locObj.topic_link) {
      createMarker(locObj, 4, 'point')
    } else {
      createMarker(locObj, 0, 'text')
    }
  })
}


const createMarker = (el, size, type, loc) => {
  const colorList = {
    red: 0xff0000,
    blue: 0x227CAD,
    petrol: 0x63B9AD,
    pink: 0xAD287D
  }

  const [xx, yy, zz] = String(el.geo_location.geo).split(',')

  let newref = null

  const geometry = new BoxGeometry(0.1, 0.1, 0.1)
  const material = new MeshBasicMaterial({ color: 0x000000 })
  newref = new Mesh(geometry, material)
  newref.position.x = xx
  newref.position.y = yy
  newref.position.z = zz

  var d = document.createElement('div')
  d.classList.add('marker')
  d.classList.add(type)
  d.classList.add(el.topic_slug)  
  d.innerHTML = '<span class="title">' + el.title + '</span><span class="cat">' + el.topic_title + '</span>'

  if (type != 'text'){
    d.addEventListener('click', function () {
      if (markerClickCallback) markerClickCallback(el)
    })  
  }

  if (newref) {
    newref.tag = d
    newref._id = el._id
    scene.add(newref)
    currentLocationData.push(newref)
  }
  labelTagRef.appendChild(d)
}


/**
 * clear all markers
 */
export const clearAllMarkers = () => {
  currentLocationData.map((el) => scene.remove(el) )
  currentLocationData = []
  labelTagRef.innerHTML = ''
}


/**
 * selecte the current location marker
 * @param {} currentLocation 
 */
export const setSelectedMarker = (currentLocation) => {
  currentLocationData.map((el) => { el.tag.classList.remove('selected') } )
  currentLocationData.map((el) => {
    if (!currentLocation){
      el.tag.classList.remove('selected')
      el.tag.classList.remove('dimmed')
    }else{
      el.tag.classList.add( el._id == currentLocation._id ? 'selected' : 'dimmed')
    }
  })
}


export const setActive = (opt) => {
  state.current = opt
  render()
}


export const mapActive = (opt) => {
  controlsEnabled = opt
  controls.enabled = opt
  wrapper.visible = opt
  stopAnim()
}