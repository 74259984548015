import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { routeRoot_city } from '../../App'
import { parseColor } from '../../utils/colors'
import { onHover } from "../header/SoundHandler"
import { Image } from '../Image'
import { Ratio } from '../Ratio'

import './TopicItem.sass'


export const TopicItem = ({data,rootUrl,onClick}) => {  
  return (
    <Link to={ rootUrl + '/' + data.slug_slug } className={`topic-item flex sm:flex-col block w-full h-full items-center bg-basic-${ parseColor(data.color) }`} onClick={ e=>onClick(data)} {...onHover}>
      <div className="image-wrap w-full bg-uigrey-darkhover">
        <Ratio ratio="1x1" className="ratio hidden sm:block" />
        <Ratio ratio="16x9" className="ratio sm:hidden" />
        <Image className="absolute top-0 object-cover w-full h-full" type="mini" srcdata={ data.image } />
      </div>
      <div className="p-1 sm:p-2 text-left flex flex-col">
        <h3 className="sm:text-h3 font-semibold">{data.title}</h3>
        <p className="pt-1 hidden sm:block">{data.short_description}</p>
      </div>      
    </Link>
  )
}