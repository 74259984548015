import React, { Fragment, useEffect, useRef, useState } from "react"
import { OverlayWindow } from "./OverlayWindow"
import { useRecoilState } from 'recoil'
import { overlayTool } from '../../../AppAtoms'
import { parseImageUrl } from "../../Image"
import { serverUrl } from "../../../utils/net"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import './OverlayDocZoomer.sass'
import { IconZoomIn, IconZoomMedia, IconZoomOut } from "../../icons"
import { getStr, L } from "../../../utils/simpleLocalize"
import { Copyright } from "../../Copyright"
import { isDebug } from "../../../utils/tool"
import { ContentHeader } from "../../ContentHeader"
import { isMobileLayout } from "../../../App"



export const OverlayDocZoomerButton = ({ data, color }) => {
  const [_OverlayTool, _setOverlayTool] = useRecoilState(overlayTool)
  var overlayData = { type: 'doczoomer', data: data, color: color }
  var p_style = {}

  if (!isMobileLayout()){
    switch(data.position){
      case 'top-left': 
        p_style = { right: 'auto', top: 128, left: 64 }
        break
      case 'top-right': 
        p_style = { right: 64, bottom: 'auto', left: 'auto', top: 128 }
        break
      case 'bottom-left': 
        p_style = { right: 'auto', bottom: 96, left: 64 }
        break
      default: p_style = { right: 64, bottom: 96, left: 'auto' }
    }  
  }

  return (
    <div>
      <button aria-label={getStr('ui.zoomoverlay')} style={p_style} className="btn-overlay btn-zoom bg-petrol-50 shadow-md font-bold absolute uppercase" onClick={e => _setOverlayTool(overlayData)}>
        <IconZoomMedia /> 
        <span className="hidden sm:block"><L id="ui.viewdocument"></L></span>
        {/* {data.hotspot ? (data.hotspot.title ? data.hotspot.title: data.button_text ) : ''} */}
      </button>
    </div>
  )
}


export const OverlayDocZoomer = ({ data, color }) => {
  const [_OverlayTool, _setOverlayTool] = useRecoilState(overlayTool)
  const [imgUrl, setImgUrl] = useState('')
  const [imgCopyright, setImgCopyright] = useState('')


  useEffect(() => {
    var img_url = parseImageUrl(data.image.path)
    setImgUrl(`${serverUrl}/assets/${isDebug()?'!':'_'}full_0_def_${img_url}`)

    if (data.image.meta){
      setImgCopyright(data.title)
      //setImgCopyright(data.image.meta.title)
    }else{
      setImgCopyright(data.title)
    }

  }, [data])



  return (
    <OverlayWindow className="doc-zoomer" title={''} onClose={e => _setOverlayTool(null)} color={color}>
      <ContentHeader title={ 'Dokument' } color={color} onClick={e => _setOverlayTool(null)} className=""/>

      {imgUrl && <TransformWrapper centerOnInit={true} >

        {({ zoomIn, zoomOut, resetTransform }) => (
          <Fragment>

            <div className="absolute bottom-0 z-10 flex flex-row w-full items-center pl-2 md:pl-4">
              <div className='flex-1'>
                <Copyright className="z-2" data={ imgCopyright } type="text" />
              </div>              
              <div><button aria-label={getStr('ui.zoomin')} className={`block bg-${color}-50`} onClick={ e=>zoomOut() }><IconZoomOut/></button></div>
              <div><button aria-label={getStr('ui.zoomout')} className={`block bg-${color}-50`} onClick={ e=>zoomIn() }><IconZoomIn/></button></div>
            </div>

            <TransformComponent wrapperClass="wrap" >
              <img style={{height:'90vh'}} onLoad={e =>{ }} src={imgUrl} className="shadow-xl"/>
            </TransformComponent>
          </Fragment>
        )}
      </TransformWrapper>}
      
    </OverlayWindow>
  )

}