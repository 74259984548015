import React from 'react'


export const Loading = () => {
  return (
    <div className="loading-block">
      <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M64.2346 32.283C64.2346 49.9561 49.9077 64.283 32.2346 64.283C14.5615 64.283 0.234619 49.9561 0.234619 32.283C0.234619 14.6099 14.5615 0.282959 32.2346 0.282959C49.9077 0.282959 64.2346 14.6099 64.2346 32.283ZM61.3721 32.283C61.3721 48.3752 48.3269 61.4204 32.2346 61.4204C16.1423 61.4204 3.09717 48.3752 3.09717 32.283H9.08887C9.08887 19.5 19.4517 9.13721 32.2346 9.13721C45.0176 9.13721 55.3804 19.5 55.3804 32.283H61.3721Z" fill="#737373"/>
      </svg>
    </div>
  )
}