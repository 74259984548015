import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { routeRoot_city } from "../App"
import { cache } from "../utils/net"
import { getStr } from "../utils/simpleLocalize"
import { IconArrowRight, IconClose } from "./icons"
import { Image } from "./Image"
import { useHistory } from 'react-router-dom'
import { Ratio } from "./Ratio"
import { InlineHelp } from "./InlineHelp"

export const Startmenu = () => {

  const [menuOptions, setMenuOptions] = useState(cache.get('startmenu'))
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()

  const menuref = useRef()
  const startref = useRef()

  const gotoUrl = (url) => {
    openMenu(false)
    setTimeout(() => {
      history.push(url)
    }, 500)
  }

  const openMenu = (opt) => {
    let r = menuref.current.querySelector('.inner')
    if (opt === true) {

      setTimeout(() => {
        menuref.current.style.height = r.offsetHeight + 'px'
      }, 100)
      menuref.current.style.height = r.offsetHeight + 'px'
    } else {
      menuref.current.style.height = '80px'
    }
    setIsOpen(opt)
  }

  return (
    <div className={`start-menu absolute w-full h-full flex flex-col overflow-hidden pointer-events-none ${isOpen && 'open'}`}>

      <div className="cover w-full h-full"></div>
      <div className="flex-1 flex sm:items-center pl-2 sm:pl-4 sm:w-3/5 sm:pt-128px pt-12">
        <Greetings data={menuOptions.greeting_texts} />
        {!isOpen && menuOptions.banner_visible && <CircleBanner data={menuOptions} />}
      </div>

      <div ref={menuref} className="relative menu-wrap" >

        <div className="inner">

          <div className="pointer-events-auto pl-2 sm:pl-4 sm:pt-2 sm:pb-4 start-wrap overflow-hidden flex">
            {!isOpen && <button className="btn-arrow pr-4 bg-pink-25 hover:bg-pink-50" onClick={e => openMenu(true)} aria-label={getStr('ui.close')}><IconArrowRight />{getStr('startmenu.explore-now')}</button>}
            <div className="flex-1"></div>
            {isOpen && <button aria-label={getStr('ui.close')} className="sm:pr-4" onClick={e => openMenu(false)}><IconClose /></button>}
          </div>

          {isOpen && <InlineHelp
            className=""
            style={{ transform: 'translate(64px,calc(-100% + 16px)' }}
            mobileStyle={{ transform: 'translate(8px,calc(-100% + 64px)' }}
            id="startmenu.help"
          />}

          <div className={`flex flex-col sm:flex-row w-full sm:pb-4 pointer-events-auto ${!isOpen ? 'pt-10' : ''}`}>
            <div className="sm:w-2/3 sm:pl-4 pl-2 sm:pr-2 pr-2 flex sm:pb-0 pb-2">
              <div className="flex-1 sm:pr-2 pr-1 col-1">
                <button onClick={e => gotoUrl(menuOptions.col_1_link)} className="col-block btn-hover-scale bg-uigrey-dark" aria-label={menuOptions.col_1_title}>
                  <Image srcdata={menuOptions.col_1_asset} className="h-28" />
                  <div className="bg-pink-25 p-2 text-left">{menuOptions.col_1_title}</div>
                </button>
              </div>
              <div className="flex-1 sm:pl-2 col-2">
                <button onClick={e => gotoUrl(menuOptions.col_2_link)} className="col-block btn-hover-scale bg-uigrey-dark" aria-label={menuOptions.col_2_title}>
                  <Image srcdata={menuOptions.col_2_asset} className="h-28" />
                  <div className="bg-petrol-25 p-2 text-left">{menuOptions.col_2_title}</div>
                </button>
              </div>

            </div>


            <div className="sm:w-1/3 w-full sm:pr-4 pl-2 pr-2 col-3">
              {menuOptions.col_3_links && Object.keys(menuOptions.col_3_links).map((key, i) => {
                let url = menuOptions.col_3_links[key]
                return <button aria-label={key} className="btn-arrow block mb-1 w-full bg-uigrey-dark hover:bg-uigrey-darkhover" onClick={e => gotoUrl(url)} key={i}><IconArrowRight />{key}</button>
              })}
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}



const Greetings = ({ data }) => {
  const [greeting, setGreeting] = useState('')

  useEffect(() => {
    if (!data) return

    var new_s = ''
    var shadow_new_s = ''
    var delay = 40
    var idx = 0

    const idd = setInterval(() => {
      let st = data[idx].value
      if (new_s.length != st.length) {
        var char = st.charAt(new_s.length)
        var nChar = char
        switch (nChar) {
          case '§': nChar = '<br class="hidden sm:inline">'; break
          case '#': nChar = '<span class="hidden sm:inline">-<br></span>'; break
          case '%': nChar = '<br class="sm:hidden">'; break
          case '$': nChar = '<span class="sm:hidden">-<br></span>'; break
        }
        new_s = new_s + char
        shadow_new_s = shadow_new_s + nChar
        delay = 40
        setGreeting(shadow_new_s)
      } else {
        if (delay-- == 0) {
          idx = (idx + 1) % data.length
          new_s = ''
          shadow_new_s = ''
        }
      }
    }, 100)

    return () => {
      clearInterval(idd)
    }
  }, [])

  return (
    <h2 className="title relative z-10 font-bold leading-none" style={{ minHeight: '2em' }} dangerouslySetInnerHTML={{ __html: greeting }} ></h2>
  )

}


const CircleBanner = ({ data }) => {
  return (
    <a href={data.banner_link} target="_blank" rel="noreferrer" className="circle-banner anim-slide-left absolute right-0 bottom-0 flex flex-col items-center justify-center text-center pointer-events-auto">
      <span dangerouslySetInnerHTML={{ __html: data.banner_text }}></span>
      <IconArrowRight />
    </a>
  )
}