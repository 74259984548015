import React, { useEffect, useRef, useState } from 'react'
import { glossaryParse, useGlossary } from "../Glossary"



export const PageText = ({data}) => {
  const [txt,setTxt] = useState('')
  const ref = useRef()
  const g = useGlossary(ref)

  useEffect(()=>{
    setTxt(glossaryParse(data.text))
  },[])

  return <div ref={ref} className={`text container ${data.class}`}  dangerouslySetInnerHTML={{__html: txt}}></div>
}