import React, { Fragment, useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import { soundVolume } from "../../../AppAtoms"
import { getStr, userLang } from "../../../utils/simpleLocalize"
import { parseTextarea } from '../../../utils/tool'
import { IconPause, IconPlay, IconSoundOff, IconSoundOn, IconSubtitles } from "../../icons"



export const OverlayVideocontrol = ({ videoref,trackref, active, title }) => {

  const [playState, setPlayState] = useState('')
  const [muted, setMuted] = useState(true)
  const [showsub, setShowsub] = useState(()=>{
    if (userLang !== 'de'){
      return localStorage.getItem('show_sub') == 'no' ? false : true
    }else{
      return localStorage.getItem('show_sub') == 'yes' ? true : false
    }
  })
  const [musicVolume, setMusicVolume] = useRecoilState(soundVolume)

  const isPlaying = (el) => {
    return !!(el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2)
  }

  const seekVideo = (ofs) => {
    if (videoref) {
      videoref.currentTime = videoref.duration * ofs
    }
  }

  const toggleSubs = (opt) => {
    localStorage.setItem('show_sub',opt ? 'yes' : 'no')
    setShowsub(opt)
  }

  useEffect(() => {
    if (!videoref) return
    const eventPlay = () => {
      setMusicVolume( (org)=>{ return {...org,...{break:true} } } )
      setPlayState('play')
    }
    const eventPause = () => {
      setMusicVolume( (org)=>{ return {...org,...{break:false} } } )
      setPlayState('stop')
    }
    const eventLoadstart = () => {      
    }

    videoref.addEventListener('playing', eventPlay , false)
    videoref.addEventListener('pause', eventPause, false)
    videoref.addEventListener('loadstart', eventLoadstart , false)
    videoref.muted = false

    setPlayState(isPlaying(videoref) ? 'play' : 'stop')
    setMuted(videoref.muted)

    return ()=>{
      videoref.removeEventListener('playing', eventPlay )
      videoref.removeEventListener('pause', eventPause)
      videoref.removeEventListener('loadstart', eventLoadstart )  
    }
  }, [videoref])


  const playVideo = ()=>{
    var vPromis = videoref.play()
    if (vPromis) vPromis.then( ()=>{}  ).catch(err=>{})
  }


  useEffect(() => {
    if (active) {
      if (videoref) {
        
      }
    } else {
      if (videoref) {
        videoref.pause()
      }
    }
  }, [active])

  return (
    <div className={`overlay-video-control pointer-events-auto absolute ${active ? 'active' : ''}`}>
      <div className="flex flex-row">
        {showsub && <SubTitles trackref={trackref} />}
        <Progressbar active={active} videoref={videoref} onSeek={(ofs) => seekVideo(ofs)} className="flex-1" />
        <div className="flex">
          {playState === 'stop' && <button aria-label={getStr('ui.play')} className="w-8 ml-1px" onClick={e => playVideo() }><IconPlay /></button>}
          {playState === 'play' && <button aria-label={getStr('ui.pause')} className="w-8 ml-1px" onClick={e => videoref.pause()} ><IconPause /></button>}
        </div>
        <div className="flex">
          {showsub && <button aria-label={getStr('ui.subtitle')} className="w-8 ml-1px" onClick={e => { toggleSubs(!showsub) }}><IconSubtitles /></button>}
          {!showsub && <button aria-label={getStr('ui.showsubtitle')} className="w-8 ml-1px" onClick={e => { toggleSubs(!showsub) }}><IconSubtitles className="opacity-10"/></button>}
          
        </div>
        <div className="flex">
          {muted && <button aria-label={getStr('ui.mute')} className="w-8 ml-1px" onClick={e => { videoref.muted = !muted; setMuted(!muted) }}><IconSoundOff /></button>}
          {!muted && <button aria-label={getStr('ui.unmute')} className="w-8 ml-1px" onClick={e => { videoref.muted = !muted; setMuted(!muted) }}><IconSoundOn /></button>}
        </div>
      </div>
      {title && <div className="px-2 text-shadow-1 absolute captions">
        {parseTextarea(title)}
      </div>}

    </div>
  )
}


const SubTitles = ({trackref}) => {

  const [tractText,setTrackText] = useState([])

  useEffect(()=>{
    console.log('SUBTITLES', trackref)
    if (!trackref) return
    trackref.addEventListener('cuechange',(e)=>{
      
      if (e.target.track.activeCues){
        var txt = []
        for(var a in e.target.track.activeCues){
          if (e.target.track.activeCues[a].text){
            txt.push( {id:a ,txt:e.target.track.activeCues[a].text} )
          }
        }
        setTrackText(txt)
      }else{
        setTrackText([])
      }
    })
  },[trackref])

  
  return <div className="sub-title">
    {tractText.map( (t)=>{
      return <div key={t.id}>{t.txt}</div>
    })}
  </div>

}


const Progressbar = ({ videoref, active, className, onSeek }) => {

  const [pct, setPct] = useState(0)

  const barRef = useRef()

  const trackStatus = () => {
    if (videoref) {
      let m = videoref.duration
      let t = videoref.currentTime
      setPct(t / m)
    }
    if (active) {
      requestAnimationFrame(trackStatus)
    }
  }

  const triggerSeek = (e) => {
    var ofs = e.clientX - 32 - 16
    var seek = ofs / (barRef.current.clientWidth - 32)
    seek = (seek < 0) ? 0 : (seek > 1) ? 1 : seek
    if (onSeek) onSeek(seek)
  }

  useEffect(() => {
    if (active) {
      trackStatus()
    }
  }, [active])

  return (
    <div ref={barRef} className={`progress-bar ${className} pl-2 pr-2 flex justify-center items-center`} onClick={e => triggerSeek(e)}>
      <div className="flex-1">
        <div className="block bg-white-default" style={{ width: pct * 100 + '%' }} ></div>
      </div>
    </div>
  )

}