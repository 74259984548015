import { Vector3 } from "three"

const floor = (n) => {
  return Math.round(n * 1000) / 1000
}



export const planeControl = (plane,cam,controls,boxholder) => {

  const updateCam = () =>{

    //controls.enabled = false

    var t = boxholder.getObjectByName('box')
    var tg = new Vector3()
    t.getWorldPosition(tg)

    controls.enabled = false
    cam.lookAt(tg)
    controls.enabled = true    
  }
  

  document.addEventListener('keydown',(e)=>{
    var speed = e.shiftKey ? 10 : 1
    switch( e.key.toLowerCase() ){

      case 'f':
        boxholder.rotation.y += Math.PI / 180 * 0.5        
        updateCam()
        break
      case 'g':
        boxholder.rotation.y -= Math.PI / 180 * 0.5
        updateCam()
        break
      case 't':
        boxholder.rotation.z += Math.PI / 180 * 0.5
        updateCam()
        break
      case 'v':
        boxholder.rotation.z -= Math.PI / 180 * 0.5
        updateCam()
        break

      case 'o': plane.position.x += speed; break
      case 'p': plane.position.x -= speed; break

      case 'i': plane.position.y += speed; break
      case 'm': plane.position.y -= speed; break

      case 'k': plane.position.z += speed; break
      case 'j': plane.position.z -= speed; break

      case 'a': plane.rotation.z -= Math.PI / 180; break
      case 's': plane.rotation.z += Math.PI / 180; break

      case 'q': plane.rotation.y -= Math.PI / 180; break
      case 'w': plane.rotation.y += Math.PI / 180; break

      case 'y': plane.rotation.x -= Math.PI / 180; break
      case 'x': plane.rotation.x += Math.PI / 180; break

      case '1': plane.scale.x *= 1.01; break
      case '2': plane.scale.x /= 1.01; break

      case '3': plane.scale.y *= 1.01; break
      case '4': plane.scale.y /= 1.01; break

      case '5': plane.scale.z *= 1.01; break
      case '6': plane.scale.z /= 1.01; break

      case ',': 
        plane.scale.x *= 1.01
        plane.scale.y *= 1.01 
        plane.scale.z *= 1.01
        break
      case '.': 
        plane.scale.x /= 1.01
        plane.scale.y /= 1.01 
        plane.scale.z /= 1.01
        break
    }

    // console.info(plane.data.title,':', [
    //   floor(plane.position.x),
    //   floor(plane.position.y),
    //   floor(plane.position.z),

    //   floor(plane.rotation.x),
    //   floor(plane.rotation.y),
    //   floor(plane.rotation.z), 

    //   floor(plane.scale.x),
    //   floor(plane.scale.y),
    //   floor(plane.scale.z),
    // ].join('_') 
    // )

  })  
}