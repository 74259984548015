import React, { Fragment, useEffect, useState } from "react"
import {L} from './../../utils/simpleLocalize'
import {IconPrisonContact,IconPrisonEscape,IconPrisonProtest,IconPrisonPublicDist,IconPrisonSabotage,IconPrisonSpying} from './../../components/icons'

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const Graph = ({ dataRow, yearMin, total, onSelect }) => {

  //const [total,setTotal] = useState()

  if (!dataRow || !dataRow.row) return <Fragment />
 
  return (
    <div>
      <header className="flex items-center w-fill block px-4 pb-2">
        <div className="flex items-center icon inline-block">

          {dataRow.icon && String(dataRow.icon).toLowerCase()=='iconprisoncontact' && <IconPrisonContact/>}
          {dataRow.icon && String(dataRow.icon).toLowerCase()=='iconprisonescape' && <IconPrisonEscape/>}
          {dataRow.icon && String(dataRow.icon).toLowerCase()=='iconprisonprotest' && <IconPrisonProtest/>}
          {dataRow.icon && String(dataRow.icon).toLowerCase()=='iconprisonpublicdist' && <IconPrisonPublicDist/>}
          {dataRow.icon && String(dataRow.icon).toLowerCase()=='iconprisonsabotage' && <IconPrisonSabotage/>}
          {dataRow.icon && String(dataRow.icon).toLowerCase()=='iconprisonspying' && <IconPrisonSpying/>}

        </div>
        <div className="summary flex-1 text-right">
          <div className="text-h2 font-bold">{total}</div>
          <div className="uppercase text-h5"><L id="prisonstats.total"/></div>
        </div>
      </header>

      <div className="graph-wrapper">

        <div className="px-4 h-full">
          <div className="flex flex-col h-full">
            <div className="bars flex-1 flex relative pr-3">
              <div className="absolute bg-lines">
                <div className="line">{dataRow.max}</div>
                <div className="line">{Math.round(dataRow.max / 5 * 4)}</div>
                <div className="line">{Math.round(dataRow.max / 5 * 3)}</div>
                <div className="line">{Math.round(dataRow.max / 5 * 2)}</div>
                <div className="line">{Math.round(dataRow.max / 5)}</div>
              </div>
              {dataRow.row.map((val, i) => {
                return <div className="bar z-5" key={val + '_' + i} idx={Number(yearMin)+i} id={`bar_${i}`} onClick={ ()=>{ onSelect(i) } }>
                  <div className="inner a" style={{ height: Math.round(val[2] / dataRow.max * 100) + '%' }} />
                  <div className="inner b" style={{ height: Math.round(val[1] / dataRow.max * 100) + '%' }} />
                  <div className="inner c" style={{ height: Math.round(val[0] / dataRow.max * 100) + '%' }} />
                </div>
              })}
            </div>

            <div className="flex">
              <div>{dataRow.year_min}</div>
              <div className="flex-1"></div>
              <div>{dataRow.year_max}</div>
            </div>

          </div>
        </div>
      </div>
    </div>


  )
}

