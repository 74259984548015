import React from 'react'
import { Link } from 'react-router-dom'
import { onHover } from "../header/SoundHandler"
import { Image } from '../Image'
import { Ratio } from '../Ratio'

export const LocationItem = ({data,onClick, color, urlprefix='', filter}) => {
  let url = data.slug_slug || data._id

  if (filter){
    url += filter
  }

  return (
    <Link to={ urlprefix +  url } className="location-item block bg-uigrey-black" onClick={ e=>{ onClick(e) } } {...onHover}>
      <div className="bg-uigrey-fdarkhover">
        <Ratio ratio="4x3" className="ratio hidden sm:block"></Ratio>
        <Ratio ratio="16x9" className="ratio sm:hidden"></Ratio>
        <Image className="absolute top-0 object-cover w-full h-full" type="small" srcdata={ data.thumbnail } />
      </div>
      <div className={`title absolute bottom-0 w-full uppercase font-semibold flex items-center px-2 ${color}`}>
        {data.title}
      </div>
    </Link>
  )
}