import React, { Fragment, useEffect, useState } from 'react'
import { cache, fetchData, getParam } from '../utils/net'
import { userLang } from '../utils/simpleLocalize'
import { Metadata } from './Metadata'

import {Redirect, useHistory} from 'react-router-dom'
import { Scrollstory } from './story/Scrollstory'
import { parseColor } from '../utils/colors'
import { ThreeSixty } from './story/Threesixty'

import { guidedTourOptions, headerState, mapState, overlayTool, soundOptions } from '../AppAtoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ShareStory } from './story/ShareStory'

import { OverlayCompareSwiper } from './story/overlays/OverlayCompareSwiper'
import { OverlaySlideshow } from './story/overlays/OverlaySlideshow'
import { OverlayText } from './story/overlays/OverlayText'
import { Scrollmap } from './story/Scrollmap'
import { StorySpecial } from "./story/StorySpecial"
import { isDebug } from "../utils/tool"
import { StorySpecialDots, StorySpecialMap } from "./story/StorySpecialMap"

export const StoryHandler = (props) => {

  const [loadState, setLoadState] = useState(0)
  const [storyData, setStoryData] = useState()
  const [shareTitle,setShareTitle] = useState('')
  const [_OverlayTool, _setOverlayTool] = useRecoilState(overlayTool)
  const [_headerState,_setHeaderState] = useRecoilState(headerState)
  const [soundState,setSoundState] = useRecoilState(soundOptions)

  const tourOptions = useRecoilValue( guidedTourOptions )

  const history = useHistory()

  const clearStoryData = (cb)=>{
    if (storyData && storyData.story_type === 'threesixty'){
      if (props.onStoryData) props.onStoryData(null)      
      setTimeout(()=>{
        cb()
      },200)
    }else{
      cb()
    }
  }

  const gotoPrevLocation = () =>{    
    clearStoryData( ()=>{
      let s
      if (storyData.options && storyData.options.close_url){
        s = storyData.options.close_url
      }else{
        s = String(props.location.pathname).split('/')
        s.pop()
        s = s.join('/')
      }
      var refpage = getParam('', 'ref')
      var path = refpage ? refpage : s

      history.push(path)
    })
  }


  const getFirstOverlay = (data)=>{
    if (! data.screens[0].overlay){
      return <div className="error">The screen does not have any overlays - only overlay will be shown</div>
    }
    if (data.screens.length && data.screens[0].overlay.length && _OverlayTool===null){
      let overlay = data.screens[0].overlay[0]
      switch (overlay.component){
        case 'overlay_slideshow':     return <OverlaySlideshow data={ overlay.settings } color={data.color.content} onClose={ e=>gotoPrevLocation() } />
        case 'overlay_compareswipe':  return <OverlayCompareSwiper data={ overlay.settings } color={data.color.content} onClose={ e=>gotoPrevLocation() } />
        case 'overlay_text':          return <OverlayText data={ overlay.settings } color={data.color.content} onClose={ e=>gotoPrevLocation() } />
        default:
          return <div className="error">Type not found: {overlay.component}</div>
      }
    }else{
      return <div className="error">Could not find!</div>
    }    
    return <blank />
  }

  const getMouseClass = (type) => {
    switch (type){
      case 'threesixty': return 'pointer-events-none'
      case 'special': return 'pointer-events-none'
      case 'special_map': return 'pointer-events-none'
    }
    return ''
  }

  const loadStory = (slug) => {    
    fetchData('/api/story/'+ slug +'/'+  userLang, (data)=>{
      if (data && data.error){
        setLoadState(-1)        
      }else{
        if (tourOptions && tourOptions.from && tourOptions.to){
          data.screens = data.screens.filter( (el,i)=>{
            var r = i>=Number(tourOptions.from) && i <= Number(tourOptions.to)
            return r
          })
        }        
        if (props.onStoryData) props.onStoryData(data)
        setStoryData(data)
        setLoadState(3)
        _setHeaderState('hide')
      }
    })
  }

  useEffect(()=>{
    if (!storyData) return

    if (storyData.music){
      setSoundState( storyData.music.content)
    }else{
      var topicSlug = props.match.params.topic
      var topics = Object.values(cache.get('topicRef')).filter( e=>e.slug_slug === topicSlug ) 
      if (topics && topics.length > 0 && topics[0].music){
        setSoundState( topics[0].music.content )
      }
    }

  },[storyData])


  useEffect( ()=>{
    return ()=>{      
      _setHeaderState('show')
    }
  },[])

  useEffect( ()=>{
    setLoadState(1)
    let slug = props.match.params.story
    loadStory(slug)
  },[props.match.params.story])

  if (loadState === 0){
    return 'nothing'
  }

  if (loadState === 1){
    return 'loading'
  }


  if (loadState === -1){
    return <Redirect to={'/error/404'} />
    // return <div className="error h-full flex flex-col justify-center">
    //   <div className="text-xxl">404</div>
    //   <div>Error loading story: {props.match.params.story}</div>      
    // </div>
  }

  return (
    <div className={`story-handler ${ getMouseClass(storyData.story_type) } overflow-hidden top-0 bottom-0 right-0 left-0 ${ parseColor(storyData.color)}`}>
      <Metadata dataObject={storyData.meta} />      
      {storyData.story_type === 'simple_overlay' && getFirstOverlay(storyData)}
      {storyData.story_type === 'scroll_normal' && <Scrollstory data={storyData} onClose={e=>gotoPrevLocation() } onShare={e=>setShareTitle(e)}/>}
      {storyData.story_type === 'scroll_skew' && <Scrollstory options={{skewed:true}}data={storyData} onClose={e=>gotoPrevLocation() }/>}
      {storyData.story_type === 'scroll_map' && <Scrollmap data={storyData} onClose={e=>gotoPrevLocation() }/>}
      {storyData.story_type === 'threesixty' && <ThreeSixty data={storyData} onClose={e=>gotoPrevLocation() }/>}    
      {storyData.story_type === 'special' && <StorySpecial data={storyData} onClose={e=>gotoPrevLocation() }/>}
      {storyData.story_type === 'special_map' && <StorySpecialMap data={storyData} onClose={e=>gotoPrevLocation() }/>}
      {shareTitle && <ShareStory title={shareTitle}  color={parseColor(storyData.color)} onClose={e=>setShareTitle('') } />}
      {/* {isDebug() && <ImageList data={storyData}/>}       */}
    </div>
  )

}


const ImageList = ({data}) => {
  const [vis, setVisible] = useState(false)
  
  const getImage = (el,i)=>{
    let img = el.settings.image.path
    img = String(img).replace('storage/uploads/', '')
    img = String(img).replace('storage/', '')
    if (img == 'undefined' || img === '') return <Fragment />
    return <a key={i} href={img} className="block">{img}</a>
  }

  return (
    <div>
      <div className="absolute" style={{top:128,left:0}} onClick={ e=>setVisible( !vis )  }>SHOW LIST</div>   
      {vis && <div className="absolute top-0 z-50 bg-uigrey-black">

        {data.FixedBackground && data.FixedBackground.map( (sc,i)=>{
          if (sc.bg && sc.bg.component == 'background_image') return getImage(sc.bg,i)
        })}

        {data.screens.map( (sc,i)=>{
          var res = []
          if (sc.background){
            sc.background.forEach( (el)=>{
              if (el.component == 'background_image') res.push( getImage(el,i) )
            })            
          }
          return res
        })}        

      </div>}
    </div>
  )

}

