import { atom } from "recoil"

const getLocalDefault = (key,def)=>{
  var d = localStorage.getItem(key)
  return d ? JSON.parse(d) : def
}


export const mapState = atom({
  key: 'mapstate',
  default: {
    "version":0
  },
})

export const headerState = atom({
  key: 'headerstate',
  default: 'visible'
})


export const view360 = atom({
  key: 'view360',
  default:null
})

export const overlayTool = atom({
  key: 'overlayTool',
  default:null
})

export const guidedTourOptions = atom({
  key: 'guidedTourOptions',
  default:null
})


export const glossaryTopic = atom({
  key: 'glossaryTopic',
  default:''
})


export const inlineHelp = atom({
  key: 'inlineHelp',
  default: Math.random()
})

export const soundOptions = atom({
  key: 'soundoptions',
  default:''
})

export const soundVolume = atom({
  key: 'soundvolume',
  default:getLocalDefault('volume',{
    vol:100,
    break:false,
    on:true
  })
})
