import { Fragment, React, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from 'recoil'
import { isMobileLayout } from "../App"
import { inlineHelp } from "../AppAtoms"
import { cache, fetchData } from "../utils/net"
import { getStr, userLang } from "../utils/simpleLocalize"
import { IconArrowLeft, IconArrowRight, IconClose } from "./icons"

import './InlineHelp.sass'


export const InlineHelp = ({ id, className, style, mobileStyle }) => {
  const [helpUrl, sethekpUrl] = useRecoilState(inlineHelp)
  const [helpData, setHelpData] = useState([])
  const [idx, setIdx] = useState(0)


  useEffect(() => {
    var d = localStorage.getItem('inlineHelpClosed')
    if (d) {
      d = JSON.parse(d)
    } else {
      d = {}
    }
    if (!d[id]) {
      setIdx(0)
      var data = cache.get('inlinehelp', id, null)
      if (data === null) {
        fetchData('/api/inlinehelp/' + userLang + '/' + id, (data) => {
          if (!data.error) {
            cache.setKey('inlinehelp', id, data)
            setHelpData(data)
          }
        })
      } else {
        setHelpData(data)
      }
    } else {
      console.log('Closed')
    }
    console.log('change', helpUrl, id)
  }, [])


  const closeHelp = () => {
    var d = localStorage.getItem('inlineHelpClosed')
    if (d) {
      d = JSON.parse(d)
    } else {
      d = {}
    }
    console.log(id)
    d[id] = 1
    localStorage.setItem('inlineHelpClosed', JSON.stringify(d))
    setHelpData([])
  }

  const updateIndex = (dir) => {
    var newIdx = idx + dir
    setIdx(newIdx)
  }


  useEffect(() => {
    if (helpUrl === '') return
    console.log('INLINE HELP', id, helpUrl)
  }, [helpUrl])

  return <Fragment/>

  // if (!helpData || helpData.length === 0 || !helpData[idx]) return <Fragment />

  // return <div className={`inline-help pointer-event-all ${className || ''}`} style={ isMobileLayout() ? mobileStyle : style }>
  //   <div className="wrap">
  //     <div onClick={e => idx < helpData.length - 1 && updateIndex(1)} className="p-3 flex-1" dangerouslySetInnerHTML={{ __html: helpData[idx].content }}></div>
  //     <div className="flex flex-col h-full">
  //       <button aria-label={getStr('ui.close')} className="" onClick={e => closeHelp()}><IconClose /></button>
  //       {helpData.length > 1 && <Fragment>
  //         <button aria-label={getStr('ui.next')} className={`${idx == helpData.length - 1 ? 'hidden' : 'block'}`} onClick={e => updateIndex(1)}><IconArrowRight /></button>
  //         <button aria-label={getStr('ui.prev')} className={`${idx == 0 ? 'hidden' : 'block'}`} onClick={e => updateIndex(-1)}><IconArrowLeft /></button>
  //       </Fragment>}
  //       <div className="flex-1"></div>
  //     </div>
  //   </div>
  // </div>
}