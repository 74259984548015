import React, { Fragment, useEffect, useRef, useState } from 'react'
import { getStr } from '../../utils/simpleLocalize'
import { IconArrowLeft, IconArrowRight, IconClose, IconMap, IconSlideshow, IconText, IconVideo, IconZoomMedia } from '../icons'
import { ContentAudio, ContentImage, ContentRelated, ContentStoryTitle, ContentText, ContentTitleBox } from './contents'
import anime from 'animejs/lib/anime.es'
import { useRecoilState } from 'recoil'
import { overlayTool } from '../../AppAtoms'
import { parseColor } from "../../utils/colors"
import './Scrollmap.sass'
import { serverUrl } from '../../utils/net'
import { OnResize } from '../../App'
import { Copyright } from "../Copyright"
import { ContentHeader } from "../ContentHeader"

export const Scrollmap = ({ data, onClose }) => {
  const [idx, setIdx] = useState(0)
  const slider = useRef()
  const back_0 = useRef()
  const back_1 = useRef()
  
  return (
    <div className={`scrollmap w-full h-full anim-slide-left`}>
      <ContentHeader title={data.title} onClick={ e=>onClose() } color={parseColor(data.color) }/>

      <div className="area w-full h-full">
        <div ref={back_0} className="backcover" style={{ backgroundImage: `url(${serverUrl}/images/pinnwall_background.jpg)` }} />
        <div ref={back_1} className="backcover" style={{ backgroundImage: `url(${serverUrl}/images/pinnwall_background_dust.png)` }} />
        <div className="slider w-full h-full absolute" ref={slider}>
          <SvgLine data={data.screens} />
          {data.screens.map((el, i) => {
            return <MapScreen key={el._id} data={el} color={data.color.content} isActive={i == idx} />
          })}
        </div>
      </div>

      <div className="frontcover pointer-events-none" style={{ backgroundImage: `url(${serverUrl}/images/pinnwall_overlay.png)` }} />

      <footer className="overlay-footer absolute bottom-0 w-full z-10">
        <div className={`absolute bottom-0 right-0 flex w-full`}>
          <div className="dot-holder flex-1 flex items-center pl-2 md:pl-6 pr-2 flex-wrap"></div>
          <MapNavigation data={data} slider={slider} back={[back_0, back_1]} onNewIndex={(idx) => setIdx(idx)} />
        </div>
      </footer>


    </div>
  )

}

const SvgLine = ({ data }) => {

  const [pathData, setPathData] = useState('')
  const [size, setSize] = useState({x:0,y:0})
  const winSize = OnResize()

  useEffect( ()=>{
    const wi = window.innerWidth
    const hi = window.innerHeight
    var maxX=0
    var maxY=0
    var pathStr = ''
    data.forEach( (scr,i)=>{
      var ofs = String(scr.title).split(' ')[0]
      var [xx,yy] = ofs.split('x')
      pathStr += (i==0) ? 'M' : 'L'
      pathStr += (xx*wi*0.5) + (wi/2)
      pathStr += ' '
      pathStr += (yy*hi*0.5) + (hi/2)
      maxX = Number(xx) > maxX ? Number(xx) : maxX
      maxY = Number(yy) > maxY ? Number(yy) : maxY
    })
    maxX++
    maxY++
    setPathData(pathStr)
    setSize({x:maxX * wi * 0.5,y:maxY * hi * 0.5})
  },[winSize])
  return (
    <svg width={size.x} height={size.y} viewBox={`0 0 ${size.x} ${size.y}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={pathData} dx="M398.687 341.008 L1206.75 1022.09 L1290.44 439.13L1983.06 609.4" stroke="#ada08e" strokeWidth="8" strokeLinejoin="round" strokeDasharray="10 10" />
    </svg>
  )
}








var tt = { xx: 0, yy: 0 }

const MapNavigation = ({ data, slider, back, onNewIndex }) => {
  const [idx, setIdx] = useState(0)
  const [anim, setAnim] = useState(null)

  useEffect(() => {
    let refid = data.screens[idx]._id
    let el = document.getElementById(refid)
    var rect = el.getBoundingClientRect()
    var p = el.parentNode.getBoundingClientRect()
    let x = rect.x - p.x
    let y = rect.y - p.y

    if (anim) anim.pause()

    setAnim(anime({
      targets: [slider.current],
      easing: 'easeInOutSine',
      left: -x,
      top: -y,
      duration: 1500,
      begin: () => {
        onNewIndex && onNewIndex(-1)
      },
      complete: () => {
        onNewIndex && onNewIndex(idx)
      }
    }))

    setAnim(anime({
      targets: [tt],
      easing: 'easeInOutSine',
      xx: -x,
      yy: -y,
      duration: 1500,
      update: () => {
        back[0].current.style.backgroundPositionX = (tt.xx * 0.5) + 'px'
        back[0].current.style.backgroundPositionY = (tt.yy * 0.5) + 'px'
        back[1].current.style.backgroundPositionX = (tt.xx * 0.3) + 'px'
        back[1].current.style.backgroundPositionY = (tt.yy * 0.3) + 'px'
      }
    }))
  }, [idx])


  const goto = (dir) => {
    var i = idx + dir
    if (i < 0) i = 0
    if (i > data.screens.length - 1) i = data.screens.length - 1
    setIdx(i)
  }

  return <Fragment>
    <button onClick={() => goto(-1)} aria-label={getStr('ui.prevSlide')} className="x1 block story-nav-prev" ><IconArrowLeft /></button>
    <button onClick={() => goto(+1)} aria-label={getStr('ui.nextSlide')} className={`block story-bg story-nav-next`} ><IconArrowRight /></button>
  </Fragment>

}


const MapScreen = ({ data, color, isActive }) => {
  const [xx, yy] = String(data.title.split(' ')[0]).split('x')
  
  const copyright = (()=>{
    var res = ''
    data.content.forEach(el=>{
      if (el.component === 'content_image' && el.settings.image.meta){
        res += el.settings.image.meta.title
      }      
    })
    return res
  })() 

  return <div className={`map-screen xpos-${xx} ypos-${yy} ${isActive ? 'active' : ''}`} id={data._id} data-slide={ data.title }>
    <div className='screen-wrapper'>
      {data.content.map((el, id) => {
        switch (el.component) {
          case 'content_text': return <ContentText {...el} key={id} />
          case 'content_scroll_title': return <ContentStoryTitle {...el} key={id} />
          case 'content_image': return <ContentImage {...el} key={id} preview={true} />
          case 'content_titlebox': return <ContentTitleBox {...el} key={id} />
          case 'content_audio': return <ContentAudio {...el} key={id} active={isActive} color={color}/>
          case 'content_related': return <ContentRelated {...el} key={id} active={isActive} />
          default: return <Fragment />
        }
      })}
      <Copyright data={copyright} type="text" />
      {data.overlay && data.overlay.map((el, id) => {
        return <OverlayButton key={id} data={el} color={color} />
      })}
    </div>
  </div>
}


const OverlayButton = ({ data, color }) => {
  const [_OverlayTool, _setOverlayTool] = useRecoilState(overlayTool)
  const getIcon = (icon) => {
    switch (icon) {
      case 'text': return <IconText />
      case 'photo': return <IconMap />
      case 'video': return <IconVideo />
      case 'slidshow': return <IconSlideshow />
      case 'zoomer': return <IconZoomMedia />
    }
  }

  const getPos = (e) => {
    let val = 0
    if (e == 'x') val = 50 + Number(data.settings.hotspot.x_offset)
    if (e == 'y') val = 50 + Number(data.settings.hotspot.y_offset)
    return val + '%'
  }

  const openOverlay = () => {
    var d = { type: '', data: data.settings, color: color }
    switch (data.component) {
      case 'overlay_doczoomer': d.type = 'doczoomer'; break
    }
    _setOverlayTool(d)
  }

  return <button aria-label={getStr('ui.openoverlay')} onClick={e => openOverlay()} className="btn-overlay small bg-petrol-50 shadow-md font-bold absolute translate-center" style={{ left: getPos('x'), top: getPos('y') }}>
    {getIcon(data.settings.hotspot.icon)}
    {data.settings.hotspot.title}
  </button>
}