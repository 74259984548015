import React from "react"

export const PageSection  = ({data,items,children}) => {

  const checkFirstImage = (children) =>{
    if (children[0].component === 'image' && String(children[0].settings.class).indexOf('section-background') !== -1){
      return 'section-image'
    }else{
      return ''
    }
  }

  const sectionHeight = (children) => {
    if (children[0].component === 'image' && String(children[0].settings.height)){
      return {height:children[0].settings.height}
    }else{
      return {}
    }
  }


  return (
    <div className={`section ${data.class} ${ checkFirstImage(items) }`} style={sectionHeight(items)} >
      <div className="container">
        {children}
      </div>
    </div>
  )
}


{/* <div key={i} className={`section ${el.settings.class} ${ checkFirstImage(el.children) }`} style={sectionHeight(el.children)}><div className="container">{ parseComponents(el.children) }</div></div> */}