import React, { useState, Fragment, useEffect } from "react"
import { Link, NavLink, useLocation, matchPath } from "react-router-dom"
import { IconLogo, IconDots, IconArea, IconMap, IconClose, IconSoundOff, IconSoundOn, IconDirections } from "../icons"
import { getStr, L, userLang } from "../../utils/simpleLocalize"
import { cache, serverUrl, videoUrl } from "../../utils/net"
import { DebugBar } from "../../utils/DebugBar"
import { routeRoot_start } from "../../App"
import { headerState, mapState, glossaryTopic } from "../../AppAtoms"
import { useRecoilValue, useRecoilState } from "recoil"
import { isDebug } from "../../utils/tool"
import { SoundHandler } from "./SoundHandler"
import { VolumeControl } from "../VolumeControl"
import { HowTo } from "./HowTo"

export const Header = () => {

  const [menuVisible, setMenuVisible] = useState(false)
  const [howtoVisible, setHowtoVisible] = useState(false)
  const location = useLocation()
  const data = cache.get('globalmenu')
  const isInStory = inStory()
  const state = useRecoilValue(headerState)
  const switchLang = String(cache.get('global_settings','switch_lang')) === '1'
  let itemCount = 0


  // toggle fullscreen
  const toggleFullscreen = () => {
    var doc = window.document
    var docEl = doc.documentElement
    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      if (requestFullScreen) {
        requestFullScreen.call(docEl)
      }
    }
    else {
      cancelFullScreen.call(doc)
    }
  }

  // closeMenu
  const closeMenu = () => {
    setMenuVisible(false)
  }

  return (
    <nav className={`header-navigation pointer-events-none flex items-center  header-${state} ${isInStory ? 'in-story' : ''}`}>

      <div className="pl-2 sm:pl-4 relative" style={{ width: 95, height: 65 }}>
        <div className="absolute z-10 shiftup pointer-events-auto logo">
          <Link to={routeRoot_start} aria-label={getStr('header.nav.start')}><IconLogo /></Link>
        </div>
      </div>

      <div className="flex-1">
        <SoundHandler />
      </div>

      <div className="sm:pr-3 shiftup pointer-events-auto">
        <NavLink className="nav-button" to="/erfurt" aria-label={getStr('header.nav.explore.erfurt.description')}>
          <IconArea />
          <div className="hidden sm:block"><L id="header.nav.explore.erfurt" raw={true} /></div>
        </NavLink>
      </div>
      <div className="sm:pr-4 shiftup pointer-events-auto">
        <NavLink className="nav-button" to="/andreasstrasse" aria-label={getStr('header.nav.explore.andreasstrasse.description')}>
          <IconMap />
          <div className="hidden sm:block"><L id="header.nav.explore.andreasstrasse" raw={true} /></div>
        </NavLink>
      </div>

      <div className="pointer-events-auto hidden sm:block">
        <VolumeControl className="" />
      </div>

      <div className="pointer-events-auto hidden sm:block">
        <button onClick={ ()=>setHowtoVisible(true) }><IconDirections/></button>
      </div>

      <div className="pointer-events-auto burger-wrap">
        <button aria-label={getStr('ui.menu')} className="nav-button burger" onClick={e => setMenuVisible(!menuVisible)}><IconDots /></button>
      </div>

      <div className={`menu-wrapper pointer-events-auto ${menuVisible ? 'show' : 'hide'}`}>
        <div className="cover" />
        {data && <div className="menu">
          <div className="menu-header flex w-100 items-center sm:pr-5">
            <div className="flex-1" />
            <VolumeControl className="sm:hidden" />
            <div className="flex items-center">
              {switchLang && userLang==='de' && <a aria-label={getStr('ui.closeMenu')} className="w-8 text-center" href="/?lang=en">EN</a>}
              {switchLang && userLang==='en' && <a aria-label={getStr('ui.closeMenu')} className="w-8 text-center" href="/?lang=de">DE</a>}
              <button aria-label={getStr('ui.help')} onClick={e => setHowtoVisible(true)}><IconDirections /></button>
              <button aria-label={getStr('ui.closeMenu')} onClick={e => closeMenu(e)}><IconClose /></button>
            </div>
          </div>
          <div className="pl-4 text-h3 font-semibold large pb-4 pr-2 pt-2 sm:pt-8">
            <MenuList className="mb-2 leading-8" data={data.prio_1} onClick={e => closeMenu(e)} doCount={() => itemCount++} />
          </div>
          <div className="normal pb-4 pl-4">
            <MenuList data={data.prio_2} onClick={e => closeMenu(e)} doCount={() => itemCount++} />
          </div>
          <div className="dimmed pl-4 pb-4">
            <MenuList data={data.prio_3} onClick={e => closeMenu(e)} doCount={() => itemCount++} />
          </div>

          <div className="pl-4">
            <ul>
              <li><a href="#" className="text-link arrow dimmed" onClick={e => toggleFullscreen()}><L id="fullscreen_on_off" /></a></li>
            </ul>
          </div>
          {isDebug() && <DebugBar />}
        </div>}
      </div>

      {howtoVisible && <HowTo id="" onClose={ ()=>setHowtoVisible(false) }/>}
    </nav>
  )
}


const MenuList = ({ data, onClick, doCount, className }) => {
  const [topic, setTopic] = useRecoilState(glossaryTopic)


  const handleAction = (e, actionString) => {
    e.preventDefault()
    var [action, options] = actionString.replace('[!', '').replace(']', '').split(':')
    switch (action) {
      case 'glossary':
        setTimeout(() => {
          setTopic('-')
        }, 400)
        onClick(e)
        break
      default:
        console.warn('Action not found:', actionString)
    }
    return false
  }

  const parseUrl = (st) => {
    st = st.replace('http://', '')
    st = st.replace('${server}', serverUrl)
    return st
  }

  if (!data) return <div>Error No MenuList Data</div>

  return (<ul>
    {data.map((nm, i) => {
      if (String(nm.about).indexOf('[!') === 0) {
        return (<li key={i}>
          <a href="#" onClick={(e) => handleAction(e, nm.about)} className={`text-link arrow ${className} level-${nm.level}`} style={{ animationDelay: (doCount() / 30) + 's' }}>{nm.title}</a>
        </li>)
      } else if (String(nm.about).indexOf('http') === 0) {
        return (<li key={i}>
          <a rel="noreferrer" target="_blank" className={`text-link arrow ${className} level-${nm.level}`} href={parseUrl(nm.about)} style={{ animationDelay: (doCount() / 30) + 's' }}>{nm.title}</a>
        </li>)
      } else {
        return (<li key={i}>
          <NavLink className={`text-link arrow level-${nm.level} ${className}`} to={nm.about} onClick={e => onClick(e)} style={{ animationDelay: (doCount() / 30) + 's' }}>{nm.title}</NavLink>
        </li>)
      }
    })}
  </ul>
  )

}


const inStory = () => {
  const [match, setMatch] = useState(false)
  const location = useLocation()

  useEffect(() => {
    var paths = [
      '/comic/:slide',
      '/umfrage/:story',
      '/survey/:story',
      '/novel/:story',
      '/story/:story',
      '/geschichte/:story',
      '/:mode/:topic/:location/:story',
    ]

    for (var a in paths){
      if ( matchPath(location.pathname, { path: paths[a] }) ){
        setMatch(true)
        return
      }
    }
    setMatch(false)

    // var m_0 = matchPath(location.pathname, { path: '/umfrage/:story' })
    // var m_0a = matchPath(location.pathname, { path: '/survey/:story' })
    // var m_1 = matchPath(location.pathname, { path: '/story/:story' })
    // var m_2 = matchPath(location.pathname, { path: '/geschichte/:story' })
    // var m = matchPath(location.pathname, { path: '/:mode/:topic/:location/:story' })
    // if (m || m_0 || m_1 || m_2) {
    //   setMatch(true)
    // } else {
    //   setMatch(false)
    // }

  }, [location])
  return match
}

