import React, { useEffect, useState } from "react"
import { serverUrl } from "../../utils/net"
import { getStr } from "../../utils/simpleLocalize"
import { ContentHeader } from "../ContentHeader"
import { IconClose } from "../icons"
import { Heatmap } from "../map/Map_Heatmap"

import './StorySpecialMap.sass'

export const StorySpecialMap = ({data,onClose}) => {

  const [text,setText] = useState('')

  useEffect(()=>{
    Heatmap.init('/test/heatmap.png' )
    if (data && data.screens && data.screens[0].content[0]){
      var t = data.screens[0].content[0].settings.text
      setText(t)
    }
    return ()=>{
      Heatmap.close()
    }
  },[data])

  return (
    <div className="story-special relative w-full h-full pointer-events-none">
      <ContentHeader title={ data.title } onClick={ ()=>onClose()}/>

      <div className="map-text-window anim-slide-left bg-red-10 pointer-events-auto">
        <div className="flex">
          <div className="flex-1"></div>
          <button className={`top-0 right-0  z-10`} aria-label={ getStr('ui.close')} onClick={ e=>onClose() }><IconClose /></button>
        </div>
        <div className="px-4 pb-4 content" dangerouslySetInnerHTML={{__html:text}}></div>
      </div>

    </div>
  )

}