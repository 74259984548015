import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchData, serverUrl } from '../utils/net'
import { getStr, L, userLang } from '../utils/simpleLocalize'
import { IconArrowLeft, IconArrowRight, IconClose, IconText } from './icons'
import { useRecoilState, useRecoilValue } from 'recoil'


import './GuidedTour.sass'
import { guidedTourOptions } from "../AppAtoms"

export const GuidedTour = ({tourname, onClose}) =>{

  const [tourData, setTourData] = useState()
  const [curIndex, setCurIndex] = useState(-1)
  const [showText, setShowText] = useState(false)
  const [_tourOptions,_setTourOptions] = useRecoilState(guidedTourOptions)
  const history = useHistory()

  const gotostep = (dir) => {
    if (_tourOptions){
      var len = (Number(_tourOptions.to) - Number(_tourOptions.from))
      if (_tourOptions.idx < len && _tourOptions.idx >= 0){
        var nd = {..._tourOptions}
        nd.idx += dir
        _setTourOptions(()=>nd )
        return        
      }
    }

    var n = curIndex + dir
    if (n < 0) n = 0
    if (n > tourData.steps.length-1) n = tourData.steps.length-1
    setShowText(false)
    setTimeout( ()=>{
      setCurIndex(n)
      setShowText(true)
    },300)
  }

  const getField = (fieldName)=>{
    if (tourData && curIndex != -1){
      return tourData.steps[curIndex].settings[fieldName]
    }else{
      return ''
    }
  }

  const closeTour = () =>{
    setTourData(null)
    setCurIndex(-1)
    history.push('/')
    onClose()
  }

  useEffect( ()=>{
    if (!tourData || curIndex < 0) return
    var values = tourData.steps[curIndex].settings

    if (values.options){
      _setTourOptions({...values.options,...{idx:0}})
    }else{
      _setTourOptions(null)
    }

    if (values.url){
      var s = values.url
      history.push(s)  
    }
  },[curIndex])


  useEffect(()=>{
    if (tourData && curIndex===-1){
      gotostep(1)
    }
  },[tourData])


  useEffect( ()=>{
    if (!tourname) {
      return
    }
    fetchData('/api/tour/' + userLang + '/' + tourname, (data) => {
      if (data) {
        setTourData(data)
      }
    })
  },[tourname])

  if (!tourname || !tourData) return <Fragment></Fragment>

  return (
    <div className="tour-panel">

      <div className={`text-panel p-4 ${showText?'show anim-fade-in':''} ${getField('text_pos')}`} >        
        <div className="" dangerouslySetInnerHTML={{__html: getField('description')}}></div>
      </div>

      {/* <div className="absolute bottom-8 text-white">{ getField('title') }</div> */}
      <div className='wrap flex items-center relative'>
        <div className="flex items-center bg-red-100 pr-4">
          <button aria-label={getStr('ui.close')} onClick={e=>closeTour()}><IconClose/></button>
          <div> <L id="ui.guidedtour.end"></L></div>
        </div>

        <div className="flex-1 pl-4">
          { getField('title') }
        </div>
        <div className="justify-center">
          {curIndex+1} | {tourData.steps.length}
        </div>
        <button onClick={e=>gotostep(-1)}><IconArrowLeft/></button>
        <button onClick={e=>gotostep(1)}><IconArrowRight/></button>
        {/* <button onClick={e=>showText()}><IconText/></button> */}
      </div>
    </div>
  )

}