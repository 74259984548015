import { MeshBasicMaterial, Mesh, Vector3, Raycaster, DoubleSide, SphereGeometry, TextureLoader, PlaneGeometry, Vector2, Spherical , Group, BoxGeometry, MathUtils } from 'three'
import { serverUrl } from '../../utils/net'
import anime from 'animejs/lib/anime.es'
import { scene, camera, setThreeSixtyCallback,renderer, mapActive } from './Map_threejs'
import { planeControl } from './threejs_planeControl'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'


let storedCameraPosition = null
var boxholder 
var p_controls
var planes = []
var selectedPOI = null
let lookAtPosition = new Vector3(0, 5, 0)
var material
var mesh


/**
 * 
 * @param {*} st 
 * @returns 
 */
const parsePath = (st)=>{
  var corsOverride = serverUrl.indexOf('localhost') !== -1 ? '__' : ''
  return corsOverride+st.replace('storage/uploads/','')
}



/**
 * 
 * @param {*} st 
 */
const stringToVector3 = (st) => {
  var [x,y,z] = String(st).split('_')
  return new Vector3(x,y,z)
}



/**
 * 
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
const orbitString = (a,b) => {
  return [a.x, a.y, a.z, b.x, b.y, b.z].join('_')
}

const orbitFloorString = (a,b) => {
  const f = (v)=>{
    return Math.round( v*1000 ) / 1000
  }
  return [f(a.x), f(a.y), f(a.z), f(b.x), f(b.y), f(b.z)].join('_')
}



/**
 * 
 * @param {*} st 
 * @returns 
 */
const stringToOrbit = (st) => {
  var [xx,yy,zz,rx,ry,rz] = st.split('_')
  return {
    pos: new Vector3(xx,yy,zz),
    rot: new Vector3(rx,ry,rz)
  }
}




/**
 * 
 * @param {*} pData 
 * @returns 
 */
const appendPlane = (pData) => {
  const geometry = new PlaneGeometry(20, 20 )
  const bg = pData.background[0] && pData.background[0].settings.image.path

  const material = new MeshBasicMaterial({    
    map:new TextureLoader().load(serverUrl + '/assets/' + parsePath(bg), (img)=>{
      var fx = img.image.height / img.image.width * plane.basescale
      //plane.scale.set(plane.basescale,fx,plane.basescale)
    })
  })
  material.side = DoubleSide
  material.transparent = true
  material.opacity = (Number(pData.options.selected) === 1) ? 1 : 0
  
  var [xx,yy,zz, rx,ry,rz, scx,scy,scz] = (pData.options.location).split('_')

  pData.selected = (Number(pData.options.selected) === 1)
  pData.loc = stringToOrbit(pData.options.cam)
  pData.anim = null
  pData.opacity = 0

  scx = isNaN(Number(scx)) ? 1 : scx
  scy = isNaN(Number(scy)) ? 1 : scy
  scz = isNaN(Number(scz)) ? 1 : scz

  const plane = new Mesh( geometry, material )
  plane.basescale = 3
  plane.scale.set(Number(scx),Number(scy),Number(scz))
  plane.position.set(Number(xx),Number(yy),Number(zz))
  plane.rotation.set(Number(rx),Number(ry),Number(rz))
  plane.data = {...pData}

  if (Number(pData.options.selected) === 1){      
    planeControl(plane,camera,p_controls,boxholder) 
    pData.opacity = 0
  }    
  scene.add(plane)
  return plane
}



/**
 * 
 */
const fadeLocation = (material, fadeTo=1, cb) => {
  if (fadeTo === 0) material.transparent = true
  anime({
    targets:[material],
    opacity: fadeTo,
    easing:'linear',
    duration:1000,
    complete:()=>{
      material.transparent = false
      cb ? cb() : null
    }
  })  
}



/**
 * 
 * @returns 
 */
const getLookatVector = () => {
  var t = boxholder.getObjectByName('box')
  var tg = new Vector3()
  t.getWorldPosition(tg)
  return tg
}



/**
 * 
 * @param {*} st 
 * @param {*} cb 
 */
export const tweenCamera360 = (st, cb) => {
  if (!st) return
  mapActive(false)

  var nw = stringToOrbit(st)
  var cc = camera.rotation.clone()

  var animPros = {
    x: lookAtPosition.x,
    y: lookAtPosition.y,
    z: lookAtPosition.z,
    rx: cc.x,
    ry: cc.y,
    rz: cc.z
  }

  p_controls.enabled = false

  anime({
    targets:animPros,
    x: nw.pos.x,
    y: nw.pos.y,
    z: nw.pos.z,
    rx: nw.rot.x,
    ry: nw.rot.y,
    rz: nw.rot.z,
    duration:2000,
    easing:'easeInOutSine',
    update:()=>{
      lookAtPosition.x = animPros.x
      lookAtPosition.y = animPros.y
      lookAtPosition.z = animPros.z
      camera.position.set( animPros.x,animPros.y, animPros.z)
      camera.rotation.set( animPros.rx,animPros.ry, animPros.rz)
    },
    complete:()=>{
      camera.lookAt(lookAtPosition)
      p_controls.update()
      p_controls.enabled = true
    }    
  })
}




/**
 * 
 * @param {*} el 
 * @param {*} newopacity 
 * @returns 
 */
const fadePOI = (el,newopacity, cb) => {
  if (!el.data) return
  if (el.data.anim) el.data.anim.pause()
  if (el.data.selected) newopacity=1
  el.data.anim = anime({
    targets: [el.material],
    easing: 'linear',
    opacity: newopacity,
    duration: 1000,
    complete: ()=>cb ? cb() : null
  })
}


/**
 * 
 */
export const closeThreeSixty = (cb) => {
  fadeLocation(material,0,()=>{
    camera.position.copy( storedCameraPosition.pos )
    camera.rotation.copy( storedCameraPosition.rot )
    scene.remove(mesh)
    mapActive(true)
    cb()
  })

  p_controls.dispose()

  planes.forEach( (e)=>{
    scene.remove(e)
    e.material.dispose()
    e.geometry.dispose()
  })

  setThreeSixtyCallback(null)

}


/**
 * 
 * @param {*} data 
 * @param {*} onTrigger 
 */
export const showThreeSixty = (data, onTrigger) => {
  var ld = new TextureLoader()
  var textureItems = {}
  var raycaster = new Raycaster()
  var screenCenter = new Vector2(0,0)

  mapActive(false)

  var geometry = new SphereGeometry(500, 40, 40)
  geometry.scale(-3, 3, 3)

  var tLoader = new TextureLoader()
  tLoader.setCrossOrigin('')

  material = new MeshBasicMaterial({map:tLoader.load(
    serverUrl + '/assets/' + parsePath(data.extra_assets.path),
    ()=>{
      fadeLocation(material,1, ()=>{
        planes.forEach( (e)=>e.dispose && e.dispose() )        
        planes = data.screens.map( (el)=>appendPlane({...el}) )        
        p_controls.update()
      })
    }
  )})

  material.transparent = true
  material.opacity = 0
  
  mesh = new Mesh(geometry, material)
  scene.add(mesh)

  // var box = new Mesh(new BoxGeometry(1, 1, 1), new MeshBasicMaterial({ color: 0xff0000 }))
  // boxholder = new Mesh()
  // box.name = 'box'
  // box.position.x = 100
  // boxholder.add(box)
  // scene.add(boxholder)

  storedCameraPosition = {
    pos:camera.position.clone(),
    rot:camera.rotation.clone()
  }

  var nw = stringToOrbit(data.options.start)

  lookAtPosition = nw.rot.clone()
  camera.position.set( nw.pos.x,nw.pos.y, nw.pos.z)
  camera.rotation.set( nw.rot.rx,nw.rot.ry, nw.rot.rz)
  //camera.lookAt( nw.rot )
  camera.fov = 50
  camera.updateProjectionMatrix()

  p_controls = new OrbitControls( camera, renderer.domElement )
  p_controls.enableZoom = false
  p_controls.enablePan = false
  p_controls.rotateSpeed = 0.5
  p_controls.update()  
  p_controls.addEventListener('change',()=>{
  })
  p_controls.addEventListener('end',()=>{
    lookAtPosition = p_controls.target.clone()
  })


  var lastSelected = null

  setThreeSixtyCallback( ()=>{    
    
    var p = planes.filter( (el,i)=>{
      var dist = camera.position.distanceTo( el.data.loc.pos )
      return dist < 0.03
    })

    p = (p.length >= 1) ? p = p[0] : null
    if (p && p != selectedPOI){
      if (selectedPOI) fadePOI(selectedPOI,0)
      fadePOI(p,1, ()=>{        
      })
      selectedPOI = p
      onTrigger({content:{...p.data.content}, options:{...p.data.options}})
    }else if (!p && selectedPOI){
      fadePOI(selectedPOI,0, ()=>{
      })
      selectedPOI = null
      onTrigger(null)
    }
  })

  

}
