import React,{useState, Fragment, useEffect} from "react"
import { DraggerStatus } from "./DraggerStatus"
import { Graph } from "./Graph"


export const Diagram = ({ idd, data, onChange, legend }) => {

  const [curValue, setCurValue] = useState('')
  const [dataRow, setDataRow] = useState()
  const [selectValue, setSelectValue] = useState(-1)


  useEffect(() => {
    if (!data) return
    var min = data.year_min
    var max = data.year_max
    var v_max = 0
    var grandTotal = 0
    var row = []
    for (var a = Number(min); a < Number(max); a++) row.push(0)
    
    if (typeof data.data == 'string' || data.data == null) {
      var d = String(data.data).split('\n')
      d.forEach((st) => {
        if (st.charAt(0) === '*'){
          var options = {}
          st.replace('*','').split(',').forEach( (opt)=>{ var [p,v]=opt.split(':'); options[p]=v  })
          data.m_total = Number(options.total)
          return
        }

        var [year, val] = st.split(':')
        if (val){
          var total = val.split(',').reduce((acc, cur) => acc + Number(cur), 0)
          row[Number(year) - min] = val.split(',').map(e => Number(e))
          v_max = Math.max(v_max, Number(total))
          grandTotal += Number(total) 
        }
      })
    } else {
      Object.keys(data.data).forEach((k) => {
        var sum = data.data[k].reduce((rd, v) => Number(rd.value) + Number(v.value))
        row[Number(k) - min] = sum
        v_max = Math.max(v_max, sum)
        grandTotal += Number(sum)
      })
    }

    setDataRow({
      max: v_max,
      row: row,
      icon: data.icon,
      total: data.m_total ? data.m_total : grandTotal,
      year_min: data.year_min,
      year_max: data.year_max
    })
  }, [data])

  const setDraggerByIdx = (idx) => {
    var bar = document.querySelector(`#bar_${idx}`)
    if (bar){
      console.log('xx')
      setSelectValue(bar.offsetLeft + (bar.clientWidth/2))
    }
  }


  if (!dataRow) return <Fragment />

  return (
    <div className="md:px-4 pb-10">
      <div className="diagram bg-uigrey-black">
        <Graph dataRow={dataRow} yearMin={data.year_min} total={ dataRow.total } onSelect={ (idx)=>setDraggerByIdx(idx) }/>
        <div className="md:px-4">
        </div>
      </div>
      <DraggerStatus value={selectValue} data={data} dataRow={dataRow} idd={idd} onChange={ (e)=>onChange(e) } legend={legend}/>
    </div>
  )
}
