import React, { Fragment } from 'react'
import { fetchData, serverUrl } from './net'

const parseUserLang = () => {
  var l = document.querySelector('body').dataset['lang']
  if (typeof l == 'undefined' || l.substring(0,2) == '{{'){
    l = 'de'
    console.log('LANG fallback',l)
  }
  console.log('LANG',l)
  // if (typeof l == 'undefined' || l.substring(0,2) == '{{') l = String(navigator.language || navigator.userLanguage).split('-')[0]
  return l
}


export const userLang =  parseUserLang()


var _language_data = null



export const loadLanguage = (cb)=>{
  fetchData('/api/lang/'+userLang, (data)=>{
    _language_data = data
    cb()
  })
}

export const getStr = ( stringid, defaultstring ) =>{
  if (_language_data){

    if (_language_data[stringid]){
      return _language_data[stringid]
    }else if (defaultstring){
      return defaultstring
    }else{
      console.warn('MissingString:',stringid)
      return `[${stringid}]`
    }
  }else{
    return String('[no-data]')
  }
}


export const L = ({id,raw=false,data=null})=>{
  const content = getStr(id,`[!${id}]`)  
  if (raw){
    return <div dangerouslySetInnerHTML={ {__html: content} }/>
  }else{  
    return <Fragment>{ content }</Fragment>
  }
}