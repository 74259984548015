import React from "react"
import { getStr } from "../utils/simpleLocalize"

export const Error = ({match}) => {
  let errorCode = match.params.code ? match.params.code : '404'

  let text = ''
  const getCodeText = (code) => {
    switch (code){
      case '404': return getStr('error.404') 
    }
  }

  return <div className="error h-full flex flex-col justify-center">
    <div className="text-h1-xxl">{errorCode}</div>
    <div className="text-h1-xl">{ getCodeText(errorCode)}</div>
  </div>

} 