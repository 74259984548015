import React,{Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { cache } from '../../utils/net'
import { IconArrowRightSmall, IconCloseSmall } from '../icons'


import './PrisonStatsWidget.sass'
import { L } from "../../utils/simpleLocalize"


export const PrisonStatsWidget = ({visible}) => {

  const [idd, setIdd] = useState(0)
  const [idx, setIdx] = useState(0)
  const [entries, setEntries] = useState([])
  const [isOpen, setIsOpen] = useState(true)

  useEffect( ()=>{
    setEntries(cache.get('prisonstatswidget'))
  },[]) 

  useEffect( ()=>{
    if (!entries) return
    clearInterval(idd)

    setIdd(setInterval( ()=>{
      var l = entries.stats && (entries.stats.length-1)
      var n = idx+1 > l ? 0 : idx+1
      setIdx(n)
    },10000))

  },[entries,idx])

  if (!visible || entries.length===0) return <Fragment/>

  let d = entries.stats[idx]

  if (!isOpen){

    return <div className={`prison-stats-widget-tag flex flex-col items-center justify-center bg-uigrey-black`} onClick={()=>setIsOpen(!isOpen)} >
      <div className="px-1"><IconArrowRightSmall/></div>
    </div>
  }


  return (
    <div className={`prison-stats-widget cursor-pointer text-left hide-on-story ${isOpen ? 'open' : 'closed'}`} >
      <div className="absolute top-0 right-1 pt-1" onClick={()=>setIsOpen(!isOpen)}><IconCloseSmall /></div>
      <Link to={'/statistik'} className='bg-uigrey-dark flex flex-col items-center'>
        <div className="flex flex-col pl-1 pb-1">
          <div className="text-h2 pr-2 values pb-1 ">{d.value.value}</div>
          <div className="text-h6 pr-1">{d.value.content}</div>
        </div>

        <div className="flex w-full bg-uigrey-black items-center">
          <div className="text-h6 uppercase pl-1 py-1"><L id="prisonstats.widget.title"/> </div>
          <div className="flex-1"></div>
          <div className="pr-1"><IconArrowRightSmall/></div>
        </div>
      </Link>
    </div>
  )
}