import { useEffect, useRef, useState } from "react"
import { userLang } from "./simpleLocalize"

export const serverUrl = (document.body.dataset.server == '{{$server_url}}') ? 'http://localhost:8100' : document.body.dataset.server
export const videoUrl = (document.body.dataset.video == '{{$video_url}}') ? 'http://vstorage.retroscope.com/erfurt/' : document.body.dataset.video

export const fetchData = (url,cb)=>{
  console.info('FETCH',serverUrl + url)
  fetch(serverUrl + url).then( (rawdata)=>{
    rawdata.json().then(jdata=>{
      cb(jdata)
    })
  })
}

export const postData = (url,data,cb)=>{
  //console.info('POST',serverUrl + url)
  fetch(serverUrl + url,{method: 'POST', body: JSON.stringify(data) }).then( (rawdata)=>{
    rawdata.json().then(jdata=>{
      cb(jdata)
    })
  })
}



export const _cache = function(){
  this.status = 0
  
  this.set = function(t,v){
    this[t] = v
  }

  this.setKey = function(t,slug,v){
    if (!this[t]){
      this[t] = {}
    }
    this[t][slug] = v
  }

  this.get = function(t,slug,def=null){
    return slug && this[t] ? this[t][slug] : (  this[t] ? this[t] : def )
  }

  this.parse = function(data){
    Object.keys(data).forEach( v=>{
      this.set(v,data[v])
    })
  }
  
}

export const cache = new _cache()

export const getParam = (f,nm) => {
  f = !f ? window.location.search : f
  let obj = new URLSearchParams(f)
  if (nm){
    return obj.get(nm)
  }else{
    return Object.fromEntries(obj)
  } 
}


// export const useGlobals = (type,slug=null) => {
//   const [data, setData] = useState({})
//   useEffect( ()=>{
//     if (!type){ return }    
//     if ( cache && cache.status == 2 ){
//       setData( cache.get(type,slug) )
//     }else if( cache.status == 0 ) {
//       cache.status = 1
//       fetchData('/api/singleton/' +  userLang, (data)=>{
//         Object.keys(data).forEach( v=> cache.set(v,data[v]) )
//         cache.set('status',2)
//         setData( cache.get(type,slug) )  
//       })
//     }


//   } )

//   return data
// }