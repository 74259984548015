export const getTopicsByMode = ( data, mode ) => {
  return data.topics.filter( (topic) => {
    return (topic.geo_category == mode)
  })
}

export const getTopicsAsRefObject = (data) => {
  let ref = {}
  data.topics.forEach( (el) => {
    ref[ el._id ] = el
  })
  return ref
}


export const getLocationsByTopicId = (data, id) => {
  let res =  data.locations.filter( (location)=>{
    return location.topic_link.indexOf(id) !== -1
  })
  return res
}


export const getAreaItems = (data) => {
  let areaTopic = getTopicsByMode(data,'area')[0]
  let items = getLocationsByTopicId(data,areaTopic._id)
  return items
}


export const getTopicBySlug = (data,slug) => {
  let slugtopics = data.topics.filter( (topic)=>{ return topic.slug_slug === slug }  )
  if (slugtopics.length === 1){
    return slugtopics[0]
  }else{
    return {_id:-1}
  }
}


export const getLocationByOrSlug = (data,term) => {
  term = term.replace('_','-')
  return data.locations.find( el=>(el._id == term || el.slug_slug == term) )
}

export const getLocationsByTopicSlug = (data,slug) => {
  return getLocationsByTopicId(data, getTopicBySlug(data,slug)._id)
}


export const getLocationsByMode = (data,mode) => {
  let topic_ids = getTopicsByMode(data, mode ).map( topic=>{ return topic._id } )
  let res = data.locations.filter( (loc)=>{
    let isInArea = topic_ids.indexOf(loc.topic_link) !== -1
    // if (loc.topic_link.length > 0){
    //   loc.topic_link.forEach( (e)=>{ if( topic_ids.indexOf(e) !== -1 ){ isInArea=true } })
    // }
   
    return isInArea
  })
  
  let q = res.sort( (a,b)=>{     
    let nA = String(a.title).toUpperCase()
    let nB = String(b.title).toUpperCase()
    if (nA < nB) return -1
    if (nA > nB) return 1
    return 0
  })
  return q
}

var _colorList = {}

export const createColorlist = (data) => {
  _colorList = {}
  data.forEach((e)=>{ _colorList[ e._id ] = e.color.content })
}

export const getColor = (id) => {
  return _colorList[id]
}