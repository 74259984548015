import React, {useCallback, useEffect, useRef, useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { IconClose, IconDragHorz, IconText } from '../../icons'
import { Image } from '../../Image'
import { OverlayWindow, TextContent } from './OverlayWindow'
import { useRecoilState } from 'recoil'
import { overlayTool } from '../../../AppAtoms'
import { ContentHeader } from "../../ContentHeader"
import './OverlayCompareSwiper.sass'
import { Copyright } from "../../Copyright"
import { getStr } from "../../../utils/simpleLocalize"


export const OverlayCompareSwiper = ({data,color, onClose}) =>{
  
  const [showText, setShowText] = useState(false)
  const [_OverlayTool, _setOverlayTool] = useRecoilState(overlayTool)
  const block = useRef()
  const sizer = useRef()
  const img1 = useRef()
  const img2 = useRef()

  const updateSize = ()=>{
    if (!img1.current || !img2.current ) return
    img2.current.style.width = img1.current.clientWidth + 'px'
    //img2.current.style.height = img1.current.clientHeight + 'px'
  }

  useEffect( ()=>{
    updateSize()
    setTimeout(()=>{
      setShowText(true)
    },400)
  },[img1.current,img2.current])

  const startDrag = useCallback(()=>{
    const mover = (e)=>{
      if (showText) return
      var tg = (e.targetTouches) ? e.targetTouches[0] : e
      if (block.current){
        let xx = tg.pageX
        if (xx < 32) xx = 32
        if (xx > window.innerWidth-32) xx = window.innerWidth-32
        block.current.style.left = xx + 'px'
        sizer.current.style.width = xx + 'px'
      }
    }
    document.addEventListener('mousemove', mover)
    document.addEventListener('touchmove', mover)

    document.addEventListener('mouseup', ()=>{
      document.removeEventListener('mousemove', mover)
    })

    document.addEventListener('touchend', ()=>{
      document.removeEventListener('touchend', mover)
    })

  },[block])

  const getFooterButtons = ()=>{
    return <div>
      <Copyright data={ copyright } type="text" />
      <button aria-label={getStr('ui.info')} className={`btn block ${showText ? 'bg-'+color+'-50' : ''}`} onClick={ e=>setShowText( !showText ) } ><IconText/></button>
    </div>
  }

  var copyright = []
  if (data.image_1.meta) copyright.push(data.image_1.meta.title)
  if (data.image_2.meta) copyright.push(data.image_2.meta.title)

  return (    
    <OverlayWindow className="compare-swiper" title={''} onClose={e => {_setOverlayTool(null); onClose() }} color={color} buttons={ getFooterButtons }>
      <div className="holder select-none h-full">
        <div ref={img1} className="pointer-events-none select-none h-full">
          <Image src={ data.image_1.path } className="w-full h-full pointer-events-none object-cover" type="raw"/>
        </div>
        <div className='absolute top-0 h-full w-6/12 border-r overflow-hidden select-none' ref={sizer}>
          <div className='innder h-full' ref={img2}>
            <Image src={ data.image_2.path } className="w-full h-full pointer-events-none object-cover" type="raw"/>
          </div>
        </div>
        <div ref={block} onTouchStart={ e=>startDrag() } onMouseDown={ e=>startDrag()} className={`absolute pointer-events-auto bottom-0 drag-block bg-${color}-50`}><IconDragHorz /></div>      
        {showText && <TextContent color={color} onClose={ e=>setShowText(false) } >{data.text}</TextContent>}
      </div>      
      <div className="absolute top-0 w-full">
        <ContentHeader title={ data.title } color={color} onClick={e => onClose()} />
      </div>
    </OverlayWindow>
    
  )

}