import React, { Fragment } from 'react'
import './DebugBar.sass'


export const DebugBar = () =>{

  const disableDebug = ()=>{
    localStorage.setItem('debugmode', '')
    window.location.reload()
  }

  if (document.body.dataset.server !== '{{$server_url}}'){
  //    return <Fragment></Fragment>
  }

  let server = window.location.host
  if (server.indexOf(':3000') != -1) server = server.replace(':3000',':8100')

  return (
    <div className="p-4">
      <div className="debugbar inline-block">
        <a target="_blank" rel="noreferrer" className="p-1 inline" href={`http://${server}/api/refresh`}>refresh ALL</a>
        <button target="_blank" rel="noreferrer" className="p-1 inline" onClick={()=>disableDebug()}>Disable Debug</button>
      </div>
    </div>
  )
}