import React, { Fragment, useEffect, useState, useCallback } from "react"
import './Glossary.sass'

import { useRecoilState, useRecoilValue } from 'recoil'
import { glossaryTopic } from "../AppAtoms"
import { fetchData } from "../utils/net"
import { getStr, L, userLang } from "../utils/simpleLocalize"
import { IconArrowLeft, IconClose } from "./icons"
import { BackgroundVideo } from "./story/backgrounds"
import { OverlayVideocontrol } from "./story/overlays/OverlayVideocontrol"
import { Ratio } from "./Ratio"
import { useHistory } from "react-router-dom"
import { Video } from "./Video"



const regex = /\[!(.*?)\]/gm

export const glossaryParse = (str) => {
  if (str.indexOf('[!') !== -1) {
    var m
    while ((m = regex.exec(str)) !== null) {
      var [hash, text] = m[1].split(':')
      str = str.replace(m[0], `<span class="glossary" data-hash="${hash}">${text}</span>`)
    }
  }
  return str
}

export const glossarySetListener = (domref, cb) => {
  if (!domref || !domref.current) return

  const wordClick = (e) => {
    cb(e.target.dataset.hash)
  }
  
  

  var nodes = domref.current.querySelectorAll('.glossary')
  Object.keys(nodes).forEach((k) => {
    let sp = nodes[k]
    sp.removeEventListener('click', wordClick)
    sp.addEventListener('click', wordClick)
  })
}


export const useGlossary = (ref)=>{
  const [glossary,setGlossary] = useRecoilState(glossaryTopic)

  useEffect(()=>{
    if (!ref) return
    setTimeout(()=>{
      glossarySetListener(ref,(hash)=>{
        setGlossary(hash)        
      })
    },200)
  },[ref])
}



export const Glossary = () => {
  const [topic, setTopic] = useRecoilState(glossaryTopic)
  const [data, setData] = useState()
  const history = useHistory()

  useEffect(() => {
    if (!topic && topic !== '-') return
    setData(null)
    fetchData('/api/glossary/' + userLang + '/' + topic, (data) => {
      setData(data)
    })    
  }, [topic])


  const closeWindow = () => {
    setTimeout(() => {
      setTopic(null)
    }, 100)
  }


  if (!topic) return <Fragment />

  //
  return (<div className="glossary-overlay">
    <div className="cover" onClick={() => closeWindow()}></div>
    <div className={`window flex flex-col ${topic==='-' ? 'list':''}`}>
      <header className="flex bg-uigrey-dark items-center">
        {topic !== '-' && <button aria-label={getStr('ui.back')} onClick={() => setTopic('-')} className="flex items-center bg-uigrey-lightblack">
          <IconArrowLeft />
          {/* <L id="ui.glossary.backtolist"></L> */}
        </button>}
        <div className="flex-1 text-h4 pl-2">
          <L id="ui.glossary.title"></L>
          <span>{data  && data.title ? ':' + data.title : ''}</span>
        </div>
        <button aria-label={getStr('ui.close')} onClick={() => closeWindow()}><IconClose /></button>
      </header>
      {topic === '-' && <GlossaryList slug={topic} onSelect={(slug) => { setTopic(slug); setData(null) }} />}
      {topic !== '-' && <GlossaryContent data={data} />}
    </div>

  </div>)

}


const GlossaryContent = ({ data }) => {
  const [videoRef, setVideoRef] = useState()
  const [trackRef, setTrackRef] = useState()

  const handleVideo = (action, opt,trc) => {
    if (action === 'ref') {
      setVideoRef(opt)
      setTrackRef(trc)
    }
  }

  useEffect(()=>{
  },[data])


  return (
    <div className="content relative flex-1">

      {data && data.content && data.content.map((el, id) => {
        switch (el.component) {
          case 'background_video':
            return (
              <div className="relative h-full ml-auto mr-auto" key={id}>
                <Ratio ratio="1x1" className="h-full w-auto w-full relative absolute" />
                <div className="absolute top-0 w-full h-full">
                  {/* <BackgroundVideo {...el} key={id} active={true} onAction={handleVideo}></BackgroundVideo> */}
                  <Video className="" loop={true} {...el.settings} active={true} onAction={ handleVideo } color=""></Video>
                  <OverlayVideocontrol videoref={videoRef} trackref={trackRef } active={true} title="" />
                  <div className="absolute top-0 p-1 text-h6">{el.settings.copyright}</div>
                </div>
              </div>)
            break
          default:
            return ''
        }
      })}
    </div>

  )
}



const GlossaryList = ({ slug, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [glossaryList, setGlossaryList] = useState([])

  useEffect(() => {
    fetchData('/api/glossary/' + userLang, (data) => {      
      var res = []
      Object.keys(data).map((key)=>res.push({key:key,value:data[key]}))
      setGlossaryList( res.sort((a,b)=>(a.value < b.value) ? -1 : 1) )
    })
  }, [])

  return (
    <div className="w-full h-full overflow-y-scroll">
      <ul className="glossary-list" style={{paddingTop:2}}>
        {glossaryList.map((el, key) => {
          return (<li key={el.key} className="w-full" style={{ paddingBottom: 2 }}><button className="w-full text-left p-2 text-h4 bg-uigrey-lightblack block" onClick={() => onSelect(el.key)}>{el.value}</button></li>)
        })}
      </ul>
    </div>
  )
}