import React, { useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import { isMobile, isMobileLayout } from "../App"
import { soundOptions } from "../AppAtoms"
import { videoUrl, serverUrl } from "../utils/net"
import { userLang } from "../utils/simpleLocalize"
import { Image } from "./Image"


export const Video = (props) => {

  const vref = useRef()
  const trackEl = useRef()
  const [src, setSrc] = useState('')
  const [poster, setPoster] = useState('')
  const [imageClass, setImageClass] = useState('')
  const [align, setAlign] = useState('')
  const [error, setError] = useState('')

  const [soundState, setSoundState] = useRecoilState(soundOptions)


  useEffect(() => {
    let curPoster = props.poster
    if (Array.isArray(curPoster) && curPoster.length == 0) {
      curPoster = null
    }

    if (curPoster) {
      setPoster(curPoster.path)
      setImageClass('show')
    } else if (props && props.video_name) {
      let genPosterUrl = serverUrl + '/frames/' + String(props.video_name.content).replace('.mp4', '.jpg').toLowerCase()
      setPoster(genPosterUrl)
    } else {
      console.warn('Error', props)
    }

  }, [])

  const getTrackUrl = (src)=>{
    return  src.replace('.mp4',`_${userLang}.vtt`)
  }

  useEffect(() => {
    if (props.active) {

      if (!props.video_name) {
        setError('NO Video specified')
        return
      }
      setSrc(videoUrl + props.video_name.content)
      if (vref) {
        try {
          var vPromis = vref.current.play()
          if (vPromis) vPromis.then( ()=>{}  ).catch(err=>{})
        } catch (e) {
          setError('Video Object could not be found')
        }
      }      
    } else if (vref.current && src != '') {
      vref.current.pause()
      setSrc('')
    }

    if (props.align){
      setAlign('object-'+props.align)
    }

    if (!props.active) {
      setImageClass('')
    }

  }, [props.active])


  useEffect(()=>{
    if (vref.current) {
      
      vref.current.addEventListener('loadedmetadata', (e) => {
      })

      vref.current.addEventListener('playing', (e) => {
        setImageClass('video-cover-fadeout')
      })

      if (props.onAction) {
        props.onAction('ref', vref.current, trackEl.current)
      }
    }
  },[src,vref.current])

  if (isMobileLayout() && props.loop === true) {
    return <img src={poster} className={`video-poster absolute top-0 w-full h-full object-cover ${imageClass}`} />
  }

  return (
    <div className="w-full h-full">
      <video ref={vref} playsInline className={`w-full h-full object-cover ${props.active ? 'block' : 'hidden'} ${align}`} autoPlay muted={true} preload="auto" loop={props.loop} crossOrigin="anonymous">
        {src && <source src={src} />}
        {/* <track src={getTrackUrl(src)} label="English" kind="subtitles" srcLang={`${userLang}`} default ref={trackEl} mode="hidden"/> */}
        { props.loop !== true && src && <track src={getTrackUrl(src)} label="English" kind="metadata" srcLang={`${userLang}`} default ref={trackEl} mode="hidden"/>}
      </video>
      <img src={poster} className={`video-poster absolute top-0 w-full h-full object-cover ${imageClass}`} />
      {error !== '' && <div className="absolute top-0">{error}</div>}
    </div>
  )


}