import React, { Fragment, useState, useEffect, useRef } from "react"
import { getStr } from "../../utils/simpleLocalize"
import { IconArrowRight } from "../icons"
import { Video } from "../Video"


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const SurveyVideo = ({ data, onNext }) => {
  return <div className="h-full flex flex-col">
    <Video active={true} loop={true} video_name={data.video} />

    <div className="flex absolute bottom-0 w-full">
      <div className="flex-1">
      </div>
      <button onClick={e => onNext(data.next_slug_slug)} aria-label={getStr('ui.nextSlide')} className={`block next bg-uigrey-default`} ><IconArrowRight /></button>
    </div>
  </div>
}