import React, { Fragment, useState, useEffect, useRef } from "react"
import { getStr } from "../../utils/simpleLocalize"
import { IconArrowRight } from "../icons"



/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const SurveyText = ({ data, onNext }) => {
  return <div className="h-full flex flex-col" data-slug={data.slug_slug}>
    <div className="flex-1"></div>
    <div className="p-4 sm:text-h3 text-bg-backdrop text-h4 font-bold anim-slide-top text-shadow-1 max-w-screen-md inner-html-content" dangerouslySetInnerHTML={{ __html: data.text }}></div>
    {/* <div className="p-4"></div> */}
    {(!data.hide_button && data.hide_button !== true) && <div className="flex">
      <div className="flex-1" />
      <button onClick={e => onNext(data.next_slug_slug)} aria-label={getStr('ui.nextSlide')} className={`block next bg-uigrey-default`} ><IconArrowRight /></button>
    </div>}

    {(data.hide_button && data.hide_button === true) && <div className="flex">
      <div className="flex-1" />
      <div className="pb-8"/>
    </div>}


  </div>
}

