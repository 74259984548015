import React, { Fragment, useState, useEffect, useRef } from "react"
import { postData } from "../../utils/net"
import { IconArrowRight } from "../icons"


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const SurveyMultiselect = ({ data, surveyId, onNext }) => {
  const submitAnswer = (opt) => {
    postData(`/api/surveys/store/${surveyId}/${data.slug_slug}`, { type: 'mc', data: opt }, (serverData) => {
      if (serverData && serverData.status === 'ok') {
        onNext(data.next_slug_slug)
      }
    })
  }

  return <div className="h-full flex flex-col">
    <div className="flex-1"></div>
    <div className="max-height-mobile">
      <div className="p-4 text-bg-backdrop text-h4 sm:text-h2 text-shadow-1 font-bold anim-slide-top anim-delay-200 opacity-0" dangerouslySetInnerHTML={{ __html: data.question }}></div>
      <div className="bg-uigrey-light flex flex-col p-4 anim-slide-top">
        {data.options.map((el, i) => {
          return <button className="bg-uigrey-default text-h4 py-2 px-2 mb-1 text-left" key={i} onClick={e => submitAnswer(i)}>{el.value.answer}</button>
        })}
      </div>
    </div>
    <div className="p-2 bg-uigrey-light"></div>
  </div>
}
