import React, { useEffect, useRef, Fragment, useState } from "react"
import { parseColor } from "../../utils/colors"
import { Copyright } from "../Copyright"
import { Image } from "../Image"
import { Video } from "../Video"
import { swiperAnim, useSwiping } from "./hooks"
import { AngleTop, DividerLeft, DividerRight, ParallaxDivider1, ParallaxDivider2, ParallaxDividerRight1, ParallaxDividerRight2, ParallaxShape1, ParallaxShape2, ParallaxShape3, ParallaxShape4, Vectors } from "./shapes"
import { AngleLeft } from "./shapes/angleLeft"


export const BackgroundImage = (props) =>{
  let divProps = {className:"h-full img-wrap"}
  let imageType = 'default'
  let align = props.settings.align || 'center'

  if (isNaN(props.fixed) ){
    divProps['data-swiper-parallax'] = '20%'
  }

  if (props.slideWidth && props.slideWidth > 1){
    imageType = 'extra-wide'
  }
  
  return (
    <div className="h-full w-full overflow-hidden absolute top-0">
      <div  className="h-full">
        <div {...divProps}>
          <Image srcdata={ props.settings.image } mobilecrop={ props.settings.mobilecrop } type={imageType} className={`w-full h-full object-cover object-${align}`}/>
        </div>
      </div>
    </div>
  )
}

export const BackgroundVideo = (props) =>{  
  return (
    <div className={`h-full w-full overflow-hidden absolute top-0 bg-${props.color}-25`}>
      <Video className="" loop={true} {...props.settings} active={props.active} onAction={ props.onAction } color={ parseColor(props.color)}></Video>
    </div>
  )
}


export const BackgroundShapeColor = (props) => {
  const shape    = props.settings.shape ? props.settings.shape.content : ''
  const color_fg = props.settings.color_fg ? props.settings.color_fg.content : ''
  const color_bg = props.settings.color_bg ? props.settings.color_bg.content : ''

  let noOverflow = false
  
  if (String(shape).indexOf('parallax') === 0) noOverflow = true
  if (String(shape).indexOf('edge-') === 0) noOverflow = true
  


  const getShape = (name) => {
    switch (name){
      case 'vectors': return  <Vectors />
      case 'angle-left': return  <AngleLeft />
      case 'angle-top': return  <AngleTop />


      case 'divider-right': return  <DividerRight />
      case 'divider-left': return  <DividerLeft />

      case 'parallax-shape-1': return <ParallaxShape1 />
      case 'parallax-shape-2': return <ParallaxShape2 />
      case 'parallax-shape-3': return <ParallaxShape3 />
      case 'parallax-shape-4': return <ParallaxShape4 />


      case 'parallax-divider-left-1': return <ParallaxDivider1 />
      case 'parallax-divider-left-2': return <ParallaxDivider2 />

      case 'parallax-divider-right-1': return <ParallaxDividerRight1 />
      case 'parallax-divider-right-2': return <ParallaxDividerRight2 />

      case 'edge-shadow': return <EdgeShadow left={true} right={true} />

      default:
        console.warn('Shape could not be found', name)
    }
  }

  return (
    <div className={`absolute top-0 ${ noOverflow ? '' : 'overflow-hidden'} h-full w-full text-${color_fg} bg-${color_bg}`} >            
      {getShape(shape)}
    </div>
  )
}

export const EdgeShadow = ({left,right}) => {
  return <Fragment />
  return (
    <div className="absolute pointer-event-none w-full h-full edge-shadow-wrap">
      {left && <div className="edge-shadow left"></div>}
      {right && <div className="edge-shadow right"></div>}
    </div>
  )
}



export const BackgroundColor = (props) => {
  const {color} = props && props.settings ? props.settings : {color:''}  
  return (
    <div className={`absolute top-0 overflow-hidden h-full w-full bg-${color ? color.content : ''}`} >      
    </div>
  )
}





/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const FixedBackground = ({bg, swref, active, color}) => {

  const bgref = useRef()
  const [isActive,setIsActive] = useState(false)

  useEffect( ()=>{
    if (!swref.current.swiper) return
    const sw = swref.current.swiper
    const setVisibility = (index, prevIndex) => {
      let isVisible = true
      if (index < prevIndex && bg.index <= prevIndex){
        isVisible = true
      }else{
        isVisible = (index >= bg.index && index <= bg.index+bg.count)
      }
      setIsActive(isVisible)
    }

    sw.on('slideChange',(_sw)=>{
      setVisibility(_sw.activeIndex, _sw.previousIndex)
    })

    var ts
    sw.on('touchStart', function (_sw,e){
      ts = e.clientX
    })

    sw.on('touchMove',(_sw,e)=>{
      var dist = ts - e.clientX
      var idx = sw.activeIndex
      var nextIndex = dist < 0 ? sw.activeIndex -1 : dist > 0 ? sw.activeIndex + 1 : sw.activeIndex
      setVisibility(nextIndex, sw.activeIndex)
    })

  },[swref.current, bg])
  
  const handleVideo = () => {
  }

  useEffect( ()=>{
  },[bg] )

  if (!bg.bg) return <Fragment />

  var el = bg.bg
  var result = <Fragment/>
  switch(bg.bg.component){
    case 'background_video':       
      result = <BackgroundVideo {...el} active={isActive} onAction={ handleVideo } color={color}/>
      break
    case 'background_image': 
      result = <BackgroundImage {...el} color={'#ff0'}/>
      break
    default:
      console.warn('Background not found in Fixed Backgrounds',bg.bg.component)
  }
  
  return <div ref={bgref} data-label={bg.title} className={`fixed-bg absolute top-0 w-full h-full ${isActive ? 'show-bg':'hide-bg'}`} >
    {result}
    {/* <div style={{top:'50%',left:'50%'}}  className="absolute top-0">{isActive ? 'ACTIVE': 'NO'}</div>  */}
  </div>
}