import { useState,useEffect } from "react"
import { matchPath, useLocation } from 'react-router'
import { RecoilState, useRecoilState } from 'recoil'
import { routeRoot_area, routeRoot_city, routeRoot_start } from "../../App"
import { headerState, mapState } from '../../AppAtoms'


// hook for location change
export const useMapLocation = () => {
  const [loc, setLoc] = useState({ mode: '', story: '', filter: null })
  const location = useLocation()
  const [mState,setMState] = useRecoilState(mapState)
  const [hState,setHState] = useRecoilState(headerState)


  const doesMatch = (curLocation, match, callback) => {
    var m = matchPath(curLocation, { path: match })
    if (m) {
      callback(m.params)
    }
  }

  // match routes
  useEffect(() => {
    doesMatch(location.pathname, "/start", (param) => {
      setLoc({
        mode: 'start',
        loc: '',
        topic: '',
        story: ''
      })
      setHState('show')
      setMState({...mState,...{mode:'start'}})
    })

    doesMatch(location.pathname, `${routeRoot_city}/:topic?/:loc?/:story?`, (param) => {
      let l = {
        mode: 'city',
        topic: param.topic || '',
        loc: param.loc || '',
        story: param.story || '',
      }
      setLoc(l)
      setMState({...mState,...l})
    })

    doesMatch(location.pathname, `${routeRoot_area}/:topic?/:loc?/:story?`, (param) => {
      let l = {
        mode: 'area',
        topic: param.topic || '',
        loc: param.loc || '',
        story: param.story || '',
      }
      setLoc(l)
      setMState({...mState,...l})
    })

    if (!matchPath(location.pathname,{path:'/:tab(start|erfurt|andreasstrasse)'})){
      setLoc({
        mode: '',
        loc: '',
        topic: '',
        story: ''
      })
      setMState({...mState,...{mode:''}})
    }

  }, [location])

  return loc
}

