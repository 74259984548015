import React from 'react'

export const PageHeadline = ({data}) => {
  switch(data.tag){
    case 'h1': return <h1 className={` ${data.class}`}>{data.text}</h1>
    case 'h2': return <h2 className={` ${data.class}`}>{data.text}</h2>
    case 'h3': return <h3 className={` ${data.class}`}>{data.text}</h3>
    case 'h4': return <h4 className={` ${data.class}`}>{data.text}</h4>
    case 'h5': return <h5 className={` ${data.class}`}>{data.text}</h5>
    case 'h6': return <h6 className={` ${data.class}`}>{data.text}</h6>
    default:
      return <h1 className={` ${data.class}`}>{data.text}</h1>
  }
}