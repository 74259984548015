import React, { Fragment, useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import { headerState } from "../../AppAtoms"
import { cache, fetchData, getParam, postData } from "../../utils/net"
import { getStr, userLang } from "../../utils/simpleLocalize"
import { isDebug } from "../../utils/tool"
import { ContentHeader } from "../ContentHeader"
import { IconArrowRight, IconClose } from "../icons"
import { Image } from "../Image"
import { SurveyEvalMc } from "./SurveyEvalMc"
import { SurveyEvalYesNo } from "./SurveyEvalYesNo"
import { SurveyMultiselect } from "./SurveyMultiselect"
import { SurveyText } from "./SurveyText"
import { SurveyVideo } from "./SurveyVideo"
import { SurveyYesNo } from "./SurveyYesNo"



/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Survey = (props) => {

  const [error, setError] = useState('')

  const [surveyData, setSurveyData] = useState()
  const [surveyBg, setSurveyBg] = useState([])
  const [surveyIndex, setSurveyIndex] = useState(null)

  const [_headerState,_setHeaderState] = useRecoilState(headerState) 

  let { id } = useParams()
  const history = useHistory()

  useEffect(()=>{
    fetchData('/api/surveys/def/'+ id +'/' + userLang, (data) => {
      if (data) {
        setSurveyData(data)
        setSurveyIndex(0)
        _setHeaderState('hide')
        if (data.steps[0].settings.image){
          setSurveyBg([ {...data.steps[0].settings.image,...{'key':0}}])
        }else{
          setSurveyBg([{fill:true}])
        }
      }
    })

  },[ id ])

  const pushBg = (idx) => {
    var bg = [...surveyBg]
    bg.push( {...surveyData.steps[ idx ].settings.image,...{'key': idx }} )
    while (bg.length > 2) bg.shift()
    setSurveyBg(bg)
  }

  const nextStep = (slug) =>{    


    if (slug){
      var foundIndex = -1
      surveyData.steps.forEach((el,i)=>{ 
        if (el.settings.slug_slug === slug){
          foundIndex=i
        } 
      })
      pushBg( foundIndex )
      setSurveyIndex( foundIndex )
    }else{      
      var t = surveyData.steps[surveyIndex].name + "("+surveyData.steps[surveyIndex].settings.slug_slug+")"
      setError('Kein Next Slug Angegeben für::'+t)
    }
    
    
    
    // else if (surveyIndex < surveyData.steps.length){
    //   pushBg( surveyIndex+1 )
    //   setSurveyIndex( (surveyIndex)=>surveyIndex+1 )
    // }
  }

  const getFieldsFromSurvey = (fieldName) => {
    var res
    surveyData.steps.map( (el)=>{
      if (el.settings.slug_slug === fieldName){
        res = {...el.settings.options}
      }
    })
    return res
  }




  const onClose = () =>{
    var refpage = getParam('', 'ref')
    var path = refpage ? refpage : '/start'
    history.push(path)
  }


  const getSurveyStep = () =>{
    var step = surveyData.steps[surveyIndex]
    switch (step.component){
      case 'survey_text': return <SurveyText data={ step.settings } onNext={ (e)=>nextStep(e) }/>
      case 'survey_video': return <SurveyVideo data={ step.settings } onNext={ (e)=>nextStep(e) }/>
      case 'survey_yesno': return <SurveyYesNo surveyId={id} data={ step.settings } onNext={ (e)=>nextStep(e) }/>
      case 'survey_mc': return <SurveyMultiselect surveyId={id} data={ step.settings } onNext={ (e)=>nextStep(e) }/>
      case 'survey_eval_yesno': return <SurveyEvalYesNo surveyId={id} data={ step.settings } onNext={ (e)=>nextStep(e) }/>
      case 'survey_eval_mc': return <SurveyEvalMc fields={ getFieldsFromSurvey( step.settings.question_slug_slug ) } surveyId={id} data={ step.settings } onNext={ (e)=>nextStep(e) }/>
      default:
        return 'not-found:'+step.component
    }
  }

  return (
    <div className="survey absolute w-full h-full pointer-events-all bg-uigrey-black">
      <div className="absolute w-full h-full survey-bg-wrapper">
        {surveyBg.map( (bg)=>{
          return <div className="survey-bg w-full h-full" key={ bg.key }>
            {bg.path && <Image className="w-full h-full object-cover anim-fade-in opacity-0 anim-delay-500" src={ bg.path }/>}
          </div>
        })}
      </div>
      <div className="absolute w-full h-full">
        { surveyIndex !== null && getSurveyStep() }
      </div>
      <div className="absolute bottom-0 z-20 h-8 flex flex-col justify-center pl-4">
        <div className="flex">
          { surveyData && surveyData.steps.map( (el,i) => {
            return <div key={i} className={`dot${ i===surveyIndex ? ' selected' : ''}`}/>
          })}
        </div>
      </div>
      <ContentHeader onClick={ e=>onClose() } title={ surveyData ? surveyData.title : ''} color="kubus"/>
      {isDebug() && <DebugList data={surveyData} onSelect={ (s)=>nextStep(s) } />}

      {error !== '' && <div className="absolute z-50 bottom-0 p-10 bg-red-100">{error}</div>}
    </div>
  )

}


const DebugList = ({data, onSelect}) => {  
  const [show,setShow] = useState(false)
  if (!data) return <Fragment/>

  const select = (s) =>{
    setShow(false)
    onSelect(s)
  }

  return <div className="absolute top-0 z-50">
    <button className="" onClick={ ()=>setShow( !show ) }>(DEBUG) Fragen Liste</button>
    {show && <ul className="bg-uigrey-black text-h6">

      { data.steps.map( (el,i)=>{
        return <li key={i}><button onClick={ ()=>select( el.settings.slug_slug ) }>{el.name}</button></li>
      })}

    </ul>}
  </div>

}





