import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { IconArrowLeft, IconArrowRight, IconClose, IconText } from '../../icons'
import { Image } from '../../Image'
import { OverlayWindow, TextContent } from './OverlayWindow'
import { useRecoilState } from 'recoil'
import { overlayTool } from '../../../AppAtoms'
import { Copyright } from "../../Copyright"
import { ContentHeader } from "../../ContentHeader"


export const OverlaySlideshow = ({ data, color, onClose }) => {

  const sw = useRef()
  const [showText, setShowText] = useState(false)
  const [idx, setIdx] = useState(0)
  const [_OverlayTool, _setOverlayTool] = useRecoilState(overlayTool)

  const swiperConfig = {
    slidesPerView: 1,
    modules: [Navigation],
    observeParents: true,
    observer: true,
    loop: true
  }

  useEffect( ()=>{
    var swobj = sw.current.swiper
    swobj.on('slideChange',()=>{
      setIdx(swobj.activeIndex-1)
    })
    setTimeout(()=>{
      setShowText(true)
    },400)    
  },[sw.current])

  const getFooterButtons = () => {
    return <div className="flex">
      <button className={`btn block ${showText ? 'bg-' + color + '-50' : ''}`} onClick={e => setShowText(!showText)} ><IconText /></button>
      <button className={`block bg-${color}-50`} onClick={ e=>sw.current.swiper.slidePrev() }><IconArrowLeft/></button>
      <button className={`block bg-${color}-50`} onClick={ e=>sw.current.swiper.slideNext() }><IconArrowRight/></button>
    </div>
  }

  const getTitle = () => {
    var _idx = (idx % data.gallery.length)
    _idx = _idx < 0 ? _idx + data.gallery.length : _idx    
    return `${ _idx +1} | ${data.gallery.length}  `    
  }

  return (
    <OverlayWindow className="compare-swiper" title={data.title} color={color} buttons={getFooterButtons}>
      {swiperConfig && <Swiper ref={sw} className="h-full" {...swiperConfig} >
        {data.gallery.map((e, i) => {
          return <SwiperSlide key={i}>
            <Image srcdata={e} className="w-full h-full object-cover" preview={true} />
            <div>
              <Copyright data={ e.meta ? e.meta.title : '' } type="text" />
            </div>
          </SwiperSlide>
        })}
      </Swiper>}
      <div className="absolute top-0 z-10 w-full">
        <ContentHeader title={getTitle()} color={color} onClick={e => { _setOverlayTool(null); onClose() }}/>
      </div>
      {showText && <TextContent className="z-10" color={color} onClose={e => setShowText(false)} >{data.text}</TextContent>}
    </OverlayWindow>
  )

}