import React from "react"

export const PageSpacer = ({data}) => {  
  var prop = {}
  var prop_mobile = {}
  var propName = (data.use_margin === true) ? 'margin':'height'

  prop[propName] = data.space
  prop_mobile[propName] = data.space_mobile
  return (
    <div className={`page-spacer ${data.class}`}>
      <div className="hidden sm:block" style={prop}></div>
      <div className=" sm:hidden" style={prop_mobile}></div>
    </div>
  )
}