import React, { Fragment, useEffect, useRef, useState, useCallback, Component } from 'react'
import anime from 'animejs/lib/anime.es'
import { getLocationByOrSlug, getLocationsById, getLocationsByMode, getLocationsByTopicId, getTopicBySlug, getTopicsAsRefObject } from './datafilter'
import { loadModels, setActive, setupMap, slideToByString, appendMarker, clearAllMarkers, setMapMarkers, threejsWrapper, setCameraShift, setSelectedMarker, controls } from './Map_threejs'
import { closeThreeSixty, showThreeSixty, tweenCamera360 } from './Map_360'
import { cache, serverUrl } from '../../utils/net'
import { Link } from 'react-router-dom'
import { routeRoot_area, routeRoot_city } from '../../App'
import { parseColor } from '../../utils/colors'
import { isDebug } from "../../utils/tool"

import { RecoilState, useRecoilState } from 'recoil'
import { view360 } from '../../AppAtoms'


const getTime = () => {
  let d = new Date()
  return d.getTime()
}

var posCounter = 0


export class MapComponent extends Component {

  selectedStory = null

  locationTag = ''

  constructor(props) {
    super(props)
    this.threeWrapper = null
    this.state = {
    }
    this.debugref = React.createRef()
    this.mapCanvas = React.createRef()
    this.mapLabels = React.createRef()
    this.mapConfig = cache.get('map_config')
  }

  componentDidMount() {
    setupMap(this.mapCanvas.current, this.mapLabels.current, this.debugref, (el) => {
      this.props.onMarkerClick(el)
    })

    //loadModels( ['models/roads_new.glb','models/buildings_finale.glb'] )
    let modelsToLoad = [
      { name: 'roads', url: '/models/erfurtarea_roads.min.glb' },
      { name: 'area', url: '/models/erfurtarea_andreasstrasse.min.glb' },
    ]

    if (this.props.isMobile === 1) {
      modelsToLoad.push({ name: 'city', url: '/models/erfurtarea_city_inner.min.glb' })
      modelsToLoad.push({ name: 'subs', url: '/models/erfurtarea_outer.min.glb' })
    } else {
      modelsToLoad.push({ name: 'city', url: '/models/erfurtarea_city_inner.min.glb' })
    }

    //this.handleStoryData( this.props.storyData )
    loadModels(modelsToLoad)
    setActive(true)
    this.updateCamera()
    this.insertMarkers()
  }


  addExtraData(topicsRef, el) {
    if (el.topic_link) {
      el.location = location
      el.topic_slug = topicsRef[el.topic_link].slug_slug
      el.topic_color = parseColor(topicsRef[el.topic_link].color)
      el.topic_title = topicsRef[el.topic_link].title
      if (el.geo_location.geo === null) {
        el.geo_location.geo = '-33,0,' + (-370 - (posCounter++ * 50))
      }
    }
    return el
  }


  insertMarkers() {
    let topicsRef = cache.get('topicRef')
    setMapMarkers([...this.props.filterData, ...this.props.defaultLocations].map(el => this.addExtraData(topicsRef, el)), location)
  }


  /**
   * 
   * @param {*} pProps 
   */
  componentDidUpdate(pProps) {

    if (this.props.location && this.props.location.mode === '') return

    if (this.props.filterData && pProps.filterData !== this.props.filterData) {
      let topicsRef = cache.get('topicRef')
      setMapMarkers([...this.props.filterData, ...this.props.defaultLocations].map(el => this.addExtraData(topicsRef, el)), location)
    }

    if (this.props.filterData !== pProps.filterData || this.props.location !== pProps.location || this.props.topic !== pProps.topic) {
      this.updateCamera()
    }

    if (this.props.currentLocation !== pProps.currentLocation) {
      setSelectedMarker(this.props.currentLocation)
      setCameraShift(this.props.currentLocation != null)
    }
  }


  /**
 * 
 */
  updateCamera() {
    if (!controls.enabled) return
    var curLocationTag = Object.values(this.props.location).join('_')
    if (this.locationTag === curLocationTag) return

    let newCam = ''
    let newMode = ''

    if (this.props.location.story) {
      return
    } else if (this.props.location.loc) {
      let loc = getLocationByOrSlug({ locations: this.props.filterData }, this.props.location.loc)
      if (!loc) return
      newCam = loc.geo_location.camera
      newMode = this.props.location.mode
    } else if (this.props.location.topic) {
      if (!this.props.topic) return
      newCam = this.props.topic.camera
      newMode = this.props.location.mode
    } else if (this.props.location.mode) {
      newCam = this.props.location.mode === 'city' ? this.mapConfig.city_geo_coords : this.mapConfig.area_geo_coords
      newMode = this.props.location.mode
      if (newMode === 'start') return
    }


    this.slideTo(newCam, newMode)
    this.locationTag = curLocationTag
  }

  /**
   * 
   * @param {*} camera 
   * @param {*} mode 
   * @param {*} cb 
   */
  slideTo(camera, mode, cb) {
    if (!camera || !mode) return
    setTimeout(() => {
      slideToByString(camera, mode, cb)
    }, 100)
  }


  /**
   * 
   * @returns 
   */
  render() {
    return (
      <div>
        <div ref={this.mapCanvas}></div>
        <div className={`hide-on-story marker-wrap ${this.props.location.mode}`} ref={this.mapLabels}></div>
        {/* <ViewHandler360 onViewChange={ ()=>{  } }  onLoad={ ()=>{} }/> */}
        {isDebug() && <div className="map-debug-tag" ref={this.debugref}>Empty</div>}
      </div>
    )
  }

}

