import React, { useEffect, useRef } from "react"
import { glossaryParse, useGlossary } from "../../Glossary"
import { IconClose } from "../../icons"
import './OverlayWindow.sass'



export const OverlayWindow = ({ title, children, color, className, buttons }) => {
  return (
    <div className={`OverlayWindow flex flex-col ${className}`}>
      <div className="flex-1 overflow-hidden relative">
        {children}
      </div>
      <footer className={`flex items-center bg-${color}-25`}>
        <div className="flex-1 pl-4">{title}</div>
        {buttons && buttons()}
      </footer>
    </div>)
}


export const TextContent = ({ color, children, onClose, className }) => {
  const txtRef = useRef()
  const g = useGlossary(txtRef)
  return <div className={`text-content absolute Overlay-slide-up bottom-0 right-0 bg-${color}-50 md:w-5/12 ${className}`}>
    <div className="flex flex-row">
      <div className="flex-1"></div>
      <button onClick={e => onClose()}><IconClose /></button>
    </div>
    <div ref={txtRef} className="inner px-4 pb-4 text-h4" dangerouslySetInnerHTML={{ __html: glossaryParse(children) }}></div>
  </div>
}