import React, { Fragment, useState, useEffect, useRef } from 'react'
import { cache, fetchData } from '../../utils/net'
import { getStr, L, userLang } from '../../utils/simpleLocalize'
import { Dropdown } from './Dropdown'
import { Diagram } from './Diagram'
import './PrisonStats.sass'
import { IconArrowRight } from "../icons"
import { BuildSummaryDiagramData, ParseSummaryData, buildSummaryItems } from "./PrisonStatsParser"



export const PrisonStats = ({ visible }) => {
  const [listData, setListData] = useState()
  const [id, setId] = useState('')

  //fetch list
  useEffect(() => {
    if (!visible) return
    fetchData('/api/prisonstats/list/' + userLang, (data) => {
      setListData(data)
    })
  }, [visible])

  if (!visible || !listData) return <Fragment />

  return (
    <div className="prison-stats bg-uigrey-dark pt-4 pb-10">
      <div className='header-cover fixed top-0 w-full z-10' />
      <div className="flex flex-col inner">
        <div className="pt-12 text-h3 md:text-h2 px-4">
          <L id="prisonstats.title" />
          <div className="text-h4 py-2"><L id="prisonstats.description" /></div>
        </div>        
        <div className="pt-2 px-4 pb-2">
          <Dropdown data={listData} id={id} onSelect={id => setId(id)} />
        </div>
        {id === '' && <PrisonStatsSummary onSelect={ v=>setId(v) } listData={listData} />}
        {id !== '' && <PrisonStatsDetails id={id} />}
      </div>
    </div>
  )

}


const PrisonStatsSummaryItem = ({data,total,onSelect,showInfo}) => {
  return (
    <div className="flex flex-col pb-4">
      <div className="flex pb-1">
        <div className="flex-1 text-h4">
          {data.id && <button onClick={ ()=>onSelect( data.id ) }>{data.title}</button>}
          {!data.id && showInfo!==false &&  <div className="text-h4">{data.title}</div>}
        </div>              
        <div>{data.value}</div>
      </div>
      <div className="summary-bar">
        <div className="summary-block" style={{ width: Math.round(Number(data.value) / total * 100) + '%' }}></div>
      </div>
    </div>
  )
}


const PrisonStatsSummary = ({ id, onSelect, listData  }) => {

  const [yearMax, setYearMax] = useState(0)
  const [summaryData, setSummaryData] = useState()
  const [summaryFiltered, setSummaryFiltered] = useState()
  const [summaryDiagram, setSummaryDiagram] = useState()

  const handleSummaryChange = (year) => {
    var f = buildSummaryItems(summaryData.options,listData,summaryData.data_json.inprison[year])
    f.total = summaryDiagram.maxValue
    setSummaryFiltered(f)
  }

  //fetch Summary and update state
  const getSummary = () => {
    fetchData('/api/prisonstats/summary/' + userLang, (data) => {
      setSummaryData( ParseSummaryData(data) )
      //buildDiagramData(data)
    })
  }

  useEffect(()=>{
    if (!summaryData) return
    setSummaryDiagram( BuildSummaryDiagramData(summaryData) )
  },[summaryData] )

  useEffect(() => {
    if (!id) getSummary()    
  }, [id])

  return (
    <div>
      <div className="stat-item px-0 pb-4 flex flex-col">
        {summaryDiagram && <Diagram idd={-1} data={summaryDiagram} onChange={(d) => handleSummaryChange(d)} legend={true}/>}
        {summaryFiltered && <div className="px-4">
          <div className="text-h3 pb-2"><L id="prisonstats.summary.selected"/></div>
          {summaryFiltered.listed.map((val)=><PrisonStatsSummaryItem key={val.idd} data={val} total={ summaryFiltered.total } onSelect={ (v)=>onSelect(v) }/> )}
          {summaryFiltered.listed.length === 0 && <div className="text-h4"><L id="prisonstats.summary.nonelisted"/></div>}
          <div className="text-h3 pt-8 pb-2"><L id="prisonstats.summary.remaining"/></div>
          <PrisonStatsSummaryItem showInfo={false} data={ summaryFiltered.remaining } total={ summaryFiltered.total } />
        </div>} 
      </div>
    </div>
  )
}




const PrisonStatsDetails = ({ id }) => {

  const [diagramData, setDiagramData] = useState()
  // Get stats
  const getStatsData = (id) => {
    if (!id) {
      getSummary(null)
      return
    }
    fetchData('/api/prisonstats/data/' + id + '/' + userLang, (data) => {
      setDiagramData({
        id: new Date().getTime(),
        type: 'item',
        data: data,
      })
    })
  }

  useEffect(() => {
    getStatsData(id)
  }, [id])

  if (!diagramData) return <Fragment />

  return (
    <div>
      <Diagram idd={diagramData.id} type={diagramData.type} data={diagramData.data} onChange={(d) => { }} />
      {diagramData && <div className="stat-item px-4 pb-4">
        <div className="container-md">
          <div className="text-h3 md:text-h4 pb-2 text-green-40">{diagramData.data.paragraph}</div>
          <div className="text-h3 ">{diagramData.data.subtitle}</div>
        </div>
        <div>
          <div className="pt-4 text-h4" dangerouslySetInnerHTML={{ __html: diagramData.data.text }}></div>
        </div>
      </div>}
    </div>
  )
}