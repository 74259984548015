import React, { Fragment, useState, useEffect, useRef } from "react"
import { IconArrowRight } from "../icons"
import { fetchData, postData } from "../../utils/net"
import { getStr } from "../../utils/simpleLocalize"




export const SurveyEvalMc = ({data,surveyId,fields,onNext}) => {
  const [resultData, setResultData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(()=>{
    fetchData(`/api/surveys/store/${surveyId}/${data.question_slug_slug}`, (data) => {
      if (data) {
        if (data.status == 'error'){
          setError(data.message)
          return
        }
        var res = []
        var total = 0
        Object.keys( data ).map( (v)=>v.indexOf('opt_') === 0 ? total+=data[v] : 0 )

        res = Object.keys( fields ).map( (key,i)=>{
          return {
            title: fields[key].value.answer, 
            value: data['opt_' + i] ? Math.round( Number(data['opt_' + i]) / total * 100) : 0
          }
        })
        setResultData( res )
      }
    })
  },[])

  return <div className="h-full flex flex-col" data-slug={data.slug_slug}> 
    <div className="h-16"></div>
    <div className="px-4 pb-2 text-h4 sm:text-h2 text-shadow-1 font-bold z-10 text-bg-backdrop pt-2" dangerouslySetInnerHTML={{__html:data.text}}></div>

    <div className="flex-1 flex flex-col justify-end">
      {resultData && resultData.map( (el,i)=>{ 
        var ii =  10 / resultData.length      
        return <div key={i} className={`flex flex-row items-center pl-3 pr-3 sm:pr-18 w-full anim-slide-right opacity-0 anim-delay-${i}00 bar-color-${ Math.round(ii * i)}`} style={{minHeight: 46, height:el.value+'%' }}>
          <div className={`text-h4 sm:text-h3 font-bold`}>{el.value+'%'}</div>
          <div className="text-right flex-1 text-h5 sm:text-h3">{el.title}</div>
        </div>
      })}
    </div>

    {error && <div className="absolute top-0 bg-uigrey-black text-h3 text-red-100 mt-10">{error}</div>}

    <div className="flex  z-10 bar-color-10 pt-8">
      <div className="flex-1"/>
      <button onClick={e=>onNext(data.next_slug_slug)} aria-label={ getStr('ui.nextSlide')} className={`block next bg-uigrey-default`} ><IconArrowRight/></button>
    </div>


  </div>

}
