import React, { useEffect, useState } from "react"
import { L, getStr } from "../../utils/simpleLocalize"
import { DraggerBlock } from "./DraggerBlock"

export const DraggerStatus = ({ value, data, idd, dataRow, onChange,legend }) => {

  const [curYear, setCurYear] = useState('')

  const txtMen = getStr('prisonstats.men')
  const txtWoman = getStr('prisonstats.women')

  const handleDragger = (val) => {
    // if (type === 'summary') {
    //   onChange({
    //     year: val,
    //     data: data.data[val]
    //   })
    // }

    var d = document.querySelectorAll('.graph-wrapper .bars .bar')
    Object.keys(d).map((k) => {
      if (d[k].getAttribute('idx') == val) {
        d[k].classList.add('hover')
      } else {
        d[k].classList.remove('hover')

      }
    })

    let idx = Number(val) - data.year_min
    let dataValue = dataRow ? dataRow.row[idx] : []
    if (!Array.isArray(dataValue)) dataValue = []

    if (val != curYear.year) onChange(val)

    setCurYear({
      year: val,
      sum: dataValue
    })
  }


  useEffect(()=>{
    console.log('dragger_status',value)
  },[value])

  return (
    <div>
      <div className="relative bg-uigrey-black">
        <DraggerBlock value={value} idd={idd} onChange={(val) => handleDragger(val)} min={Number(data.year_min)} max={Number(data.year_max)} />
      </div>
      <div className="py-4 px-3 flex text-h4 bg-uigrey-lightblack">
        <div className="flex-1">
          <L id="prisonstats.summary.convictions" />{curYear.year}
        </div>
        {curYear.sum && <div className="text-right">
          {curYear.sum.reduce((acc, cur) => acc + cur, 0)}
          {legend !== false && <div className="text-h5 flex">
            <div className="pr-2"><div className="inline-block mini-block bg-white">&nbsp;</div>{curYear.sum[0]} {txtMen}</div>
            <div><div className="inline-block mini-block bg-white opacity-50">&nbsp;</div>{curYear.sum[1]} {txtWoman}</div>
          </div>}
        </div>}
      </div>
    </div>
  )

}