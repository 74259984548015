import React, { useEffect, useRef, useState } from "react"
import { isMobile } from "../../App"
import { L } from "../../utils/simpleLocalize"


export const DraggerBlock = ({ value,idd, onChange, min, max }) => {
  const parRef = useRef()
  const [px, setPx] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const is_mobile = isMobile()

  const startDrag = () => {

    const cleanup = () => {
      document.removeEventListener(is_mobile ? 'touchend' : 'mouseup', cleanup)
      document.removeEventListener(is_mobile ? 'touchmove' : 'mousemove', dragging)
      setIsDragging(false)
    }

    const dragging = (e) => {
      if (!parRef.current) return
      var parRect = parRef.current.getBoundingClientRect()
      var pageX = is_mobile ? e.targetTouches[0].pageX : e.pageX
      var xx = pageX - parRect.left - 32.5
      if (xx < 0) xx = 0
      if (xx > parRect.width - 65 - 32.5) xx = parRect.width - 65 - 32.5
      setPx(xx)
      if (is_mobile) return false
      if (e.stopPropagation) e.stopPropagation()
      if (e.preventDefault) e.preventDefault()
      e.cancelBubble = true
      e.returnValue = false      
      return false
    }

    document.addEventListener(is_mobile ? 'touchmove' : 'mousemove', dragging)
    document.addEventListener(is_mobile ? 'touchend' : 'mouseup', cleanup)
    setIsDragging(true)
  }

  const triggerOnChange = () => {
    var parRect = parRef.current.getBoundingClientRect()
    var fx = px / (parRect.width - 65 - 32.5)
    var w = Math.round(min + ((max - min) * fx))
    onChange(w)
  }

  useEffect(()=>{
    console.log('VALUE CHANGED::',value)
    if (value !== 1){
      setPx(value)
    }
  },[value])

  useEffect(() => {
    setPx(0)
    triggerOnChange()
  }, [idd])

  useEffect(() => {
    triggerOnChange()
    if (!isDragging) {
      triggerOnChange()
    }
  }, [px, isDragging])

  return (
    <div ref={parRef} className="draggeBlockWrapper">
      <svg style={{ left: px }} className="absolute" width="65" height="74" viewBox="0 0 65 74" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseDown={() => startDrag()} onTouchStart={() => startDrag()} >
        <path fillRule="evenodd" clipRule="evenodd" d="M23.2989 9.58203L32.6101 0.270752L41.9214 9.58203H64.6101V73.582H0.610107V9.58203H23.2989Z" fill="#98B3B1" />
        <rect x="24.6169" y="31.728" width="1.75719" height="19.7081" fill="#FAFAFA" />
        <rect x="31.4055" y="31.728" width="1.75719" height="19.7081" fill="#FAFAFA" />
        <rect x="38.8462" y="31.728" width="1.75719" height="19.7081" fill="#FAFAFA" />
      </svg>
    </div>
  )
}