import React from "react"
import { getStr } from "../utils/simpleLocalize"
import { IconClose } from "./icons"

export const ContentHeader = ({title,onClick,color,className})=>{

  return (
    <header className={`z-30 absolute flex items-center w-full flex pointer-events-none ${className ? className : ''}`}>
      <div className="uppercase text-h4 pl-2 text-shadow-1 pr-6">{title}</div>
      <div className="flex-1"></div>
      <button aria-label={getStr('ui.closeStory')} className={`block pointer-events-auto bg-${color}-10`} onClick={ e=>onClick() }><IconClose/></button>
    </header> 
  )
} 