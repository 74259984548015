import React, { useRef, useEffect, useState, Fragment } from "react"
import { Image } from "../Image"
import { swiperAnim } from "./hooks"
import { Howl, Howler } from 'howler'
import { cache, videoUrl } from "../../utils/net"
import { IconArrowRight, IconPause, IconPlay } from "../icons"
import { getStr, userLang } from "../../utils/simpleLocalize"
import { isMobile, isMobileLayout } from "../../App"
import { glossaryParse, glossarySetListener, useGlossary } from "../Glossary"

import { useRecoilState } from 'recoil'
import { glossaryTopic } from "../../AppAtoms"
import { vttHandler } from "../../utils/vtt"


const Htmltext = ({ text }) => {
  return <span dangerouslySetInnerHTML={{ __html: text }}></span>
}

export const ContentText = (props) => {
  return (
    <div className="w-full h-full flex flex-col justify-center pl-2 sm:pl-5 scrollbar-right-padding">
      <div className="text-body-wrapper">
        <div className="text-body text-xl" dangerouslySetInnerHTML={{ __html: props.settings.text }}></div>
      </div>
    </div>
  )
}

export const ContentStoryTitle = (props) => {

  return (
    <div className={`w-full h-full flex flex-col justify-center pl-2 sm:pl-5 ${props.settings.class}`}>
      <div data-swiper-parallax={'-40%'} className="story-title break-all " dangerouslySetInnerHTML={{ __html: props.settings.headline }}></div>
      {props.settings.subtext && <div className="story-subtext pt-4">{props.settings.subtext}</div>}
    </div>
  )
}


export const ContentImage = (props) => {
  const st = {
    height: props.settings.size + '%'
  }

  if (!isMobileLayout() && props.settings.size_desktop) {
    st.height = props.settings.size_desktop + '%'
  }

  var [xx, yy] = String(isMobileLayout() ? props.settings.mobile_position : props.settings.desktop_position).split('x')

  const st_1 = {
    left: xx + '%',
    top: yy + '%',
    objectPosition: props.settings.align || 'center' 
  }

  return (
    <div className={`content-image absolute ${props.settings.class}`} data-swiper-parallax={props.settings.parallax} style={st}>
      <Image srcdata={props.settings.image} type="raw" className={`${props.settings.class} wi-auto relative w-full`} style={st_1} preview={props.preview} />
    </div>
  )
}


export const ContentTitleBox = (props) => {
  const [txt,setTxt] = useState('')
  const ref = useRef()
  const g = useGlossary(ref)

  useEffect(()=>{
    setTxt(glossaryParse(props.settings.title))
  },[])

  return (
    <div className={`content-title-box ${props.settings.position} ${props.settings.size} ${props.settings.class} ${'backdrop'} ${props.settings.class}`}>
      <div ref={ref} data-swiper-parallax={'-20%'} className="text-wrap"><span dangerouslySetInnerHTML={{ __html: txt }}></span></div>
    </div>

  )
}

const RelatedBlock = ({ title, link, img, color }) => {

  const [_link,_setLink] = useState()

  console.log('REALTED BLOCK',link)


  useEffect(()=>{
    if (!link) return
    var d = cache.get('topicData')
    var loc = d.locations.filter( (el)=>el._id==link._id )
    
    if (!link._id){
      _setLink(link)
      return
    }


    if (loc.length > 0){
      var topic = d.topics.filter( (el)=>el._id==loc[0].topic_link )
      var linkArr = []
      linkArr.push( topic[0].geo_category == 'city' ? 'erfurt' : 'andreasstrasse' )
      linkArr.push( topic[0].slug_slug )
      linkArr.push( loc[0].slug_slug )
      _setLink('/'+linkArr.join('/') )        
    }
  },[link])

  return <div data-swiper-parallax={'-150%'} className="flex-1">
    <a href={_link} className="h-full flex flex-col shadow-md hover-scale" >
      <div className="relative flex-1">
        {/* <img className="w-full" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEElEQVR42mNkIAAYRxWAAQAG9gAKqv6+AwAAAABJRU5ErkJggg=="/> */}
        <Image src={img} type="small" className="object-cover absolute w-full h-full object-cover" />
      </div>
      <div className={`flex items-center title bg-${color}-25`}>
        <div className="flex-1 pl-2">{title}</div>
        <div><IconArrowRight /></div>
      </div>
    </a>
  </div>
}



export const ContentRelated = (props) => {
  if (!props || !props.settings) return <></>
  const data = props.settings
  return (
    <div className="content-related absolute right-0 h-full w-full px-2 md:pr-8 flex flex-col pb-8 md:flex-row">
      <div className="flex-1 flex items-end md:pl-8 pb-4 md:pb-0">
        <div className="text-h4 md:text-h3 md:pl-8 md:pr-8"><Htmltext text={data.text}></Htmltext></div>
      </div>
      <div className="links flex flex-col justify-center md:px-4">
        <div className="text-h4 pb-2" data-swiper-parallax={'-100%'}>{data.alt_title}</div>
        {data.related_1_title && <RelatedBlock color={props.color} title={data.related_1_title} link={data.related_1_url} img={data.related_1_image ? data.related_1_image.path : ''} />}
        <div className="pt-2"></div>
        {data.related_2_title && <RelatedBlock color={props.color} title={data.related_2_title} link={data.related_2_url} img={data.related_2_image ? data.related_2_image.path : ''} />}
      </div>
    </div>
  )
}




export const ContentAudio = (props) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [hwl, setHwl] = useState(false)
  const [wi, setWi] = useState(false)
  const canvas_ref = useRef()
  const initOk = useRef()

  const getColor = () => {
    return (props.settings.color) ? props.settings.color : 'white'
  }


  useEffect(() => {

    if (!hwl) return
    if (initOk.current === 1) return

    initOk.current = 1
    const canvasCtx = canvas_ref.current.getContext('2d')

    var analyser = Howler.ctx.createAnalyser()
    Howler.masterGain.connect(analyser)

    analyser.fftSize = 2048
    var bufferLength = analyser.frequencyBinCount
    var dataArray = new Uint8Array(bufferLength)

    analyser.getByteTimeDomainData(dataArray)

    const WIDTH = 400
    const HEIGHT = 200

    var cc = 0

    const draw = () => {
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)

      analyser.getByteTimeDomainData(dataArray)

      canvasCtx.fillStyle = 'rgb(200, 200, 200)'

      canvasCtx.lineWidth = 1
      canvasCtx.strokeStyle = getColor() //'rgb(255, 255, 255)'
      canvasCtx.beginPath()

      var sliceWidth = WIDTH * 1.0 / bufferLength
      var x = 0

      for (var i = 0; i < bufferLength; i++) {
        var v = dataArray[i] / 128.0
        var y = v * HEIGHT / 2
        if (i === 0) {
          canvasCtx.moveTo(x, y)
        } else {
          canvasCtx.lineTo(x, y)
        }
        x += sliceWidth
      }
      canvasCtx.lineTo(WIDTH, HEIGHT / 2)
      canvasCtx.stroke()
      if (hwl) setTimeout(draw, 30)
    }

    draw()

    return () => {
      if (hwl) {
        hwl.unload()
      }
    }

  }, [hwl])




  useEffect(() => {
    if (props.active && props.settings.audio_name) {
      var sound = new Howl({
        src: [videoUrl + props.settings.audio_name.content],
        //html5:true
        onplay: () => { 
          setIsPlaying(true) 
        },
        onpause: () => { 
          setIsPlaying(false) 
        },
        onend: () => { 
          setIsPlaying(false) 
        }
      })
      sound.play()
      setHwl(sound)

    } else {
      if (hwl) {
        hwl.unload()
        setHwl(null)
      }
    }
  }, [props.active])


  const getPosition = () => {
    if (!props.settings) return ''
    switch (props.settings.position) {
      case 'top-left':
        return 'top-0 left-0'
      case 'top-right':
        return 'top-0 right-4'
      case 'bottom-left':
        return 'bottom-0 left-0'
      case 'bottom-right':
        return 'bottom-0 right-8'
      default:
        return 'top-0 right-4'
    }
  }

  useEffect(() => {
    
  }, [])

  var curColor = 'text-' + getColor() + '-default'
  return (
    <div className={`content-audio-control pointer-events-auto ${props.settings.show_bg === true ? 'bg-' + props.color + '-50' : ''}  ${props.fixed ? '' : 'absolute'} flex flex-col ${getPosition()}`}>
      <div className="flex flex-row">
        <div className={`flex-1 relative wrap-canvas`}>
          <canvas className="absolute right-0" style={{ transform: 'translateY( calc(-50% + 32px))' }} ref={canvas_ref} width="400" height="200"></canvas>
        </div>
        <div>
          <div className="flex">
            {!isPlaying && <button aria-label={getStr('ui.play')} className={`w-8 ml-1px ${curColor}`} onClick={e => hwl.play()}><IconPlay /></button>}
            {isPlaying && <button aria-label={getStr('ui.pause')} className={`w-8 ml-1px ${curColor}`} onClick={e => hwl.pause()} ><IconPause /></button>}
          </div>
        </div>
      </div>
      
      <AudioSubtitles hwl={hwl} path={videoUrl + props.settings.audio_name.content}/>

      <div className={`text-h5 px-4 pb-2 ${curColor}`}>
        {props.settings.description}
      </div>
    </div>
  )
}


const AudioSubtitles = ({hwl, path}) => {
  const [vtt,setVtt] = useState()
  const [trackText,setTrackText] = useState('')
  const domRef = useRef()
  const initOk = useRef()

  useEffect( ()=>{
    if (initOk.current == 1) return 
    initOk.current = 1
    var vttPath = (path)
    vttPath = vttPath.replace('.mp3',`_${ userLang}.vtt`).replace('.m4a',`_${ userLang}.vtt`).replace('.mp4',`_${ userLang}.vtt`)
    setVtt(new vttHandler(vttPath))    
    return ()=>{
      if (vtt) vtt.destroy()
    }
  },[])

  useEffect( ()=>{
    if (hwl && vtt){
      vtt.startTracking(hwl,(txt)=>{
        setTrackText(txt)
      })

      hwl.on('end',()=>vtt.reset())
    }
  },[hwl,vtt])

  return <div className="audio-subtitles">
    <div className={`wrap ${trackText === '' ? 'hidden': ''}`}>{trackText}</div> 
  </div>

}