import React,{ useState, useEffect } from "react"
import { Howl } from "howler"
import { soundOptions, soundVolume } from "../../AppAtoms"
import { useRecoilState } from "recoil"
import { videoUrl } from "../../utils/net"

export const SoundHandler = ({volume}) => {
  const [soundState, setSoundState] = useRecoilState(soundOptions)
  const [volumeState, setVolumeState] = useRecoilState(soundVolume)
  const [curSound,setCurSound] = useState('')
  const [howlObj,setHowlObj] = useState()

  const toggleOnOff = () => {
    setVolumeState( (cur)=>{ return {...cur,...{on: !cur.on }} } )
  }

  useEffect(()=>{  
    if (soundState && soundState != curSound){      
      setCurSound( soundState )
    }
  },[soundState])

  useEffect(()=>{
    if (!howlObj) return
    if (volumeState.break || !volumeState.on || volumeState.vol === 0) {
      howlObj.pause()
    }else if (volumeState.on){
      if (!howlObj.playing()) howlObj.play()
    }    
    howlObj.volume(volumeState.vol / 100)    
    localStorage.setItem('volume', JSON.stringify(volumeState))
  },[volumeState])


  useEffect(()=>{
    if (!curSound) return
    if (howlObj) {
      var tmp = howlObj
      tmp.once('fade',()=>{
        tmp.unload()
      })
      tmp.fade(volumeState.vol / 100,0,2000)
    }
    setHowlObj( new Howl({
      src: [videoUrl+curSound ],
      volume: volumeState.vol / 100, 
      loop: true,      
    }))
    console.log('NEW SOUND', curSound)
  },[ curSound])

  useEffect(()=>{
    if (!howlObj) return
    if (volumeState.on){
      howlObj.play()
      howlObj.fade(0,volumeState.vol/100,2000)  
    }
  },[howlObj])

  return <div className="pt-10 z-50 pointer-events-auto">
    
    {/* {JSON.stringify(volumeState)}{curSound}  */}
    {/* <button onClick={()=>toggleOnOff()}>{volumeState.on ? 'ON' : 'OFF'}</button>      */}
  </div>
}

export const InterfaceSounds = ()=>{
  return <div className="">
    <audio className="hidden" controls autoPlay src="data:audio/mpeg;base64,SUQzAwAAAAAAFgAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/+5DAAAANdHErVa2AEgsUKnc3oBDlVqkkAnJbTSGsPxQsrUDUHdew4C7IdCoMxpU4L81oEzkA4GDN3WQEvmBDxlBQY8HJ8gUFAwWWsSIbR02Jv3QQ5ZhiMWN16enyhhnbv2oYhyxXl/afP//lPlz///cJAxwf/VBDlz7Fn+xX8Pl0pNVJktFlRut+TyC4XVb5lfDAXNe8eDzAQ4wYnYE6Rp1Yes+GTBZgpQ0OTD9o0hAwB80BFpMghlabJy0rhO9JYjRT7DC/iajWpTYhqmrd5J6/e/VltL+OPP/8+Z9wpKtampscd7/Wvwth9yyrlr8oAz4iCoK/hjl27Cv6Qg7nznWCoLQEsQAAAj0AAPIkgAAAGKhDf////////////////////////////////P//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+5DASYAD+AMWnBAAKAAAJcAAAAT//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/+5DA/4AxgAEuAAAAIAAAJcAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=" />
  </div>
}


export const onHover = {
  onMouseEnter:(e)=>{
  },
  onMouseLeave:(e)=>{
  }
}