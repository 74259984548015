import React, { Fragment, useState, useEffect, useRef } from "react"
import { fetchData } from "../../utils/net"
import { getStr } from "../../utils/simpleLocalize"
import { IconArrowRight } from "../icons"




/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const SurveyEvalYesNo = ({ data, surveyId, onNext }) => {
  const [resultData, setResultData] = useState(null)
  const [error, setError] = useState(null)


  useEffect(() => {
    fetchData(`/api/surveys/store/${surveyId}/${data.question_slug_slug}`, (serverData) => {
      if (serverData) {
        if (serverData.status == 'error'){
          setError(data.message)
          return
        }
        var total = Number(serverData.yes) + Number(serverData.no)
        if (data.eval_no === true){
          setResultData(Math.round(Number(serverData.no) / total * 100))
        }else{
          setResultData(Math.round(Number(serverData.yes) / total * 100))
        }
        
      }
    })
  }, [])
  

  return <div className="h-full flex flex-col" data-slug={data.slug_slug}>
    <div className="flex-1 relative">
      <svg className="bg-shape anim-fade-in" width="449" height="666" viewBox="0 0 449 666" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 148L449 0L449 788H0L0 148.442Z" fill="#333F45" fillOpacity={0.9} />
      </svg>
    </div>
    {resultData !== null && <div className="px-4 z-10 anim-slide-right opacity-0 anim-delay-200 pct-number text-shadow-2">
      {resultData}%
    </div>}

    {error && <div className="absolute top-0 bg-uigrey-black text-h4 text-red-100 mt-10">{error}</div>}

    <div className="px-4 text-h3 sm:text-h2 font-bold z-10 anim-slide-right text-shadow-1" dangerouslySetInnerHTML={{ __html: data.text }}></div>
    <div className="flex  z-10">
      <div className="flex-1" />
      <button onClick={e => onNext( data.next_slug_slug )} aria-label={getStr('ui.nextSlide')} className={`block next bg-uigrey-default`} ><IconArrowRight /></button>
    </div>
  </div>

}