import React, { Fragment, useEffect, useState,Suspense } from 'react'
import { Metadata } from './Metadata'
import { cache, fetchData, getParam } from '../utils/net'
import { useMapLocation } from "./map/useMapLocation"
import { getStr, userLang } from "../utils/simpleLocalize"
import { startAnim, stopAnim } from './map/Map_threejs'
import { SwiperConfigAreaItems, SwiperConfigCityItems, TabPanel } from './map/TabPanel'
import { TopicItem } from './map/TopicItem'
import { L } from '../utils/simpleLocalize'
import { routeRoot_area, routeRoot_city } from "../App"
import { LocationItem } from "./map/LocationItem"
import { IconClose } from "./icons"
import { useHistory } from "react-router-dom"
import { LocationPanel } from "./map/LocationPanel"
import { Loading } from "./Loading"
import { MapComponent } from "./map/Map"
import { soundOptions } from "../AppAtoms"
import { useRecoilState } from "recoil"

import {PrisonStatsWidget} from './prisonStatistics/PrisonStatsWidget'
import { InlineHelp } from "./InlineHelp"


export const MapHandler = ({onTour,isMobile, storyData}) => {

  const location = useMapLocation()
  const history = useHistory()
  const [locationData, setLocationData] = useState(null)
  const [topicData, setTopicData] = useState(null)
  const [locationItems, setLocationItems] = useState(null)
  const [hideInterface, setHideInterface] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(null)
  const [topicOpen, setTopicOpen] = useState(false)
  const [locationOpen, setLocationOpen] = useState(false)
  const [soundState,setSoundState] = useRecoilState(soundOptions)
  const showStats = cache.get('global_settings','show_stats')

  // get location data
  const fetchLocationData = () => {
    setLocationData( cache.get('topicData') )
  }

  //
  const getTopicBySlug = (slug)=>{
    var res = locationData.topics.filter( (el)=>el.slug_slug === slug )
    return Array.isArray(res) ? res[0] : null
  }

  //
  const getUrlRoot = ( param ) => {
    let res = location.mode === 'city' ? routeRoot_city : routeRoot_area
    return res + (param ? '/'+param+'/' : '')
  }
  
  //
  const handleOpenStory = (data) => {
    if (!data.content_link) {
      setErrorText('No Story Attached')
      return
    }
    var url = getUrlRoot( location.topic )
    url += location.loc + '/' + data.content_link.display
    history.push(url)    
  }

  //
  const handleNextStory = (opt) => {
    var idx = locationItems.findIndex( (el)=>location.loc==el.slug_slug ) + opt
    idx = idx > locationItems.length-1 ? 0 : idx
    var url = getUrlRoot( location.topic )
    if (locationItems[idx]){
      history.push(url + locationItems[idx].slug_slug)
    }
  }

  // 
  const showMapByStory = (data) => {
    if (!data) return false
    switch (data.story_type){
      case 'threesixty': return true
    }
    return false
  }


  // 
  const onMarkerClick = (el) => {
    var url
    if (location.topic === el.topic_slug){
      url = getUrlRoot( location.topic ) + el.slug_slug
    }else{
      url = getUrlRoot( el.topic_slug )
    }
    history.push(url)    
  }

  useEffect( ()=>{
    if (!storyData) return
    fetchLocationData()
  },[storyData])

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {

    if (location.mode === ''){
      if (topicOpen) setTopicOpen(false)
      if (locationOpen) setLocationOpen(false) 
      setCurrentLocation(null)     
      return
    }

    var mc = cache.get('map_config')    
    setSoundState(  mc.music.content || '' )

    // if on startscreen, animate map
    if (location.mode === 'start') {
      startAnim()
    } else {
      stopAnim()
    }    

    if ( location.mode === '' || location.story ) {
      return
    } else if (!locationData){
      fetchLocationData()    
    }

    if (!location.story && !locationData) {
    }

    if (location.topic === ''){
      setTopicOpen(true)
    }else {
      setLocationOpen(true)
    }

    if (location.topic && locationData){
      var t = getTopicBySlug(location.topic)
      if (!t) return
      var q = locationData.locations.filter( (el)=>el.topic_link === t._id  )
      setTopicData(t)
      setLocationItems(q)
    }else if (locationItems){
      setLocationItems(null)
      setTopicData(null)
    }

    if (location.loc && locationData && !location.story) {
      let l = locationData.locations.filter( (el,i)=>el.slug_slug==location.loc )
      setCurrentLocation(l[0])
      setLocationOpen(false)
      setTopicOpen(false)
    }else if(!location.loc && currentLocation){
      setCurrentLocation(null)
    }

  }, [location,locationData])


  return (
    <div className={`map-handler ${location.story ? 'in-story': ''} ${location.topic} `}>
      {location.mode == 'area' && <Metadata slug="area" />}
      {location.mode == 'city' && <Metadata slug="city" />}    
            
      {(locationData) &&
        <Suspense fallback={<Loading />}>
          <MapComponent
            filterData={locationData.locations}
            location={location}
            currentLocation={currentLocation}
            defaultLocations={cache.get('default_locations')}
            topic={topicData}
            onReady={loc => { }}
            onMarkerClick={ (e)=>{ onMarkerClick(e) }}
            selectedView={ null } //_view360
            storyData={ storyData }
            isMobile={ isMobile} />
        </Suspense>
      }


      {/* dont show navigation if onTour */}
      {(onTour !== true && !hideInterface && location.mode !== 'start') && <Fragment>

        <TabPanel
          visible={topicOpen}
          className={`topics-${location.mode} hide-on-story`}
          data={ location.mode === 'city' ? cache.get('city_topics') : cache.get('area_topics') }
          title={<L id={`map.topic.${location.mode}.bartitle`} />}
          renderListItem={props => { return <TopicItem {...props} rootUrl={ getUrlRoot() } /> }}
          onSelect={obj => { setTopicOpen(false) }}
          onVisible={ opt=>setTopicOpen(opt) }
          headerButtons={
            <Fragment>
              {topicOpen && <InlineHelp id={`map.${location.mode}.topics`} />}
            </Fragment>
          }
        >
          <div className="info pr-2 hidden sm:block">
            <h2 className="hidden sm:block text-h3 font-semibold"><L id={`map.topics.${location.mode}.title`} /></h2>
            <p className="p-1 sm:px-0"><L id={`map.topics.${location.mode}.info`} /></p>
          </div>
        </TabPanel>

        <TabPanel
          visible={locationOpen}
          className="locations show-gradient hide-on-story"
          title={ topicData ? topicData.title : ''}
          data={ locationItems }
          icon={false}
          renderListItem={props => { return <LocationItem  urlprefix={ getUrlRoot( location.topic ) } color={`bg-basic-${ topicData.color.content }`} {...props} /> }}
          onVisible={ (opt)=>{setLocationOpen(opt)} }
          headerButtons={
            <Fragment>
              <button aria-label={getStr('ui.close')} className="block bg-uigrey-dark" onClick={ ()=>{ history.push( getUrlRoot() ) } }><IconClose /></button>
              {locationOpen && <InlineHelp id={`map.${location.mode}.locations`} />}
            </Fragment>
          }
          swiper={SwiperConfigCityItems}
        ></TabPanel>        

        {currentLocation && <LocationPanel 
          data={currentLocation} 
          color={topicData.color.content}
          onClose={e => { 
            setCurrentLocation(null) 
            history.push( getUrlRoot( location.topic ) )
          }} 
          onOpenContent={e=>handleOpenStory(e) } 
          onNextLocation={e=>handleNextStory(1)} 
          onPrevLocation={e=>handleNextStory(-1)} 
        />}

      </Fragment>}
        
      <div className="absolute">{showStats}{hideInterface ? 'true' : 'false'}</div>

      { Number(showStats) === 1 && !currentLocation &&  <PrisonStatsWidget visible={location.mode === 'area'} /> }

      <MapMusic />
    </div>
  )

}



const MapMusic = () => {
  const [soundState,setSoundState] = useRecoilState(soundOptions)

  return <Fragment/>
}