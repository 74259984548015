import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { IconArrowLeft, IconArrowRight, IconClose, IconShare } from "../icons"
import { getStr } from "../../utils/simpleLocalize"
import { useRecoilState } from "recoil"
import { headerState,view360,selectedView360 } from "../../AppAtoms"
import { ContentAudio } from "./contents"
import { parseColor } from "../../utils/colors"
import { closeThreeSixty, showThreeSixty, tweenCamera360 } from "../map/Map_360"
import { ContentHeader } from "../ContentHeader"

 

export const ThreeSixty = ({data,onClose}) => {
 
  const [_headerState,_setHeaderState] = useRecoilState(headerState) 
  const [curView,setCurView] = useState(null)

  const closeStory = () => {
    _setHeaderState('show')
    setCurView(null) 
    onClose()
  } 

  useEffect( ()=>{    
    _setHeaderState('hide')
    setTimeout(()=>{
      showThreeSixty( data, (data)=>{
      })
    },400)

    return ()=>{      
      closeThreeSixty(()=>{
      })
    }
  },[])

  const gotoView = (dir) => {
    var idx = data.screens.findIndex( (e)=>curView ? e._id==curView._id : false )
    idx += dir
    if (idx < 0) idx = data.screens.length -1
    if (idx > data.screens.length-1) idx = 0  
    setCurView( data.screens[idx] )  
  }

  useEffect(()=>{
    if (!curView) return
    tweenCamera360(curView.options.cam)  
  },[curView])

  return (
    <div className={`scrollstory w-full h-full threesixty`}>
      <ContentHeader title={data.title} onClick={ e=>closeStory() } color={parseColor(data.color) }/>

      <div className={`absolute bottom-0 w-full flex flex-col items-center`}>
        <div>
          <AudioPlayer data={curView}/> 
        </div>
        <div className="navigation flex-1 flex justify-center">
          { data.screens.map( (el,i)=><ScreenItem key={i} data={el} onSelect={ e=>setCurView(e)}  selected={curView ? el._id==curView._id : false  }/> )}
        </div>
        <div className={` flex items-center w-full`}>
          <button className={`btn bg-${ parseColor(data.color)}-50`} onClick={ ()=>gotoView(-1) }><IconArrowLeft/></button>
          <div className="flex-1"></div>
          <button className={`btn bg-${ parseColor(data.color)}-50`} onClick={ ()=>gotoView(1) }><IconArrowRight/></button>
        </div>
      </div>
    </div>
  )
}

const AudioPlayer = ({data}) => {

  const [content, setContent] = useState(null)

  useEffect(()=>{
    if (!data || !data.content) return
    setTimeout(()=>{
      setContent( data.content[0] )
    },300)
    setContent(null)
  },[data])


  if (content){
    return <div className="pb-2 relative">
      <ContentAudio {...content} active={true} fixed={true} color={'petrol'}/>
    </div>
  }else{
    return <div></div>
  }
}


const ScreenItem = ({data,onSelect,selected}) => {
  return (<button className={`dot-wrap ${selected?'selected':''}`} onClick={ ()=>onSelect(data) }><div className="dot"></div></button>)
}