import React, { useState, useCallback, useEffect, useRef, Fragment } from "react"
import { isDebug } from "../../utils/tool"
import { Copyright } from "../Copyright"
import { BackgroundColor, BackgroundImage, BackgroundShapeColor, BackgroundVideo } from "./backgrounds"
import { ContentAudio, ContentImage, ContentRelated, ContentStoryTitle, ContentText, ContentTitleBox } from "./contents"
import { OverlayDocZoomer, OverlayDocZoomerButton } from "./overlays/OverlayDocZoomer"
import { OverlayVideocontrol } from "./overlays/OverlayVideocontrol"


export const isSlideActive = (sw, index) => {
  const [isActive, setIsActive] = useState(false)
  useEffect(() => {
    sw.on('slideChange', function () {
      if (sw.activeIndex === index) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    })
  }, [sw])
  return isActive
}


export const StorySlide = ({ data, sw, color, index }) => {

  const [videoRef, setVideoRef] = useState()
  const [trackRef, setTrackRef] = useState()
  const [test, setTest] = useState(1)
  const bgref = useRef()
  const bgwrapref = useRef()
  const isActive = isSlideActive(sw, index)

  let fixedClass = ''
  let stretchClass = ''
  let fixedAmount = '0'
  let extraClass = ''
  let bgprops = {
    className: 'back h-full'
  }


  useEffect(() => {
    
  }, [isActive])

  const handleVideo = useCallback((action, opt,trc) => {
    if (action === 'ref') {
      setVideoRef(opt)
      setTrackRef(trc)
    }
  })

  const handleBackground = (el, i) => {
    let id = data._id + '_bg_' + i
    switch (el.component) {
      case 'background_video': return <BackgroundVideo {...el} key={id} active={isActive} onAction={handleVideo} color={color} />
      case 'background_image': return <BackgroundImage {...el} key={id} color={color} slideWidth={data.stretch_background_count} />
      case 'background_color': return <BackgroundColor {...el} key={id} />
      case 'background_shapecolor': return <BackgroundShapeColor {...el} key={id} />
      default: console.warn('missing bg::', el.component)
    }
  }

  const handleContent = (el, i) => {
    let id = data._id + '_ct_' + i
    switch (el.component) {
      case 'content_text': return <ContentText {...el} key={id} />
      case 'content_scroll_title': return <ContentStoryTitle {...el} key={id} />
      case 'content_image': return <ContentImage {...el} key={id} />
      case 'content_titlebox': return <ContentTitleBox {...el} key={id} />
      case 'content_audio': return <ContentAudio {...el} key={id} active={isActive} color={color} />
      case 'content_related': return <ContentRelated {...el} key={id} active={isActive} color={color} />
      default: console.warn('missing content::', el.component)
    }
  }

  const handleOverlay = (el, i) => {
    switch (el.component) {
      case 'overlay_videocontrols': return <OverlayVideocontrol key={i} videoref={videoRef} trackref={trackRef} active={isActive} title={ el.settings.title }/>
      case 'overlay_doczoomer': return <OverlayDocZoomerButton key={i} data={el.settings} color={color} />
      default: console.warn('missing overlay::', el.component)
    }
  }

  if (!data) {
    return <></>
  }


  if (data.fixed_background_count) {
    fixedClass = 'fixed-bg'
    fixedAmount = data.fixed_background_count
  }

  if (data.stretch_background_count && Number(data.stretch_background_count) > 1) {
    stretchClass = 'stretch-bg-' + data.stretch_background_count
  }

  if (data.options && data.options.class) {
    extraClass = data.options.class
  }

  return (
    <div className={`h-full ${extraClass} ${stretchClass}`} data-index={index} ref={bgwrapref} >
      <div className={`back relative h-full ${fixedClass}`} data-fixedcount={fixedAmount} ref={bgref}>
        {data.background ? data.background.map(handleBackground) : <></>}
      </div>
      <div className="content w-full absolute">
        {data.content ? data.content.map(handleContent) : <></>}
      </div>
      <div className="overlay w-full h-full pointer-events-none">
        {data.overlay ? data.overlay.map(handleOverlay) : <></>}
      </div>
      {data.bgCopyright && <Copyright data={data.bgCopyright} type="text" />}
      <Copyright data={data} type="background" />
      {isDebug() && <div className="absolute bottom-0 left-50 p-1 bg-red-25 text-h6">{data.title}</div>}
    </div>
  )
}


